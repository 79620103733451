import { useContext, useEffect, useMemo, useState, useCallback } from 'react'

import { gql, useMutation } from '@apollo/client'
import ReactJson from '@microlink/react-json-view'
import { Box, Button, TextField, Typography } from '@mui/material'
import { IconInbox } from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import type { Action, UpdateActionInput } from 'types/graphql'
import type { ActionType } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { actionQueries } from 'src/lib/actions'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ActionActions from '../Actions/ActionActions/ActionActions'
import { ActionChannelType } from '../Actions/ActionChannelChip/ActionChannelChip'
import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import PersonChip from '../People/PersonChip/PersonChip'
import PromptInput from '../Prompts/PromptInput'
import SuggestedPromptRow from '../Prompts/SuggestedPromptRow'
import Row from '../Row/Row'

import CreateActionDraftButton from './CreateActionDraftButton'
import { typeLabels } from './inbox'

const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      title
      contentJson
      contentHtml
      ownerEmail
      createdAt
      updatedAt
      shortLinkHash
      madeExternalAt
      workspaceId
      emoji
      headerImage
      publishedForUserAt
      parentObject {
        objectId
        objectType
      }
      crmObjects {
        objectId
        objectType
        properties
        workspaceId
      }
      aiInitialPrompt
      aiPopulationCompletedAt
    }
  }
`

const MARK_ACTION_READ = gql`
  mutation MarkActionRead($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const GET_SUGGESTED_CONTEXT_OBJECTS = gql`
  query GetSuggestedContextObjects(
    $parentReferenceKey: String!
    $workspaceId: String!
    $referencedObjectsKeys: [String!]!
    $prompt: String
  ) {
    suggestedContextObjects(
      parentReferenceKey: $parentReferenceKey
      workspaceId: $workspaceId
      referencedObjectsKeys: $referencedObjectsKeys
      prompt: $prompt
    ) {
      objectType
      objectId
      properties
    }
  }
`

const GET_OWNER_DETAILS = gql`
  query GetOwnerDetails($email: String!, $workspaceId: String!) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      firstName
      lastName
      email
    }
  }
`

const getActionDraftPrompt = ({
  actionPrompt,
  userContact,
  title,

  channelType,
}: {
  actionPrompt: string
  userContact: { firstName: string; lastName: string; email: string }
  title: string
  channelType: ActionChannelType
}) => {
  const today = new Date().toISOString().split('T')[0]

  return `You are helping ${userContact.firstName} ${userContact.lastName} (${userContact.email}) with the following action item: "${title}".

Your task is to: ${actionPrompt}

The communication channel is: ${channelType}

${
  channelType === ActionChannelType.GMAIL
    ? `
EMAIL FORMATTING INSTRUCTIONS:
- Write concisely and action-oriented
- Use straightforward language, avoid unnecessary adjectives
- Keep tone friendly and slightly informal
- Write in first person as ${userContact.firstName}
- Do not include email headers or signatures
- Use the recipient's first name when addressing them`
    : ''
}

${
  channelType === ActionChannelType.SLACK
    ? `
SLACK FORMATTING INSTRUCTIONS:
- Start with a clear subject/purpose
- Use brief, direct language
- Break information into bullets if needed
- Avoid emojis or special formatting
- Skip unnecessary pleasantries
- Write as ${userContact.firstName}`
    : ''
}

${
  channelType === ActionChannelType.PAGE
    ? `
PAGE FORMATTING INSTRUCTIONS:
- Start with a clear purpose summary
- Use direct, plain language
- Break information into sections as needed
- Use [ ] for action items or steps
- Include all necessary details while staying focused
- Write from ${userContact.firstName}'s perspective`
    : ''
}

Use the provided context to ensure the draft is:
1. Relevant and up-to-date as of ${today}
2. Incorporating key details from previous communications
3. Focused specifically on completing this action
4. Clear and actionable
`
}

const InboxActionDetail = ({
  selectedAction,
  refetch,
  type,
  updateAction,
  showCrmObjects = false,
  onHide = (_actionId: string) => {},
}: {
  selectedAction: Action
  refetch: () => void
  type: ActionType
  updateAction: (action: UpdateActionInput) => void
  showCrmObjects: boolean
  onHide: (actionId: string) => void
}) => {
  const { currentUser } = useAuth()
  const { setSidebarObject } = useContext(DayContext)
  const [actionPrompt, setActionPrompt] = useState(
    selectedAction?.draft?.prompts?.[0]?.shortPrompt || ''
  )
  const [pageCreationUnderway, setPageCreationUnderway] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const [title, setTitle] = useState(selectedAction?.title || '')
  const [contextObjects, setContextObjects] = useState<any[]>([])
  const [hiddenObjects, setHiddenObjects] = useState<string[]>([])
  const [selectedChannelType, setSelectedChannelType] =
    useState<ActionChannelType>(
      (selectedAction?.draft?.prompts?.[0]?.channelType as ActionChannelType) ||
        (selectedAction?.channel?.type as ActionChannelType) ||
        ActionChannelType.GMAIL
    )

  const { data } = useQuery(GET_SUGGESTED_CONTEXT_OBJECTS, {
    variables: {
      parentReferenceKey: `${selectedAction?.workspaceId} : ${NativeObjectTypes.Action} : ${selectedAction?.id}`,
      workspaceId: selectedAction?.workspaceId,
      referencedObjectsKeys: contextObjects.map((o) => o.objectId),
    },
    skip: !selectedAction?.id || !selectedAction?.workspaceId,
  })

  const { data: ownerData } = useQuery(GET_OWNER_DETAILS, {
    variables: {
      email: selectedAction?.owner?.email,
      workspaceId: selectedAction?.workspaceId,
    },
    skip: !selectedAction?.owner?.email || !selectedAction?.workspaceId,
  })

  const isActionOwner = useMemo(() => {
    return selectedAction?.owner?.email === currentUser?.email
  }, [selectedAction, currentUser])

  const [markRead] = useMutation(MARK_ACTION_READ, {
    refetchQueries: actionQueries,
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    setTitle(selectedAction?.title || '')
    setEditingTitle(false)

    // Add debug logging
    logger.dev('Action detail mounted:', {
      actionId: selectedAction?.id,
      isOwner: isActionOwner,
      status: selectedAction?.status?.id,
      ownerEmail: selectedAction?.owner?.email,
      currentUserEmail: currentUser?.email,
    })

    // Mark action as READ when mounted if user is the owner and status is UNREAD
    if (
      selectedAction?.id &&
      isActionOwner &&
      selectedAction?.status?.id === 'UNREAD'
    ) {
      logger.dev('Marking action as read:', {
        actionId: selectedAction.id,
        workspaceId: selectedAction.workspaceId,
      })

      markRead({
        variables: {
          input: {
            id: selectedAction.id,
            workspaceId: selectedAction.workspaceId,
            status: 'READ',
          },
        },
      }).catch((error) => {
        logger.error('Failed to mark action as read:', error)
      })
    }
  }, [selectedAction, isActionOwner, markRead, currentUser?.email])

  useEffect(() => {
    if (selectedAction) {
      const initialContextObjects = [
        ...(selectedAction.people || []).map((person) => ({
          objectType: NativeObjectTypes.Person,
          objectId: person.email,
          properties: {
            email: person.email,
          },
        })),
        ...(selectedAction.organizations || []).map((organization) => ({
          objectType: NativeObjectTypes.Organization,
          objectId: organization.domain,
          properties: {
            domain: organization.domain,
          },
        })),
        ...(selectedAction.opportunityIds || []).map((opportunityId) => ({
          objectType: NativeObjectTypes.Opportunity,
          objectId: opportunityId,
          properties: {
            id: opportunityId,
          },
        })),
      ]
      setContextObjects(initialContextObjects)
    }
  }, [selectedAction])

  useEffect(() => {
    if (data?.suggestedContextObjects) {
      const newSuggestions = data.suggestedContextObjects.filter(
        (suggestion) =>
          !contextObjects.some(
            (existing) => existing.objectId === suggestion.objectId
          ) && !hiddenObjects.includes(suggestion.objectId)
      )

      if (newSuggestions.length > 0) {
        setContextObjects((prev) => [...prev, ...newSuggestions])
      }
    }
  }, [data?.suggestedContextObjects, contextObjects, hiddenObjects])

  useEffect(() => {
    if (selectedAction?.draft?.prompts?.[0]) {
      setActionPrompt(selectedAction.draft.prompts[0].shortPrompt)
      setSelectedChannelType(
        (selectedAction.draft.prompts[0].channelType as ActionChannelType) ||
          ActionChannelType.GMAIL
      )
    }
  }, [selectedAction])

  const handleHideObject = useCallback((objectId: string) => {
    setHiddenObjects((prev) => [...prev, objectId])
  }, [])

  const handleUpdateContextObjects = useCallback((newContextObjects: any[]) => {
    setContextObjects(newContextObjects)
    logger.dev('Context objects updated:', newContextObjects)
  }, [])

  const handleUpdateAction = async () => {
    if (!selectedAction) return
    setEditingTitle(false)

    await updateAction({
      id: selectedAction.id,
      title,
      workspaceId: selectedAction.workspaceId,
    })
  }

  const [createPage] = useMutation(CREATE_PAGE)

  const handleCreatePage = async () => {
    try {
      setPageCreationUnderway(true)
      const result = await createPage({
        variables: {
          input: {
            workspaceId: selectedAction.workspaceId,
            title: `Action: ${selectedAction.title}`,
            aiInitialPrompt: getActionDraftPrompt({
              actionPrompt,
              userContact: {
                firstName: ownerData?.getPerson?.firstName,
                lastName: ownerData?.getPerson?.lastName,
                email: ownerData?.getPerson?.email,
              },
              title: selectedAction.title,
              channelType: selectedChannelType,
            }),
            contentJson: {
              type: 'doc',
              content: [],
            },
            contentHtml: '',
            objectType: NativeObjectTypes.Action,
            objectId: selectedAction.id,
            crmObjects: [
              {
                objectType: NativeObjectTypes.Action,
                objectId: selectedAction.id,
              },
              ...(selectedAction?.people || []).map((person) => ({
                objectType: NativeObjectTypes.Person,
                objectId: person.email,
              })),
              ...(selectedAction?.organizations || []).map((org) => ({
                objectType: NativeObjectTypes.Organization,
                objectId: org.domain,
              })),
              ...contextObjects.map(({ objectType, objectId, properties }) => ({
                objectType,
                objectId,
                properties,
              })),
            ],
          },
        },
      })
      if (result.data?.createPage) {
        const newSidebarPageObject = {
          objectId: result.data.createPage.id,
          objectType: NativeObjectTypes.Page,
          workspaceId: selectedAction.workspaceId,
        }

        setSidebarObject(newSidebarPageObject)
        setPageCreationUnderway(false)
      }
    } catch (error) {
      logger.error('Failed to create page:', error)
      setPageCreationUnderway(false)
      toast.error('Failed to create page')
    }
  }

  return selectedAction?.id ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 64px)',
        overflowY: 'auto',
        px: 1,
      }}
    >
      <Row
        sx={{
          height: '56px',
          justifyContent: 'space-between',
        }}
      >
        <Row gap={1}>
          <ActionPriorityChip action={selectedAction} />
          <ActionActions
            action={selectedAction}
            showButtons={true}
            onUpdate={() => {
              refetch()
            }}
            onHide={(actionId) => {
              onHide(actionId)
            }}
          />
        </Row>
        <Row gap={1}>
          {selectedAction.owner?.email && (
            <PersonChip
              email={selectedAction.owner.email}
              sx={{
                border: 'none',
                px: 0,
                '& .MuiChip-label': {
                  pr: 0,
                },
              }}
            />
          )}
        </Row>
      </Row>

      {editingTitle ? (
        <Box>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
            variant="standard"
            multiline={true}
            rows={title?.length > 64 ? 2 : 1}
            inputProps={{
              style: {
                fontSize: '24px',
                fontWeight: 600,
                letterSpacing: '-1.2px',
                lineHeight: '30px',
              },
            }}
          />
          <Row sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleUpdateAction}>Save</Button>
          </Row>
        </Box>
      ) : (
        <Typography
          variant="h2"
          sx={{ mt: 1, mb: showCrmObjects ? 2 : 4 }}
          onClick={() => setEditingTitle(true)}
        >
          {title}
        </Typography>
      )}
      <Row sx={{ ml: 1 }}>
        <SuggestedPromptRow
          prompts={selectedAction?.draft?.prompts
            ?.slice(1, 4)
            .map((prompt) => ({
              ...prompt,
              channelType: prompt?.channelType || ActionChannelType.GMAIL,
            }))}
          onAddPrompt={(prompt) => {
            setActionPrompt(prompt.shortPrompt)
            setSelectedChannelType(
              (prompt?.channelType as ActionChannelType) ||
                ActionChannelType.GMAIL
            )
          }}
        />
      </Row>
      <PromptInput
        prompt={actionPrompt}
        placeholder={'Or tell us something else to make...'}
        setPrompt={setActionPrompt}
        contextObjects={contextObjects}
        onUpdateContextObjects={handleUpdateContextObjects}
        suggestedObjects={[]}
        workspaceId={selectedAction.workspaceId}
        variant="new"
        onSubmit={handleCreatePage}
        buttonLabel="Add people, organizations, or opportunities"
        actionButton={
          <CreateActionDraftButton
            action={selectedAction}
            onClick={handleCreatePage}
            disabled={pageCreationUnderway || !actionPrompt}
            selectedChannelType={selectedChannelType}
            setSelectedChannelType={setSelectedChannelType}
          />
        }
        onHideObject={handleHideObject}
      />

      {false && process.env.HOST.includes('localhost') && (
        <ReactJson
          src={selectedAction}
          collapsed={0}
        />
      )}
    </Box>
  ) : type ? (
    <Row sx={{ height: '90%', justifyContent: 'center', opacity: 0.5 }}>
      <Row
        sx={{ flexDirection: 'column', alignItems: 'center' }}
        gap={1}
      >
        <IconInbox size={72} />
        <Typography variant="h3">{typeLabels[type].label} Inbox</Typography>
      </Row>
    </Row>
  ) : null
}

export default InboxActionDetail
