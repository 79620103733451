import { Box, Chip } from '@mui/material'
import { IconFileText, IconPencil } from '@tabler/icons-react'
import type { ActionPromptOutput } from 'types/graphql'

import { ActionChannelType } from '../Actions/ActionChannelChip/ActionChannelChip'
import SlackLogo from '../Slack/SlackLogo/SlackLogo'
const suggestedSx = {
  border: '1px solid',
  borderColor: (theme) => theme.palette.action.disabled,
  color: (theme) => theme.palette.primary.main,
  p: 0.5,
}

const SuggestedPromptChip = ({
  prompt,
  key,
  onAddPrompt,
  hide,
}: {
  prompt: ActionPromptOutput
  key: string
  onAddPrompt: (prompt: ActionPromptOutput) => void
  hide?: boolean
}) => {
  const display = {
    icon: null,
  }

  let channelTypeId = prompt.channelType as ActionChannelType
  if (channelTypeId === 'EMAIL') {
    channelTypeId = 'GMAIL' as ActionChannelType
  }

  switch (channelTypeId) {
    case ActionChannelType.GMAIL:
      display.icon = (
        <Box
          component="img"
          src={'/gmail-icon.svg'}
          sx={{
            height: '12px',
            width: '12px',
          }}
        />
      )

      break
    case ActionChannelType.SLACK:
      display.icon = <SlackLogo />

      break
    case ActionChannelType.PAGE:
      display.icon = <IconFileText />
      break
    default:
      display.icon = <IconPencil />
  }
  return (
    <Chip
      icon={display.icon}
      label={prompt.shortPrompt}
      key={key}
      size="small"
      variant="outlined"
      onClick={() => onAddPrompt(prompt)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.light,
        },
        maxWidth: 280,
        fontSize: '0.75rem',
        backgroundColor: (theme) => theme.palette.background.paper,
        ...suggestedSx,
        display: hide ? 'none' : '',
      }}
    />
  )
}

export default SuggestedPromptChip
