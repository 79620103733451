import { IconButton } from '@mui/material'
import { IconX } from '@tabler/icons-react'

import useViews from './useViews'

const ViewRemovePin = () => {
  const { removePin, currentView } = useViews()

  const handleRemovePin = (e) => {
    e.stopPropagation()
    removePin(currentView?.id)
  }

  return (
    <IconButton
      onClick={handleRemovePin}
      sx={{
        p: '3px',
        borderRadius: '2px',
        opacity: 0.5,
        '&:hover': { opacity: 1 },
      }}
    >
      <IconX
        size={14}
        stroke={2.5}
      />
    </IconButton>
  )
}

export default ViewRemovePin
