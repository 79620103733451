import { useContext, useReducer, useEffect, useCallback, useState } from 'react'

import { Search, Close } from '@mui/icons-material'
import { Box, Typography, Button, IconButton } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import type { OpportunityCreateInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ObjectTile from 'src/components/ObjectTile/ObjectTile'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import {
  extractEmailDomain,
  NativeObjectFormatters,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'
import { slugify } from 'src/lib/utils'

import {
  CREATE_PROPERTY_DEFINITION,
  UPDATE_PROPERTY_DEFINITION,
  DELETE_PROPERTY_DEFINITION,
  PROPERTY_DEFINITIONS,
  CREATE_OPPORTUNITY_FOR_DIALOG,
} from './mutations'
import OpportunityCreateForm from './OpportunityCreateForm'
import PropertyDefinitionForm from './PropertyDefinitionForm'
import type {
  EditorState,
  OpportunityState,
  PropertyDefinitionState,
  PropertyDefinitionAction,
  OpportunityAction,
  EditorAction,
  SidebarObjectEditorProps,
} from './types'
import {
  initialEditorState,
  initialOpportunityState,
  initialPropertyDefinitionState,
} from './types'

// Property definition reducer
function propertyDefinitionReducer(
  state: PropertyDefinitionState,
  action: PropertyDefinitionAction
): PropertyDefinitionState {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.payload }
    case 'SET_DESCRIPTION':
      return { ...state, description: action.payload }
    case 'SET_PROPERTY_TYPE':
      return { ...state, propertyType: action.payload }
    case 'SET_AI_ENABLED':
      return { ...state, aiEnabled: action.payload }
    case 'SET_USE_WEB':
      return { ...state, useWeb: action.payload }
    case 'SET_OPTIONS':
      return { ...state, options: action.payload }
    case 'ADD_OPTION':
      return {
        ...state,
        options: [
          ...state.options,
          {
            id: slugify(action.payload.name),
            name: action.payload.name,
            description: action.payload.description,
          },
        ],
      }
    case 'REMOVE_OPTION':
      return {
        ...state,
        options: state.options.filter((option) => option.id !== action.payload),
      }
    case 'EDIT_OPTION':
      return {
        ...state,
        options: state.options.map((option) =>
          option.id === action.payload.id
            ? {
                id: option.id,
                name: action.payload.name,
                description: action.payload.description,
              }
            : option
        ),
      }
    default:
      return state
  }
}

// Opportunity reducer
function opportunityReducer(
  state: OpportunityState,
  action: OpportunityAction
): OpportunityState {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      logger.dev('REDUCER: Processing SET_ORGANIZATION')
      logger.dev(`REDUCER: Payload domain is: ${action.payload?.domain}`)
      return {
        ...state,
        selectedOrganization: action.payload,
        domain: action.payload?.domain,
        title: state.title || action.payload?.domain,
      }
    case 'SET_PERSON':
      return {
        ...state,
        selectedPerson: action.payload,
        title: state.title || action.payload.objectId,
      }
    case 'SET_PIPELINE':
      return {
        ...state,
        selectedPipeline: action.payload,
        oppType: action.payload.opportunityTypes?.[0] || 'New Business',
        hasRevenue: action.payload.hasRevenue,
      }
    case 'SET_STAGE':
      return {
        ...state,
        selectedStage: action.payload,
      }
    case 'SET_OWNER':
      return {
        ...state,
        selectedOwner: action.payload,
        ownerWasDismissed: false,
      }
    case 'SET_TITLE':
      return {
        ...state,
        title: action.payload,
      }
    case 'SET_OPP_TYPE':
      return {
        ...state,
        oppType: action.payload,
      }
    case 'SET_EXPECTED_CLOSE_DATE':
      return {
        ...state,
        expectedCloseDate: action.payload,
      }
    case 'SET_EXPECTED_REVENUE':
      return {
        ...state,
        expectedRevenue: action.payload,
      }
    case 'SET_HAS_REVENUE':
      return {
        ...state,
        hasRevenue: action.payload,
      }
    case 'SET_DOMAIN':
      return {
        ...state,
        domain: action.payload,
      }
    case 'DISMISS_OWNER':
      return {
        ...state,
        selectedOwner: null,
        ownerWasDismissed: true,
      }
    case 'RESET':
      return initialOpportunityState
    case 'INITIALIZE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

// Editor reducer
function editorReducer(state: EditorState, action: EditorAction): EditorState {
  let newState: EditorState
  switch (action.type) {
    case 'SET_MODE':
      return { ...state, mode: action.payload }
    case 'SET_TITLE':
      return { ...state, title: action.payload }
    case 'SET_OBJECT_TYPE':
      return {
        ...state,
        objectType: action.payload,
        objectState:
          action.payload === 'opportunity'
            ? initialOpportunityState
            : initialPropertyDefinitionState,
      }
    case 'SET_OBJECT_STATE': {
      logger.dev('EDITOR_REDUCER: Processing SET_OBJECT_STATE')
      logger.dev(
        `EDITOR_REDUCER: Current objectState type: ${state.objectType}`
      )

      if (state.objectType === NativeObjectTypes.Opportunity) {
        const currentState = state.objectState as OpportunityState
        const updates = action.payload as Partial<OpportunityState>

        // Log what's changing
        Object.keys(updates).forEach((key) => {
          logger.dev(`EDITOR_REDUCER: Updating ${key}:`, {
            from: currentState[key],
            to: updates[key],
          })
        })

        // Create new state, only merging the updated fields
        newState = {
          ...state,
          objectState: {
            ...currentState,
            ...updates,
          },
        }
      } else {
        // Handle PropertyDefinition case
        const currentState = state.objectState as PropertyDefinitionState
        const updates = action.payload as Partial<PropertyDefinitionState>

        newState = {
          ...state,
          objectState: {
            ...currentState,
            ...updates,
          },
        }
      }

      return newState
    }
    case 'RESET':
      return {
        ...initialEditorState,
        objectType: state.objectType, // Preserve the object type on reset
      }
    case 'INITIALIZE': {
      logger.dev({ action })
      if (state.objectType === NativeObjectTypes.PropertyDefinition) {
        const propertyPayload =
          action.payload as Partial<PropertyDefinitionState>
        return {
          ...state,
          title: propertyPayload.name || '',
          objectState: {
            ...state.objectState,
            ...propertyPayload,
            propertyType: (propertyPayload.propertyType as any)?.id || null,
            selectedDefinition: propertyPayload,
          },
        }
      } else if (state.objectType === NativeObjectTypes.Opportunity) {
        const opportunityPayload = action.payload as Partial<OpportunityState>
        return {
          ...state,
          title: opportunityPayload.title || '',
          objectState: {
            ...initialOpportunityState, // Reset to initial state first
            ...opportunityPayload, // Then apply new values
          },
        }
      }
      return state
    }
    default:
      return state
  }
}

// Wrapper component for property definition form
const PropertyDefinitionFormWrapper = ({
  state,
  dispatch: editorDispatch,
  onSubmit,
  onDelete,
}: {
  state: PropertyDefinitionState
  dispatch: React.Dispatch<EditorAction>
  onSubmit: () => Promise<void>
  onDelete?: () => Promise<void>
}) => {
  // Convert editor dispatch to property definition dispatch
  const handleDispatch = (action: PropertyDefinitionAction) => {
    // First update the property definition state
    const newState = propertyDefinitionReducer(state, action)
    editorDispatch({
      type: 'SET_OBJECT_STATE',
      payload: newState,
    })

    // Then update the title if name changes
    if (action.type === 'SET_NAME') {
      editorDispatch({ type: 'SET_TITLE', payload: action.payload })
    }
  }

  return (
    <PropertyDefinitionForm
      state={state}
      dispatch={handleDispatch}
      onSubmit={onSubmit}
      onDelete={onDelete}
    />
  )
}

// Styles
const STYLES = {
  titleFlex: {
    justifyContent: 'space-between',
  },
  titleText: {
    flexShrink: 1,
  },
  finderBox: {
    mb: 1,
    mt: 1,
  },
  selectedObject: {
    mb: 2,
  },
  objectTileContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  objectTileBox: {
    width: '300px',
  },
} as const

// Wrapper component for opportunity form
const OpportunityFormWrapper = ({
  state: editorState,
  dispatch: editorDispatch,
  pipelines,
  loading: _loading,
  onSubmit: _onSubmit,
  onComplete,
  onClose,
  refetch,
}: {
  state: OpportunityState
  dispatch: React.Dispatch<EditorAction>
  pipelines: any[]
  loading: boolean
  onSubmit: () => Promise<void>
  onComplete?: () => void
  onClose?: () => void
  refetch?: () => void
}) => {
  const { currentUser: user } = useAuth()
  const {
    selectedWorkspace,
    workspaces,
    pipelines: contextPipelines,
    orgsByDomain,
    setSidebarObject,
  } = useContext(DayContext)
  const confirm = useConfirm()

  const [searchOpen, setSearchOpen] = useState(false)

  const [createOpportunityForDialog] = useMutation(
    CREATE_OPPORTUNITY_FOR_DIALOG
  )

  const handleDispatch = useCallback(
    (action: OpportunityAction) => {
      logger.dev(`DISPATCH: Starting dispatch for action type: ${action.type}`)

      // First update the opportunity state
      const newState = opportunityReducer(editorState, action)

      // Create an updates object that only includes changed fields
      const updates: Partial<OpportunityState> = {}
      Object.keys(newState).forEach((key) => {
        if (newState[key] !== editorState[key]) {
          logger.dev(`DISPATCH: Field ${key} changed:`, {
            from: editorState[key],
            to: newState[key],
          })
          updates[key] = newState[key]
        }
      })

      logger.dev('DISPATCH: Updates to be applied:', updates)

      // Only dispatch if there are actual changes
      if (Object.keys(updates).length > 0) {
        editorDispatch({
          type: 'SET_OBJECT_STATE',
          payload: updates,
        })
      }
    },
    [editorDispatch, editorState]
  )

  // Load full pipeline and stage data when initialized with IDs
  useEffect(() => {
    if (editorState.selectedPipeline?.id && pipelines?.length > 0) {
      const fullPipeline = pipelines.find(
        (p) => p.id === editorState.selectedPipeline.id
      )
      if (fullPipeline) {
        handleDispatch({ type: 'SET_PIPELINE', payload: fullPipeline })

        // If we have a stage ID, find and set the full stage data
        if (editorState.selectedStage?.id && fullPipeline.stages?.length > 0) {
          const fullStage = fullPipeline.stages.find(
            (s) => s.id === editorState.selectedStage.id
          )
          if (fullStage) {
            handleDispatch({ type: 'SET_STAGE', payload: fullStage })
          }
        }
      }
    }
  }, [
    editorState.selectedPipeline?.id,
    editorState.selectedStage?.id,
    pipelines,
    handleDispatch,
  ])

  // Handle search modal selection
  const handleSearchSelect = useCallback(
    async (result: any) => {
      logger.dev('SEARCH_SELECT: Starting search select')
      if (result.objectType === NativeObjectTypes.Organization) {
        // Get organization from context
        const org = orgsByDomain[result.objectId]
        logger.dev('SEARCH_SELECT: Found org from context')
        if (org) {
          logger.dev('SEARCH_SELECT: Creating updates array')
          // Initialize all org-related state at once
          const updates: OpportunityAction[] = [
            { type: 'SET_ORGANIZATION', payload: org },
          ]

          // Set title if not already set
          if (!editorState.title) {
            const name = org?.name || org?.domain
            updates.push({ type: 'SET_TITLE', payload: name })
          }

          // Set owner from org roles if not already set or dismissed
          if (!editorState.selectedOwner && !editorState.ownerWasDismissed) {
            const suggestedOwner = org?.overview?.['objective/roles']?.find(
              (role) => role.role.includes('OWNER_EMAIL')
            )
            logger.dev('Suggested owner:', { suggestedOwner })

            if (suggestedOwner) {
              const workspace = workspaces?.find(
                (workspace) => workspace.id === selectedWorkspace
              )
              const workspaceMember = workspace?.members?.find(
                (member) => member.email === suggestedOwner.email
              )
              if (workspaceMember) {
                updates.push({ type: 'SET_OWNER', payload: workspaceMember })
              }
            } else if (user) {
              updates.push({ type: 'SET_OWNER', payload: user })
            }
          }

          // Set expected close date if not set
          if (!editorState.expectedCloseDate) {
            let offset = 60
            if (org?.overview?.['status/recommendedStage']?.stageNumber) {
              offset -= org.overview['status/recommendedStage'].stageNumber * 7
            }
            updates.push({
              type: 'SET_EXPECTED_CLOSE_DATE',
              payload: dayjs().add(offset, 'days').toDate(),
            })
          }

          // Initialize pipeline if not set
          if (!editorState.selectedPipeline && pipelines?.length > 0) {
            const defaultPipeline = pipelines[0]
            if (defaultPipeline) {
              updates.push({ type: 'SET_PIPELINE', payload: defaultPipeline })
              if (defaultPipeline.stages?.length > 0) {
                updates.push({
                  type: 'SET_STAGE',
                  payload: defaultPipeline.stages[0],
                })
              }
            }
          }

          // Apply all updates in sequence
          logger.dev('SEARCH_SELECT: About to apply updates')
          for (const update of updates) {
            logger.dev(`SEARCH_SELECT: Dispatching update type: ${update.type}`)
            handleDispatch(update)
          }
          logger.dev('SEARCH_SELECT: Finished applying updates')
        }
      } else if (result.objectType === NativeObjectTypes.Contact) {
        // Initialize all contact-related state at once
        const updates: OpportunityAction[] = [
          { type: 'SET_PERSON', payload: result },
        ]

        // Set title if not set and no org
        if (!editorState.title && !editorState.selectedOrganization) {
          const fullName =
            NativeObjectFormatters[result.objectType].label(result)
          updates.push({
            type: 'SET_TITLE',
            payload: fullName || result.objectId,
          })
        }

        // Set owner for person without domain
        if (
          !editorState.selectedOwner &&
          !extractEmailDomain(result?.objectId) &&
          !editorState.ownerWasDismissed &&
          user
        ) {
          updates.push({ type: 'SET_OWNER', payload: user })
        }

        // Initialize pipeline if not set
        if (!editorState.selectedPipeline && pipelines?.length > 0) {
          const defaultPipeline = pipelines[0]
          if (defaultPipeline) {
            updates.push({ type: 'SET_PIPELINE', payload: defaultPipeline })
            if (defaultPipeline.stages?.length > 0) {
              updates.push({
                type: 'SET_STAGE',
                payload: defaultPipeline.stages[0],
              })
            }
          }
        }

        // Apply all updates in sequence
        for (const update of updates) {
          handleDispatch(update)
        }
      }
      setSearchOpen(false)
      logger.dev(
        'SEARCH_SELECT: Search modal closed, current organization:',
        editorState.selectedOrganization?.domain
      )
    },
    [
      selectedWorkspace,
      user,
      workspaces,
      orgsByDomain,
      pipelines,
      handleDispatch,
      editorState,
    ]
  )

  // Handle opportunity creation
  const handleOpportunityCreate = useCallback(async () => {
    const orgRoles = editorState.selectedOrganization?.roles || null
    const personRoles =
      orgRoles?.length > 0 && Array.isArray(orgRoles)
        ? orgRoles
            .filter((personRole) => !personRole.role.includes('OWNER_EMAIL'))
            .map((personRole) => ({
              personEmail: personRole.email,
              roles: personRole.role,
              reasoning: personRole.reasoningForRole,
            }))
        : editorState.selectedPerson
          ? [
              {
                personEmail: editorState.selectedPerson.objectId,
                roles: ['PRIMARY_CONTACT'],
                reasoning:
                  'Identified as primary contact in Opportunity creation process.',
              },
            ]
          : null

    const input: OpportunityCreateInput = {
      title: editorState.title,
      type: editorState.oppType,
      ownerEmail: editorState.selectedOwner.email,
      expectedCloseDate: editorState.expectedCloseDate.toISOString(),
      expectedRevenue: editorState.expectedRevenue,
      hasRevenue: !!editorState.expectedRevenue,
      domain:
        editorState.selectedOrganization?.domain ||
        extractEmailDomain(editorState.selectedPerson?.objectId),
      position: 0,
      stageId: editorState.selectedStage.id,
      pipelineId: editorState.selectedPipeline.id,
      workspaceId: selectedWorkspace,
      primaryPerson: editorState.selectedPerson?.objectId || null,
      roles: personRoles,
      currentStatus: '',
    }

    await toast.promise(
      createOpportunityForDialog({
        variables: { input },
      }),
      {
        loading: 'Creating opportunity...',
        success: (result) => {
          if (refetch) refetch()
          handleDispatch({ type: 'RESET' })
          if (onComplete) onComplete()
          if (onClose) onClose()
          setSidebarObject({
            objectType: NativeObjectTypes.Opportunity,
            objectId: result.data.createOpportunity.id,
            properties: result.data.createOpportunity,
            workspaceId: result.data.createOpportunity.workspaceId,
          })
          return 'Opportunity created!'
        },
        error: (error) => {
          logger.error('Failed to create opportunity:', error)
          return 'Error creating opportunity'
        },
      }
    )
  }, [
    onClose,
    onComplete,
    refetch,
    editorState,
    selectedWorkspace,
    createOpportunityForDialog,
    handleDispatch,
    setSidebarObject,
  ])

  const handleClearSelection = async (type: 'organization' | 'person') => {
    try {
      await confirm({
        title: `Clear ${type === 'organization' ? 'Organization' : 'Person'}?`,
        description: `Are you sure you want to clear the selected ${type}? You'll need to select a new one.`,
        confirmationText: 'Clear',
        cancellationText: 'Keep',
      })

      if (type === 'organization') {
        handleDispatch({ type: 'SET_ORGANIZATION', payload: null })
      } else {
        handleDispatch({ type: 'SET_PERSON', payload: null })
      }
      setSearchOpen(true)
    } catch {
      // User cancelled
    }
  }

  const handleDismissOwner = async () => {
    try {
      await confirm({
        title: 'Remove Owner?',
        description:
          'Are you sure you want to remove the owner? You can select a new one.',
        confirmationText: 'Remove',
        cancellationText: 'Keep',
      })

      handleDispatch({ type: 'DISMISS_OWNER' })
    } catch {
      // User cancelled
    }
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {/* Only show search button if no object selected */}
        {!editorState.selectedOrganization && !editorState.selectedPerson && (
          <Box sx={STYLES.finderBox}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setSearchOpen(true)}
              startIcon={<Search />}
            >
              Search for Organization or Person
            </Button>
          </Box>
        )}

        <SearchModal
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          onSelect={handleSearchSelect}
          forcedObjectTypes={[
            NativeObjectTypes.Organization,
            NativeObjectTypes.Contact,
          ]}
          allowCreation={true}
        />

        {editorState.selectedOrganization && (
          <Box sx={STYLES.selectedObject}>
            <Row sx={STYLES.objectTileContainer}>
              <Box sx={STYLES.objectTileBox}>
                <ObjectTile
                  objectId={editorState.selectedOrganization.domain}
                  objectType={NativeObjectTypes.Organization}
                  workspaceId={selectedWorkspace}
                  properties={editorState.selectedOrganization}
                  size={48}
                />
              </Box>
              <IconButton
                onClick={() => handleClearSelection('organization')}
                size="small"
              >
                <Close />
              </IconButton>
            </Row>
          </Box>
        )}

        {editorState.selectedPerson && (
          <Box sx={STYLES.selectedObject}>
            <Row sx={STYLES.objectTileContainer}>
              <Box sx={STYLES.objectTileBox}>
                <ObjectTile
                  objectId={editorState.selectedPerson.objectId}
                  objectType={editorState.selectedPerson.objectType}
                  workspaceId={selectedWorkspace}
                  properties={editorState.selectedPerson}
                  size={48}
                />
              </Box>
              <IconButton
                onClick={() => handleClearSelection('person')}
                size="small"
              >
                <Close />
              </IconButton>
            </Row>
          </Box>
        )}

        {(editorState.selectedPerson || editorState.selectedOrganization) && (
          <OpportunityCreateForm
            state={editorState}
            dispatch={handleDispatch}
            pipelines={contextPipelines || []}
            loading={false}
            onSubmit={handleOpportunityCreate}
            onDismissOwner={handleDismissOwner}
          />
        )}
      </Box>
    </Box>
  )
}

const SidebarObjectEditor = ({
  objectType,
  mode = 'create',
  initialData = null,
  workspaceId,
  onComplete,
}: SidebarObjectEditorProps) => {
  const { workspaces, pipelines: contextPipelines } = useContext(DayContext)
  const confirm = useConfirm()

  // Initialize editor state
  const [state, dispatch] = useReducer(editorReducer, {
    ...initialEditorState,
    mode,
    objectType,
    workspaceId,
    objectState:
      objectType === NativeObjectTypes.Opportunity
        ? initialOpportunityState
        : initialPropertyDefinitionState,
  })

  // Initialize with initial data if provided
  useEffect(() => {
    if (initialData) {
      const workspace = workspaces?.find(
        (workspace) => workspace.id === workspaceId
      )

      // If we have a pipelineId and stageId in initialData, we should initialize with those
      const initialPayload: Partial<OpportunityState> = {
        ...initialData,
        selectedPipeline: initialData.pipelineId
          ? {
              id: initialData.pipelineId,
              // We'll get the full pipeline data when the form loads
            }
          : null,
        selectedStage: initialData.stageId
          ? {
              id: initialData.stageId,
              // We'll get the full stage data when the form loads
            }
          : null,
      }

      // If we have an ownerEmail in initialData, find the workspace member
      if (initialData.ownerEmail && workspace?.members) {
        const owner = workspace.members.find(
          (member) => member.email === initialData.ownerEmail
        )
        if (owner) {
          initialPayload.selectedOwner = owner
        }
      }

      logger.dev('Initializing editor with data:', initialPayload)
      dispatch({ type: 'INITIALIZE', payload: initialPayload })
    }
  }, [initialData, workspaceId, workspaces])

  // Property Definition Mutations
  const [createPropertyDefinition] = useMutation(CREATE_PROPERTY_DEFINITION, {
    refetchQueries: [
      { query: PROPERTY_DEFINITIONS, variables: { workspaceId } },
    ],
  })

  const [updatePropertyDefinition] = useMutation(UPDATE_PROPERTY_DEFINITION, {
    refetchQueries: [
      { query: PROPERTY_DEFINITIONS, variables: { workspaceId } },
    ],
  })

  const [deletePropertyDefinition] = useMutation(DELETE_PROPERTY_DEFINITION, {
    refetchQueries: [
      { query: PROPERTY_DEFINITIONS, variables: { workspaceId } },
    ],
  })

  // Add opportunity mutation
  const [createOpportunityForDialog] = useMutation(
    CREATE_OPPORTUNITY_FOR_DIALOG
  )

  const handleCreatePropertyDefinition = useCallback(
    async (input) => {
      toast.promise(
        createPropertyDefinition({
          variables: {
            workspaceId,
            input,
          },
        }),
        {
          loading: 'Creating property definition...',
          success: () => {
            if (onComplete) onComplete()
            return 'Property definition created'
          },
          error: 'Failed to create property definition',
        }
      )
    },
    [createPropertyDefinition, workspaceId, onComplete]
  )

  const handleUpdatePropertyDefinition = useCallback(
    async (id, input) => {
      toast.promise(
        updatePropertyDefinition({
          variables: {
            workspaceId,
            id,
            input,
          },
        }),
        {
          loading: 'Updating property definition...',
          success: () => {
            if (onComplete) onComplete()
            return 'Property definition updated'
          },
          error: 'Failed to update property definition',
        }
      )
    },
    [updatePropertyDefinition, workspaceId, onComplete]
  )

  const handleDeletePropertyDefinition = useCallback(
    async (id) => {
      toast.promise(
        deletePropertyDefinition({
          variables: {
            workspaceId,
            id,
          },
        }),
        {
          loading: 'Deleting property definition...',
          success: () => {
            if (onComplete) onComplete()
            return 'Property definition deleted'
          },
          error: 'Failed to delete property definition',
        }
      )
    },
    [deletePropertyDefinition, workspaceId, onComplete]
  )

  // Common UI elements
  const renderHeader = () => (
    <Box sx={{ p: 2 }}>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h3">
          {state.mode === 'create' ? 'Create' : 'Edit'}{' '}
          {ObjectTypeMetadata[state.objectType].label}
        </Typography>
      </Row>
    </Box>
  )

  // Render appropriate form based on object type
  const renderForm = () => {
    switch (state.objectType) {
      case NativeObjectTypes.Opportunity:
        return (
          <OpportunityFormWrapper
            state={state.objectState as OpportunityState}
            dispatch={dispatch}
            pipelines={contextPipelines || []}
            loading={false}
            onSubmit={handleSubmit}
            onComplete={onComplete}
            refetch={() => {
              // Add any refetch queries needed after opportunity creation
            }}
            onClose={() => {
              if (onComplete) onComplete()
            }}
          />
        )
      case NativeObjectTypes.PropertyDefinition:
        return (
          <PropertyDefinitionFormWrapper
            state={state.objectState as PropertyDefinitionState}
            dispatch={dispatch}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        )
      default:
        return null
    }
  }

  const handleSubmit = async () => {
    try {
      switch (state.objectType) {
        case NativeObjectTypes.Opportunity: {
          const opportunityState = state.objectState as OpportunityState
          const orgRoles = opportunityState.selectedOrganization?.roles || null
          const personRoles =
            orgRoles?.length > 0 && Array.isArray(orgRoles)
              ? orgRoles
                  .filter(
                    (personRole) => !personRole.role.includes('OWNER_EMAIL')
                  )
                  .map((personRole) => ({
                    personEmail: personRole.email,
                    roles: personRole.role,
                    reasoning: personRole.reasoningForRole,
                  }))
              : opportunityState.selectedPerson
                ? [
                    {
                      personEmail: opportunityState.selectedPerson.objectId,
                      roles: ['PRIMARY_CONTACT'],
                      reasoning:
                        'Identified as primary contact in Opportunity creation process.',
                    },
                  ]
                : null

          const input: OpportunityCreateInput = {
            title: opportunityState.title,
            type: opportunityState.oppType,
            ownerEmail: opportunityState.selectedOwner.email,
            expectedCloseDate: opportunityState.expectedCloseDate.toISOString(),
            expectedRevenue: opportunityState.expectedRevenue,
            hasRevenue: !!opportunityState.expectedRevenue,
            domain:
              opportunityState.selectedOrganization?.domain ||
              extractEmailDomain(opportunityState.selectedPerson?.objectId),
            position: 0,
            stageId: opportunityState.selectedStage.id,
            pipelineId: opportunityState.selectedPipeline.id,
            workspaceId,
            primaryPerson: opportunityState.selectedPerson?.objectId || null,
            roles: personRoles,
            currentStatus: '',
          }

          await toast.promise(
            createOpportunityForDialog({
              variables: { input },
            }),
            {
              loading: 'Creating opportunity...',
              success: () => {
                if (onComplete) onComplete()
                return 'Opportunity created!'
              },
              error: (error) => {
                logger.error('Failed to create opportunity:', error)
                return 'Error creating opportunity'
              },
            }
          )
          break
        }
        case NativeObjectTypes.PropertyDefinition: {
          const propertyState = state.objectState as PropertyDefinitionState
          const input = {
            name: propertyState.name,
            description: propertyState.description,
            objectTypeId: NativeObjectTypes.Organization,
            propertyTypeId: propertyState.propertyType,
            aiManaged: propertyState.aiEnabled,
            useWeb: propertyState.useWeb,
            options: propertyState.options.map(({ name, description }) => ({
              name,
              description: description || '',
            })),
          }

          if (state.mode === 'create') {
            await handleCreatePropertyDefinition(input)
          } else {
            await handleUpdatePropertyDefinition(
              propertyState.selectedDefinition.id,
              input
            )
          }
          break
        }
      }
    } catch (error) {
      logger.error(`Failed to ${state.mode} ${state.objectType}:`, error)
      toast.error(
        `Error ${state.mode === 'create' ? 'creating' : 'updating'} ${
          state.objectType
        }`
      )
    }
  }

  const handleDelete = async () => {
    const propertyState = state.objectState as PropertyDefinitionState
    if (!propertyState.selectedDefinition) return

    try {
      await confirm({
        title: 'Delete Property',
        description: `Are you sure you want to delete "${propertyState.name}"? This action cannot be undone.`,
      })

      await handleDeletePropertyDefinition(propertyState.selectedDefinition.id)
    } catch (error) {
      // If error is from user canceling the confirmation, just return
      if (error?.message) {
        logger.error('Failed to delete property definition:', error)
      }
    }
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {renderHeader()}
      <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto' }}>{renderForm()}</Box>
    </Box>
  )
}

export default SidebarObjectEditor
