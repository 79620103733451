import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'

import {
  Button,
  Checkbox,
  Tooltip,
  FormControlLabel,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { IconButton, ListItem, Menu, TextField } from '@mui/material'
import {
  IconChevronDown,
  IconChevronLeft,
  IconStack,
  IconStack3Filled,
  IconStackFront,
  IconX,
} from '@tabler/icons-react'

import Row from '../Row/Row'

import useViews from './useViews'

type ViewCreateState = 'create' | 'pin' | null

const ViewCreate = () => {
  const {
    createView,
    objectType,
    workspaceId,
    getExternalGridState,
    pinnedViews,
    views,
    removePin,
    addPin,
  } = useViews()

  const defaultViewState: ViewCreateState = useMemo(
    () => (pinnedViews?.length > 0 ? null : 'create'),
    [pinnedViews]
  )

  const [addViewState, setAddViewState] =
    useState<ViewCreateState>(defaultViewState)

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)
  const [newViewTitle, setNewViewTitle] = useState('')
  const [newViewDescription, setNewViewDescription] = useState('')
  const [showDescription, setShowDescription] = useState(false)
  const [shareWithWorkspace, setShareWithWorkspace] = useState(false)

  const handleMenuClose = useCallback(() => {
    setCreateMenuEl(null)
    setTimeout(() => {
      setNewViewTitle('')
      setNewViewDescription('')
      setShowDescription(false)
      setShareWithWorkspace(false)
      setAddViewState(defaultViewState)
    }, 250)
  }, [
    defaultViewState,
    setNewViewTitle,
    setNewViewDescription,
    setShowDescription,
    setShareWithWorkspace,
    setAddViewState,
  ])

  const handleCreateView = useCallback(async () => {
    await createView({
      objectType,
      workspaceId,
      title: newViewTitle,
      description: newViewDescription,
      gridState: getExternalGridState() as any,
      shareWithWorkspace,
      position: pinnedViews.length,
    })
    handleMenuClose()
  }, [
    createView,
    newViewTitle,
    newViewDescription,
    objectType,
    workspaceId,
    shareWithWorkspace,
    pinnedViews.length,
    handleMenuClose,
    getExternalGridState,
  ])

  const titleRef = useRef<HTMLInputElement>(null)
  const handleCreateClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
    },
    []
  )

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewViewTitle(e.target.value)
    },
    []
  )

  const handleTitleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        await handleCreateView()
      }
    },
    [handleCreateView]
  )

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      setNewViewDescription(e.target.value)
    },
    []
  )

  const handleDescriptionKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
    },
    []
  )

  const handleDescriptionToggle = useCallback(() => {
    setShowDescription((prev) => !prev)
  }, [])

  const handleWorkspaceShareChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShareWithWorkspace(e.target.checked)
    },
    []
  )

  const pinnedViewsIds = useMemo(() => {
    return pinnedViews?.map((v) => v.id)
  }, [pinnedViews])

  useEffect(() => {
    if (addViewState === 'create') {
      titleRef.current?.focus()
    }
  }, [addViewState])

  return (
    <>
      <Tooltip
        title="Add view"
        arrow={true}
        placement="top"
      >
        <IconButton
          onClick={handleCreateClick}
          sx={{
            borderRadius: '3px',
            p: '6px',
            opacity: 0.6,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <IconStackFront size={18} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={createMenuEl}
        open={Boolean(createMenuEl)}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': { width: '320px' },
          '& .MuiList-root': { py: 0 },
          '& .MuiListItemText-primary': {
            fontSize: '11px',
            opacity: 0.9,
            letterSpacing: '-0.2px',
          },
          '& .MuiListItemIcon-root': {
            minWidth: '24px',
          },
        }}
      >
        {!addViewState && (
          <ListItemButton onClick={() => setAddViewState('create')}>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <IconStack size={16} />
            </ListItemIcon>
            <ListItemText primary="Save as new view" />
          </ListItemButton>
        )}
        {!addViewState && (
          <ListItemButton onClick={() => setAddViewState('pin')}>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <IconStack3Filled size={16} />
            </ListItemIcon>
            <ListItemText primary={`Load saved view`} />
          </ListItemButton>
        )}

        {addViewState === 'create' && (
          <Row
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              p: 2,
              pt: 1,
            }}
            gap={1}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                letterSpacing: '-0.4px',
              }}
            >
              Save view
            </Typography>
            <IconButton
              onClick={() => setAddViewState(null)}
              sx={{
                p: '3px',
                opacity: 0.5,
                '&:hover': { opacity: 1 },
                borderRadius: '2px',
              }}
            >
              <IconX
                size={16}
                stroke={2.5}
              />
            </IconButton>
          </Row>
        )}
        {addViewState === 'create' && (
          <ListItem>
            <Row
              sx={{ width: '320px' }}
              gap={1}
            >
              <TextField
                inputRef={titleRef}
                label="Title"
                value={newViewTitle}
                onChange={handleTitleChange}
                onKeyDown={handleTitleKeyDown}
                fullWidth={true}
                size="small"
              />
              <IconButton
                onClick={handleDescriptionToggle}
                sx={{
                  p: '4px',
                  borderRadius: '3px',
                }}
              >
                {!showDescription ? (
                  <IconChevronLeft size={20} />
                ) : (
                  <IconChevronDown size={20} />
                )}
              </IconButton>
            </Row>
          </ListItem>
        )}

        {addViewState === 'create' && showDescription && (
          <ListItem>
            <TextField
              label="Description"
              value={newViewDescription}
              onChange={handleDescriptionChange}
              onKeyDown={handleDescriptionKeyDown}
              fullWidth={true}
              size="small"
              multiline={true}
              rows={3}
              autoComplete="off"
            />
          </ListItem>
        )}

        {addViewState === 'create' && showDescription && (
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithWorkspace}
                  onChange={handleWorkspaceShareChange}
                />
              }
              label="Share with workspace"
            />
          </ListItem>
        )}

        {addViewState === 'create' && (
          <ListItem>
            <Button
              fullWidth={true}
              variant="outlined"
              onClick={handleCreateView}
              disabled={!newViewTitle}
              color="secondary"
            >
              Create
            </Button>
          </ListItem>
        )}
        {addViewState === 'pin' &&
          views.map((v) => (
            <ListItemButton
              key={v.id}
              onClick={() => {
                if (pinnedViewsIds?.includes(v.id)) {
                  removePin(v.id)
                } else {
                  addPin(v.id)
                }
              }}
              sx={{
                py: 1,
              }}
            >
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Checkbox checked={pinnedViewsIds?.includes(v.id)} />
              </ListItemIcon>
              <ListItemText
                primary={v.title}
                primaryTypographyProps={{
                  fontSize: '12px',
                  fontWeight: 500,
                  letterSpacing: '-0.3px',
                }}
                sx={{ my: 0 }}
              />
            </ListItemButton>
          ))}
      </Menu>
    </>
  )
}

export default ViewCreate
