import { useMemo } from 'react'

import { Box, TextField } from '@mui/material'
import type { ActionPromptOutput } from 'types/graphql'

import SuggestedPromptRow from 'src/components/Prompts/SuggestedPromptRow'
import Row from 'src/components/Row/Row'
import ObjectContextManager from 'src/components/Threads/ObjectContextManager/ObjectContextManager'

interface PromptInputProps {
  prompt: string
  setPrompt: (prompt: string) => void
  suggestedPrompts?: ActionPromptOutput[]
  onSubmit?: () => void
  placeholder?: string
  // Context manager props
  contextObjects: any[]
  suggestedObjects: any[]
  onHideObject?: (objectId: string) => void
  onUpdateContextObjects: (objects: any[]) => void
  workspaceId?: string
  contextStatus?: 'loading' | 'loaded' | 'error_capacity' | 'error_unknown'
  showStatus?: boolean
  showDebug?: boolean
  debugComponent?: 'page' | 'thread'
  variant?: 'new' | undefined
  buttonLabel?: string
  // Optional action button to render in top right
  actionButton?: React.ReactNode
}

const PromptInput = ({
  prompt,
  setPrompt,
  suggestedPrompts = [],
  onSubmit,
  placeholder = "Let's take action. What should we make?",
  contextObjects,
  suggestedObjects,
  onHideObject,
  onUpdateContextObjects,
  workspaceId,
  contextStatus,
  showStatus,
  showDebug,
  debugComponent,
  variant,
  buttonLabel,
  actionButton,
}: PromptInputProps) => {
  const upperContainerSx = useMemo(
    () => ({
      background: (theme) => theme.palette.background.paper,
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      border: (theme) => `1px solid ${theme.palette.divider}`,
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      p: 1,
      position: 'relative',
    }),
    []
  )

  const lowerContainerSx = useMemo(
    () => ({
      background: (theme) => theme.palette.background.paper,
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      border: (theme) => `1px solid ${theme.palette.divider}`,
      borderTop: 'none',
      p: 1,
      position: 'relative',
    }),
    []
  )

  const containerWrapperSx = useMemo(
    () => ({
      mb: 2,
      mx: 1,
      mt: 1,
      borderRadius: '12px',
      boxShadow: `
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 2px 6px rgba(0, 0, 0, 0.08),
      0 4px 12px rgba(0, 0, 0, 0.05)
    `,
    }),
    []
  )

  const suggestedPromptRowsHeight = useMemo(() => {
    if (suggestedPrompts.length === 0 || prompt !== '') {
      return '20px'
    }
    return '60px'
  }, [suggestedPrompts, prompt])

  const textFieldRows = useMemo(() => {
    if (suggestedPrompts.length === 0 || prompt === '') {
      return 2
    }
    return 4
  }, [suggestedPrompts, prompt])

  return (
    <Box sx={containerWrapperSx}>
      <Box sx={upperContainerSx}>
        <TextField
          multiline={true}
          rows={textFieldRows}
          fullWidth={true}
          value={prompt}
          variant="standard"
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPrompt(e.target.value)
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && !e.shiftKey && onSubmit) {
              e.preventDefault()
              onSubmit()
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              display: 'flex',
              flexDirection: 'column',
              p: '4px',
              pt: '12px',
              pl: '12px',
            },
            endAdornment: suggestedPrompts.length > 0 && (
              <Box sx={{ width: '100%' }}>
                <Row
                  sx={{
                    flexWrap: 'wrap-reverse',
                    gap: 1,
                    width: '100%',
                    height: suggestedPromptRowsHeight,
                  }}
                >
                  <SuggestedPromptRow
                    prompts={suggestedPrompts}
                    onAddPrompt={setPrompt}
                    hide={prompt !== ''}
                  />
                </Row>
              </Box>
            ),
          }}
        />
        {actionButton && (
          <Row sx={{ position: 'absolute', right: 8, bottom: 8 }}>
            {actionButton}
          </Row>
        )}
      </Box>
      <Box sx={lowerContainerSx}>
        <Box
          sx={{
            opacity: prompt !== '' ? 0.7 : 1,
            px: 1.5,
            mb: '2px',
            transition: 'opacity 0.1s ease-in, opacity 0.4s ease-out',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <ObjectContextManager
            variant={variant}
            contextObjects={contextObjects}
            onUpdateContextObjects={onUpdateContextObjects}
            buttonLabel={buttonLabel}
            workspaceId={workspaceId}
            onHideObject={onHideObject}
            suggestedObjects={suggestedObjects}
            contextStatus={contextStatus}
            showStatus={showStatus}
            showDebug={showDebug}
            debugComponent={debugComponent}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PromptInput
