import type { ReactNode } from 'react'
import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import type {
  GlobalWorkAccountWorkspaceConnectionsQuery,
  Organization,
  Person,
  Pipeline,
} from 'types/graphql'
import type { WorkAccountWorkspaceConnection, Workspace } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router/dist/useRouteName'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { onboardingStatus } from 'src/lib/welcome'

import { extractEmailDomain } from './contactFormatting'
import {
  getWorkspacePeople,
  getWorkspaceOrganizations,
  cacheWorkspaceOrganizations,
  cacheWorkspacePeople,
} from './indexedDb'
import { logger } from './logger'

const USER_CORE_CONTACT_QUERY = gql`
  query UserCoreContactQuery($email: String!) {
    coreContactFromPerson(email: $email) {
      id
      email
      firstName
      lastName
      title
      photo
      description
      linkedInUrl
      timezone
      travelTimezone
    }
  }
`

const WORK_ACCOUNTS_QUERY = gql`
  query DayContextFindWorkAccountByOwnerEmail($ownerEmail: String!) {
    workAccounts(ownerEmail: $ownerEmail) {
      id
      createdAt
      uuid
      email
      name
      tokenError
      scopes
      crm3WorkspaceId
      googleSyncStatuses {
        type
        status
        reason
        statusChangedAt
        lastSeenSyncToken
        lastSyncedAt
      }
      calendarAutoRecordSettings {
        mode
        availableModes
      }
      notificationSubscriptionOverrides {
        notificationKey
        enabled
      }
      workspaces {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const FETCH_WORKSPACES_AND_ROLES = gql`
  query FetchWorkspacesAndRoles($userEmail: String!) {
    workspaces(userEmail: $userEmail) {
      id
      name
      status
      domains {
        domain
        autoInvite
        autoInviteRoleId
      }
      claimableDomains
      isDefault
      members {
        id
        email
        status
        isDefaultOwner
        roleId
        coreContact {
          firstName
          lastName
          photo
          title
        }
      }
      roles {
        id
        name
        description
        permissions {
          type
          permission
          scope
        }
      }
    }
  }
`

const WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY = gql`
  query GlobalWorkAccountWorkspaceConnectionsQuery {
    workAccountWorkspaceConnections {
      workAccountUuid
      connectedWorkspaces {
        workspaceId
        sharingRules {
          category
          selector {
            type
            expression
          }
          authorization {
            type
            id
          }
        }
      }
    }
  }
`

const MEETING_RECORDINGS_FOR_CONTEXT = gql`
  query MeetingRecordingsForContext(
    $workspaceId: String!
    $limit: Int!
    $page: Int!
  ) {
    workspaceMeetingRecordings(
      workspaceId: $workspaceId
      limit: $limit
      page: $page
    ) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

const GET_PIPELINES_FOR_DAYCONTEXT = gql`
  query GetPipelinesForDayContext($workspaceId: String!) {
    workspacePipelines(workspaceId: $workspaceId) {
      id
      workspaceId
      title
      type
      hasRevenue
      updatedAt
      opportunityTypes
      ownerEmails
      setupSteps
      stages {
        id
        title
        entranceCriteria
        likelihoodToClose
        type
        opportunities {
          id
          title
          ownerEmail
          ownerId
          expectedCloseDate
          expectedRevenue
          domain
          position
          status
          daysInStage
          type
          roles {
            personEmail
            roles
            reasoning
          }
        }
        position
      }
    }
  }
`

const GET_SUGGESTED_EXCLUSIONS_CONTEXT = gql`
  query GetSuggestedExclusions($workspaceId: String!, $workAccountId: String!) {
    getSuggestedExclusions(
      workspaceId: $workspaceId
      workAccountId: $workAccountId
    ) {
      reason
      domain
      category
    }
  }
`

const GET_WORKSPACE_PEOPLE_CONTEXT = gql`
  query GetWorkspacePeople($workspaceId: String!, $page: Int) {
    workspacePeople(workspaceId: $workspaceId, page: $page) {
      id
      email
      fullName
      photoUrl
      email
      currentJobTitle
      linkedInUrl
      relationship {
        long
        short
        oneSentence
        type
      }
    }
  }
`

const GET_WORKSPACE_ORGANIZATIONS_1 = gql`
  query GetWorkspaceOrganizations($workspaceId: String!, $page: Int) {
    workspaceOrganizations(workspaceId: $workspaceId, page: $page) {
      id
      name
      domain
      updatedAt
      photos {
        id
        square
      }
      roles {
        id
        email
        name
        reasoning
        role
      }
      employeeCount
      annualRevenue
      funding
      colors {
        id
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorLightMuted
        colorDarkMuted
      }
      links {
        id
        facebook
        linkedIn
        x
        instagram
        youtube
        website
      }
      about {
        id
        aiDescription
        description
        industry
        selling
        employeesTo
        employeesFrom
      }
      relationship {
        id
        types {
          id
          label
          key
          value
          source
          userId
          createdAt
        }
        upcomingEvents
        quotes {
          id
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        proofOfPaymentReasoning
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      customProperties
    }
  }
`

export interface DayContextProps {
  userCoreContact: any
  refetchUserCoreContact: () => void
  workAccounts: any[]
  setWorkAccounts: (workAccounts: any[]) => void
  refetchWorkAccounts: () => void
  workspaces: Workspace[]
  refetchWorkspaces: () => Promise<any>
  sidebarObject: any
  setSidebarObject: (sidebarObject: any) => void
  workAccountWorkspaceConnections: WorkAccountWorkspaceConnection[]
  refetchWorkAccountWorkspaceConnections: () => void
  selectedWorkspace: string | null
  setSelectedWorkspace: (workspaceId: string) => void
  refetchPeople: () => void
  loading: boolean
  internalDomains: string[]
  selectedWorkspaceLoading: boolean
  suggestedExclusions: any[]
  refetchSuggestedExclusions: () => void
  workspacePeople: Person[]
  workspaceOrganizations: Organization[]
  refetchWorkspaceOrganizations: () => void
  orgsByDomain: Record<string, Organization>
  peopleByEmail: Record<string, Person>
  pipelines: Pipeline[]
  refetchPipelines: () => void
  meetingRecordings: any[]
}

export const DayContext = createContext<DayContextProps>({
  userCoreContact: null,
  refetchUserCoreContact: () => {},
  workAccounts: [],
  setWorkAccounts: () => {},
  refetchWorkAccounts: () => {},
  workspaces: [],
  refetchWorkspaces: async () => {},
  workAccountWorkspaceConnections: null,
  refetchWorkAccountWorkspaceConnections: () => {},
  sidebarObject: null,
  setSidebarObject: () => {},
  selectedWorkspace: null,
  setSelectedWorkspace: () => {},
  refetchPeople: () => {},
  loading: false,
  internalDomains: [],
  selectedWorkspaceLoading: true,
  suggestedExclusions: [],
  refetchSuggestedExclusions: () => {},
  workspacePeople: [],
  workspaceOrganizations: [],
  refetchWorkspaceOrganizations: () => {},
  orgsByDomain: {},
  peopleByEmail: {},
  pipelines: [],
  refetchPipelines: () => {},
  meetingRecordings: [],
})

interface DayProviderProps {
  children: ReactNode
}

// LocalStorage will happily stringify null/undefined values
// and then hand them back to you as strings, which messes up
// truthy/falsey checks. Let's immediately convert them back
// before using them in business logic.
const parseLocalStorageValue = (value: string) => {
  if (value === 'null' || value === 'undefined') return null

  return value
}

const selectedWorkspaceKey = 'selectedWorkspaceV2'

export const DayProvider: React.FC<DayProviderProps> = ({ children }) => {
  const { currentUser } = useAuth()
  const route = useRouteName()
  const [sidebarObject, setSidebarObject] = useState<any>(null)
  const [workAccounts, setWorkAccounts] = useState<any[]>([])
  const [workAccountWorkspaceConnections, setWorkAccountWorkspaceConnections] =
    useState<WorkAccountWorkspaceConnection[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(
    null
  )

  const [peopleByEmail, setPeopleByEmail] = useState<Record<string, Person>>({})
  const [orgsByDomain, setOrgsByDomain] = useState<
    Record<string, Organization>
  >({})
  const [workspacePeoplePage, setWorkspacePeoplePage] = useState(0)
  const [isLoadingMorePeople, setIsLoadingMorePeople] = useState(false)
  const [hasMorePeople, setHasMorePeople] = useState(true)
  const [selectedWorkspaceLoading, setSelectedWorkspaceLoading] = useState(true)
  const [workspaceOrganizationsPage, setWorkspaceOrganizationsPage] =
    useState(0)
  const [isLoadingMoreOrganizations, setIsLoadingMoreOrganizations] =
    useState(false)
  const [hasMoreOrganizations, setHasMoreOrganizations] = useState(true)

  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [meetingRecordingsPageInfo, setMeetingRecordingsPageInfo] =
    useState<any>(null)
  const [meetingRecordingsPage, setMeetingRecordingsPage] = useState<number>(1)

  const selectedWorkspaceFromLocalStorage = useMemo(() => {
    return parseLocalStorageValue(localStorage.getItem(selectedWorkspaceKey))
  }, [])

  const {
    data: workspacesData,
    refetch: refetchWorkspacesQuery,
    loading: workspacesLoading,
  } = useQuery(FETCH_WORKSPACES_AND_ROLES, {
    variables: { userEmail: currentUser?.email },
    skip: !currentUser,
  })

  const workspaces = useMemo(
    () => workspacesData?.workspaces || [],
    [workspacesData]
  )

  // Immediately set workspace from localStorage if available
  useEffect(() => {
    const storedWorkspace = selectedWorkspaceFromLocalStorage
    if (storedWorkspace && !selectedWorkspace) {
      setSelectedWorkspace(storedWorkspace)
      setSelectedWorkspaceLoading(true)
      localStorage.setItem(selectedWorkspaceKey, storedWorkspace)
    }
  }, [selectedWorkspaceFromLocalStorage, selectedWorkspace])

  // Once workspaces data is available, validate or set default
  useEffect(() => {
    if (!workspaces?.length) return

    const defaultWorkspaceId =
      workspaces.find((ws) => {
        return ws.domains?.length > 0
      })?.id || workspaces[0]?.id

    if (!defaultWorkspaceId) {
      logger.warn('No default workspace available')
      return
    }

    // If we have a selected workspace, validate it exists in workspaces
    if (selectedWorkspace) {
      const workspaceIds = workspaces.map(({ id }) => id)
      if (!workspaceIds.includes(selectedWorkspace)) {
        logger.dev(
          'Selected workspace not found in available workspaces, using default'
        )
        setSelectedWorkspace(defaultWorkspaceId)
        localStorage.setItem(selectedWorkspaceKey, defaultWorkspaceId)
      }
    }
    // If no workspace is selected, use stored or default
    else if (
      selectedWorkspaceFromLocalStorage &&
      workspaces.map(({ id }) => id).includes(selectedWorkspaceFromLocalStorage)
    ) {
      setSelectedWorkspace(selectedWorkspaceFromLocalStorage)
      localStorage.setItem(
        selectedWorkspaceKey,
        selectedWorkspaceFromLocalStorage
      )
    } else {
      setSelectedWorkspace(defaultWorkspaceId)
      localStorage.setItem(selectedWorkspaceKey, defaultWorkspaceId)
    }

    setSelectedWorkspaceLoading(false)
  }, [workspaces, selectedWorkspace, selectedWorkspaceFromLocalStorage])

  // Load cached data when workspace changes
  useEffect(() => {
    if (selectedWorkspace) {
      logger.dev(`Loading cache for workspace ${selectedWorkspace}`)
      const loadCache = async () => {
        const cachedPeople = await getWorkspacePeople(selectedWorkspace)
        if (Object.keys(cachedPeople).length > 0) {
          setPeopleByEmail(cachedPeople)
        }

        const cachedOrgs = await getWorkspaceOrganizations(selectedWorkspace)
        if (Object.keys(cachedOrgs).length > 0) {
          setOrgsByDomain(cachedOrgs)
        }
      }

      loadCache()
    } else {
      logger.dev('No workspace selected, skipping cache load')
    }
  }, [selectedWorkspace])

  const peopleCompleted = useRef(false)
  useEffect(() => {
    const getCachedPeople = async () => {
      if (selectedWorkspace && Object.keys(peopleByEmail).length > 0) {
        const cachedPeople = await getWorkspacePeople(selectedWorkspace)
        if (Object.keys(cachedPeople).length > 0) {
          setPeopleByEmail(cachedPeople)
        }
      }
    }

    if (!peopleByEmail || (!hasMorePeople && !peopleCompleted.current)) {
      getCachedPeople()
      peopleCompleted.current = true
    }
  }, [selectedWorkspace, hasMorePeople, peopleByEmail])

  const orgsCompleted = useRef(false)

  useEffect(() => {
    const getCachedOrgs = async () => {
      if (selectedWorkspace) {
        const cachedOrgs = await getWorkspaceOrganizations(selectedWorkspace)
        if (Object.keys(cachedOrgs).length > 0) {
          setOrgsByDomain(cachedOrgs)
        }
      }
    }

    if (
      Object.keys(orgsByDomain).length === 0 ||
      (!hasMoreOrganizations && !orgsCompleted.current)
    ) {
      getCachedOrgs()
      orgsCompleted.current = true
    }
  }, [selectedWorkspace, hasMoreOrganizations, orgsByDomain])

  const {
    data: userCoreContactData,
    refetch: refetchUserCoreContactQuery,
    loading: userCoreContactLoading,
  } = useQuery(USER_CORE_CONTACT_QUERY, {
    variables: { email: currentUser?.email },
    skip: !currentUser,
  })

  const refetchUserCoreContact = useCallback(() => {
    refetchUserCoreContactQuery()
  }, [refetchUserCoreContactQuery])

  const userCoreContact = userCoreContactData?.coreContactFromPerson

  const PRECACHE_MEETING_RECORDINGS = true

  useQuery(MEETING_RECORDINGS_FOR_CONTEXT, {
    variables: {
      limit: 20,
      page: meetingRecordingsPage,
      workspaceId: selectedWorkspace,
    },
    onCompleted: (data) => {
      logger.dev(
        `Loaded ${data.workspaceMeetingRecordings.recordings.length} meeting recordings`
      )
      setMeetingRecordings((prev) => [
        ...prev,
        ...data.workspaceMeetingRecordings.recordings,
      ])
      setMeetingRecordingsPageInfo(data.workspaceMeetingRecordings.pageInfo)
      setMeetingRecordingsPage(
        data.workspaceMeetingRecordings.pageInfo.page + 1
      )
    },
    skip:
      !selectedWorkspace ||
      !PRECACHE_MEETING_RECORDINGS ||
      meetingRecordingsPageInfo?.totalPages < meetingRecordingsPage,
  })

  const {
    data: workAccountsData,
    refetch: refetchWorkAccountsQuery,
    loading: workAccountsLoading,
  } = useQuery(WORK_ACCOUNTS_QUERY, {
    variables: {
      ownerEmail: currentUser?.email,
    },
    skip: !currentUser,
    onCompleted: (data) => {
      setWorkAccounts(data.workAccounts)
    },
  })

  const refetchWorkAccounts = useCallback(() => {
    refetchWorkAccountsQuery()
  }, [refetchWorkAccountsQuery])

  const refetchWorkspaces = useCallback(() => {
    return refetchWorkspacesQuery()
  }, [refetchWorkspacesQuery])

  const { refetch: refetchWorkspacePeopleQuery } = useQuery(
    GET_WORKSPACE_PEOPLE_CONTEXT,
    {
      variables: { workspaceId: selectedWorkspace, page: workspacePeoplePage },
      skip: !selectedWorkspace || !hasMorePeople,
      onCompleted: (data) => {
        if (data?.workspacePeople?.length === 0) {
          setHasMorePeople(false)
          return
        }
        logger.dev(`Loaded ${data.workspacePeople.length} more people`)

        cacheWorkspacePeople(selectedWorkspace, data.workspacePeople)

        setWorkspacePeoplePage((page) => page + 1)
        setIsLoadingMorePeople(false)
      },
    }
  )

  const workspacePeople = useMemo(() => {
    return Object.values(peopleByEmail)
  }, [peopleByEmail])

  // Start loading when workspace changes
  useEffect(() => {
    if (selectedWorkspace) {
      setWorkspacePeoplePage(0)
      setPeopleByEmail({})
      setHasMorePeople(true)
      setIsLoadingMorePeople(true)
      setWorkspaceOrganizationsPage(0)
      setHasMoreOrganizations(true)
      setIsLoadingMoreOrganizations(true)
    }
  }, [selectedWorkspace])

  // Continue loading next page
  useEffect(() => {
    if (hasMorePeople && !isLoadingMorePeople && selectedWorkspace) {
      setIsLoadingMorePeople(true)
    }
  }, [hasMorePeople, isLoadingMorePeople, selectedWorkspace])

  useEffect(() => {
    if (
      hasMoreOrganizations &&
      !isLoadingMoreOrganizations &&
      selectedWorkspace
    ) {
      setIsLoadingMoreOrganizations(true)
    }
  }, [hasMoreOrganizations, isLoadingMoreOrganizations, selectedWorkspace])

  const refetchPeople = useCallback(() => {
    refetchWorkspacePeopleQuery()
  }, [refetchWorkspacePeopleQuery])

  const { refetch: refetchWorkspaceOrganizationsBasic } = useQuery(
    GET_WORKSPACE_ORGANIZATIONS_1,
    {
      variables: {
        workspaceId: selectedWorkspace,
        page: workspaceOrganizationsPage,
      },
      skip: !selectedWorkspace || !hasMoreOrganizations,
      onCompleted: (data) => {
        if (
          !data?.workspaceOrganizations ||
          data.workspaceOrganizations.length === 0
        ) {
          setHasMoreOrganizations(false)
          return
        }

        cacheWorkspaceOrganizations(
          selectedWorkspace,
          data.workspaceOrganizations
        )

        setWorkspaceOrganizationsPage((page) => page + 1)
        setIsLoadingMoreOrganizations(false)
      },
    }
  )

  const refetchWorkspaceOrganizations = useCallback(async () => {
    logger.dev('Starting organization refetch')
    const cachedOrgs = await getWorkspaceOrganizations(selectedWorkspace)
    if (Object.keys(cachedOrgs).length > 0) {
      setOrgsByDomain(cachedOrgs)
    }
    setWorkspaceOrganizationsPage(0)
    setHasMoreOrganizations(true)
    setIsLoadingMoreOrganizations(true)
    await refetchWorkspaceOrganizationsBasic()
  }, [refetchWorkspaceOrganizationsBasic, selectedWorkspace])

  const { data: pipelinesData, refetch: refetchPipelinesQuery } = useQuery(
    GET_PIPELINES_FOR_DAYCONTEXT,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
      onCompleted: (dayContextPipelinesData) => {
        logger.dev(dayContextPipelinesData)
      },
    }
  )

  const pipelines = useMemo(() => {
    return pipelinesData?.workspacePipelines || []
  }, [pipelinesData])

  const refetchPipelines = useCallback(() => {
    refetchPipelinesQuery()
  }, [refetchPipelinesQuery])

  const [
    selectedWorkAccountForSuggestedExclusions,
    setSelectedWorkAccountForSuggestedExclusions,
  ] = useState<{ uuid: string } | undefined>(undefined)
  const { data: suggestedExclusionsData, refetch: refetchSuggestedExclusions } =
    useQuery(GET_SUGGESTED_EXCLUSIONS_CONTEXT, {
      variables: {
        workspaceId: selectedWorkspace,
        workAccountId: selectedWorkAccountForSuggestedExclusions?.uuid,
      },
      skip: !selectedWorkspace || !selectedWorkAccountForSuggestedExclusions,
    })

  const suggestedExclusions = useMemo(() => {
    try {
      if (
        workAccountWorkspaceConnections?.length > 0 &&
        workAccounts?.length > 0 &&
        !selectedWorkAccountForSuggestedExclusions
      ) {
        for (const connection of workAccountWorkspaceConnections) {
          if (
            (connection?.connectedWorkspaces ?? []).some(
              (workspace) => workspace?.workspaceId === selectedWorkspace
            )
          ) {
            setSelectedWorkAccountForSuggestedExclusions(
              workAccounts.find(
                (workAccount) => workAccount.uuid === connection.workAccountUuid
              )
            )
            break
          }
        }
      }
    } catch (error) {
      logger.warn('Error fetching suggested exclusions')
    }

    return suggestedExclusionsData?.getSuggestedExclusions || []
  }, [
    suggestedExclusionsData,
    workAccountWorkspaceConnections,
    workAccounts,
    selectedWorkspace,
    selectedWorkAccountForSuggestedExclusions,
  ])

  const workspaceOrganizations = useMemo(() => {
    const orgs = Object.values(orgsByDomain) as Organization[]
    return orgs
  }, [orgsByDomain])

  const {
    data: workAccountWorkspaceConnectionsData,
    refetch: refetchWorkAccountWorkspaceConnections,
    loading: workAccountWorkspaceConnectionsLoading,
  } = useQuery<GlobalWorkAccountWorkspaceConnectionsQuery>(
    WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY,
    {
      onCompleted: (data) => {
        setWorkAccountWorkspaceConnections(data.workAccountWorkspaceConnections)
      },
    }
  )

  const internalDomains = useMemo(() => {
    if (
      !workspaces ||
      !workAccounts ||
      workspaces.length === 0 ||
      workAccounts.length === 0 ||
      !Array.isArray(workspaces) ||
      !Array.isArray(workAccounts)
    )
      return []
    const internal = new Set(
      workspaces
        ?.map((workspace) => {
          if (!workspace?.domains || !Array.isArray(workspace.domains))
            return []
          return workspace.domains.map((domain) => domain.domain)
        })
        ?.flat()
    )
    for (const domain of workAccounts.map((account) =>
      extractEmailDomain(account.email)
    )) {
      internal.add(domain)
    }
    const uniqueInternalDomains = Array.from(internal).filter(Boolean)
    return uniqueInternalDomains
  }, [workspaces, workAccounts])

  useEffect(() => {
    if (
      userCoreContact &&
      workspacesData &&
      workAccountsData &&
      selectedWorkspace &&
      workAccountWorkspaceConnectionsData &&
      !(
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading
      )
    ) {
      const skipOnboarding = localStorage.getItem('skipOnboarding')
      if (skipOnboarding) {
        logger.dev('Skipping onboarding')
        return
      }
      const targetWorkspaces = workspacesData.workspaces.map((workspace) => {
        const { isComplete } = onboardingStatus({
          coreContact: userCoreContact,
          workAccounts: workAccountsData.workAccounts,
          workspace,
          workAccountWorkspaceConnections:
            workAccountWorkspaceConnectionsData.workAccountWorkspaceConnections,
        })
        return isComplete
      })

      const needsOnboarding = !targetWorkspaces.some((isComplete) => isComplete)

      const excludedRoutesFromForcedOnboarding = [
        'revokeAlert',
        'data',
        'workspaceCreate',
      ] // Add more if necessary

      if (
        needsOnboarding &&
        !excludedRoutesFromForcedOnboarding.includes(route)
      )
        navigate(routes.welcome())
    }
  }, [
    userCoreContact,
    workAccountsData,
    workspacesData,
    selectedWorkspace,
    workAccountWorkspaceConnectionsData,
    route,
    workAccountWorkspaceConnectionsLoading,
    userCoreContactLoading,
    workAccountsLoading,
    workspacesLoading,
  ])

  const loading = useMemo(() => {
    return (
      workAccountWorkspaceConnectionsLoading ||
      userCoreContactLoading ||
      workAccountsLoading ||
      workspacesLoading
    )
  }, [
    workAccountWorkspaceConnectionsLoading,
    userCoreContactLoading,
    workAccountsLoading,
    workspacesLoading,
  ])

  const updateSelectedWorkspace = useCallback((workspaceId: string) => {
    setSelectedWorkspace(workspaceId)
    localStorage.setItem(selectedWorkspaceKey, workspaceId)
  }, [])

  useEffect(() => {
    logger.dev('workspacePeople changed')
  }, [workspacePeople])

  const memoizedSetSidebarObject = useCallback((object) => {
    setSidebarObject(object)
  }, [])

  const value = useMemo(() => {
    return {
      userCoreContact,
      refetchUserCoreContact,
      workAccounts,
      setWorkAccounts,
      refetchWorkAccounts,
      workspaces,
      refetchWorkspaces,
      workAccountWorkspaceConnections,
      refetchWorkAccountWorkspaceConnections,
      sidebarObject,
      setSidebarObject: memoizedSetSidebarObject,
      selectedWorkspace,
      setSelectedWorkspace: updateSelectedWorkspace,
      refetchPeople,
      internalDomains,
      suggestedExclusions,
      refetchSuggestedExclusions,
      loading,
      selectedWorkspaceLoading,
      workspacePeople,
      workspaceOrganizations,
      refetchWorkspaceOrganizations,
      orgsByDomain,
      peopleByEmail,
      pipelines,
      refetchPipelines,
      meetingRecordings,
    }
  }, [
    userCoreContact,
    workAccounts,
    workspaces,
    workAccountWorkspaceConnections,
    sidebarObject,
    memoizedSetSidebarObject,
    selectedWorkspace,
    workspacePeople,
    refetchPeople,
    internalDomains,
    selectedWorkspaceLoading,
    suggestedExclusions,
    refetchSuggestedExclusions,
    refetchWorkAccountWorkspaceConnections,
    refetchUserCoreContact,
    refetchWorkspaceOrganizations,
    refetchWorkspaces,
    refetchWorkAccounts,
    refetchPipelines,
    peopleByEmail,
    pipelines,
    orgsByDomain,
    workspaceOrganizations,
    loading,
    updateSelectedWorkspace,
    meetingRecordings,
  ])

  return <DayContext.Provider value={value}>{children}</DayContext.Provider>
}
