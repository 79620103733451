export const actionQueries = [
  'GetActionsForContactSidebar',
  'GetActionsForInbox',
  'GetActionsForMeetingRecording',
  'GetActionsForOpportunitySidebar',
  'GetActionsForOrganizationSidebar',
  'GetActionsForProduct',
  'GetActionsLibrary',
  'GetActionsToday',
]

export const GET_ACTION_SOURCE_INFO = gql`
  query GetActionSourceInfo($workspaceId: String!, $actionId: String!) {
    actionSourceInfo(workspaceId: $workspaceId, actionId: $actionId)
  }
`
