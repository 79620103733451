import { useEffect, useMemo, useState } from 'react'

import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import {
  RiCalendarEventLine,
  RiDraggable,
  RiFilmLine,
  RiTimeLine,
} from '@remixicon/react'
import { IconPlayerPlayFilled } from '@tabler/icons-react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import dayjs from 'dayjs'

import { useQuery } from '@redwoodjs/web'

import AvatarRow from 'src/components/AvatarRow/AvatarRow'
import MeetingRecordingDialog from 'src/components/MeetingRecordingDialog/MeetingRecordingDialog'
import Row from 'src/components/Row/Row'
import SidebarButton from 'src/components/Sidebar/SidebarButton/SidebarButton'
import { logger } from 'src/lib/logger'

import {
  nodeDescriptionSx,
  nodeDragHandleSx,
  nodeTextDetailsContainerSx,
  nodeViewContainerSx,
} from '../styles'

const GET_MEETING_RECORDING_FOR_TIPTAP = gql`
  query GetMeetingRecordingForTiptap($id: String!) {
    meetingRecording: meetingRecordingForPageBlock(id: $id) {
      id
      statusHistory {
        level
        status
        reason
        message
        createdAt
      }
      title
      startedAt
      endedAt
      summary {
        status
        output
      }
      video {
        private {
          thumbnail {
            status
            url
          }
          download {
            status
            urls {
              default
              quality
              url
            }
          }
          playbackId
          tokens {
            video
            thumbnail
            storyboard
          }
        }
      }
      clips {
        id
        title
        description
        video {
          private {
            thumbnail {
              status
              url
            }
            download {
              status
              urls {
                default
                quality
                url
              }
            }
            playbackId
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
      }
      participants {
        email
        contact {
          objectType
          objectId
          properties
        }
      }
    }
  }
`

function calculateMeetingLength(meetingRecording) {
  if (!meetingRecording) return null
  const { startedAt, endedAt } = meetingRecording
  const duration =
    !!startedAt && !!endedAt
      ? (new Date(endedAt).getTime() - new Date(startedAt).getTime()) / 1000
      : null
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60)

  let result = ''
  if (hours > 0) result += `${hours}h `
  if (minutes > 0) result += `${minutes}m `
  if (hours === 0 && minutes === 0) result += `${seconds}s`

  return result
}

const TiptapNodeMeetingRecordingRenderer = (props) => {
  const [open, setOpen] = useState(false)
  const [recording, setRecording] = useState(null)
  const meetingRecordingId = useMemo(
    () => props.node.attrs.id,
    [props.node.attrs.id]
  )

  const { loading } = useQuery(GET_MEETING_RECORDING_FOR_TIPTAP, {
    variables: {
      id: meetingRecordingId,
    },
    skip: recording,
    onCompleted: (result) => {
      setRecording(result.meetingRecording)
    },
  })

  useEffect(() => {
    logger.dev({ open })
  }, [open])

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-meetingrecording"
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
    >
      <Row
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
        sx={{
          ...nodeViewContainerSx,
          height: '104px',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        <Box
          className="drag-handle"
          sx={nodeDragHandleSx}
        >
          <RiDraggable />
        </Box>
        {loading ? (
          <Box sx={{ pl: 2, width: '100%' }}>
            <CircularProgress size={56} />
          </Box>
        ) : (
          <Row
            sx={{
              width: 'calc(100% - 172px)',
              pl: 2,
            }}
          >
            <AvatarRow
              emails={recording?.participants.map(
                (participant) => participant.email
              )}
              size={56}
              borderRadius={100}
              max={5}
              margin={'-18px'}
            />
            <Box sx={{ ...nodeTextDetailsContainerSx, height: 'auto' }}>
              <Box
                component="span"
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  letterSpacing: '-0.22px',
                  lineHeight: '140%',
                }}
              >
                {recording?.title}
              </Box>
              <Typography sx={nodeDescriptionSx}>
                {recording?.summary?.output.Summary_Short}
              </Typography>
              <Row sx={{ mt: '4px' }}>
                <RiTimeLine size={14} />
                <Typography
                  sx={{ ml: '4px', fontSize: '0.7rem !important', mr: 2 }}
                >
                  {calculateMeetingLength(recording)}
                </Typography>
                <RiCalendarEventLine size={14} />
                <Typography
                  sx={{ ml: '4px', fontSize: '0.7rem !important', mr: 2 }}
                >
                  {dayjs(recording?.startedAt).format('MMM D, YYYY')}
                </Typography>
                {recording?.clips?.length > 0 && (
                  <>
                    <RiFilmLine size={14} />
                    <AvatarGroup
                      max={8}
                      componentsProps={{
                        additionalAvatar: { sx: { display: 'none' } },
                      }}
                      variant="rounded"
                    >
                      {recording?.clips.map((clip) => (
                        <Avatar
                          key={`clipAvatar-${clip.id}`}
                          alt={clip.title}
                          src={clip.video.private.thumbnail.url}
                          sx={{ width: 14, height: 14 }}
                        />
                      ))}
                    </AvatarGroup>
                    <Typography
                      sx={{ ml: '4px', fontSize: '0.7rem !important' }}
                    >
                      {`${recording?.clips?.length} clips`}
                    </Typography>
                  </>
                )}
              </Row>
            </Box>
          </Row>
        )}

        <Box sx={{ width: '128px', ml: 2 }}>
          <SidebarButton
            icon={<IconPlayerPlayFilled />}
            label="Click to watch"
            onClick={() => {
              setOpen(true)
            }}
          />
        </Box>
      </Row>
      <MeetingRecordingDialog
        open={open}
        id={meetingRecordingId}
        //prefetched={recording}
        onClose={() => setOpen(false)}
      />
    </NodeViewWrapper>
  )
}

const TiptapNodeMeetingRecording = Node.create({
  name: 'meetingrecording',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-meetingrecording',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const meetingRecordingAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'meetingrecording',
    })
    return ['day-ai-meetingRecording', meetingRecordingAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeMeetingRecordingRenderer)
  },
})

export default TiptapNodeMeetingRecording
