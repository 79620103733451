import {
  IconBolt,
  IconBriefcase,
  IconBuildingSkyscraper,
  IconColumns,
  IconColumns2,
  IconTrophy,
  IconUser,
  IconVideo,
  IconFiles,
  IconBubbleText,
  IconCalendar,
  IconCode,
} from '@tabler/icons-react'
import { merge } from 'lodash'

import { logger } from './logger'
import { NativeSuggestedPipelineTypes } from './relationshipSummary'

export const GlobalWorkspace = 'global'

export const NativeObjectTypes = {
  Person: 'native_contact',
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
  Workspace: 'native_workspace',
  View: 'native_view',
  Event: 'native_calendarevent',
  GmailThread: 'native_gmailthread',
  UserContext: 'native_usercontext',
  PropertyDefinition: 'property_definition',
  SlackChannel: 'native_slackchannel',
} as const

export type NativeObjectType =
  (typeof NativeObjectTypes)[keyof typeof NativeObjectTypes]

export const PropertyTypes = {
  Address: 'address',
  Boolean: 'boolean',
  Calculated: 'calculated',
  Combobox: 'combobox',
  Currency: 'currency',
  Email: 'email',
  MultiPicklist: 'multipicklist',
  Percent: 'percent',
  Picklist: 'picklist',
  Phone: 'phone',
  ObjectReference: 'reference',
  TextArea: 'textarea',
  Url: 'url',
  DateTime: 'datetime',
  Integer: 'int',
  Float: 'float',
  DeleteMarker: 'deletemarker',
} as const

export const ObjectAccessLevels = {
  Owner: 'owner',
  FullAccess: 'fullAccess',
  Editor: 'editor',
  Collaborator: 'collaborator',
  Viewer: 'viewer',
} as const

type ObjectPath = {
  key: string
  label: string
}

type ObjectTypeMetadataType = {
  icon: React.ComponentType<any>
  label: string
  pluralLabel: string
  slug: string
  paths?: {
    [key: string]: ObjectPath
  }
}

export const ObjectTypeMetadata: Record<
  NativeObjectType,
  ObjectTypeMetadataType
> = {
  [NativeObjectTypes.Organization]: {
    icon: IconBuildingSkyscraper,
    label: 'Organization',
    pluralLabel: 'Organizations',
    slug: 'organizations',
  },
  [NativeObjectTypes.Person]: {
    icon: IconUser,
    label: 'Person',
    pluralLabel: 'People',
    slug: 'people',
  },
  [NativeObjectTypes.Opportunity]: {
    icon: IconTrophy,
    label: 'Opportunity',
    pluralLabel: 'Opportunities',
    slug: 'opportunities',
    paths: {
      CREATION: {
        key: 'opportunities/create',
        label: 'Creation & Assignment',
      },
    },
  },
  [NativeObjectTypes.Pipeline]: {
    icon: IconColumns2,
    label: 'Pipeline',
    pluralLabel: 'Pipelines',
    slug: 'pipelines',
  },
  [NativeObjectTypes.Stage]: {
    icon: IconColumns,
    label: 'Stage',
    pluralLabel: 'Stages',
    slug: 'stages',
  },
  [NativeObjectTypes.Workspace]: {
    icon: IconBriefcase,
    label: 'Workspace',
    pluralLabel: 'Workspaces',
    slug: 'workspace',
  },
  [NativeObjectTypes.Action]: {
    icon: IconBolt,
    label: 'Action',
    pluralLabel: 'Actions',
    slug: 'actions',
    paths: {
      ROOT: {
        key: 'root',
        label: 'Creation & Assignment',
      },
    },
  },
  [NativeObjectTypes.MeetingRecording]: {
    icon: IconVideo,
    label: 'Meeting Recording',
    pluralLabel: 'Meeting Recordings',
    slug: 'recordings',
  },
  [NativeObjectTypes.Page]: {
    icon: IconFiles,
    label: 'Page',
    pluralLabel: 'Pages',
    slug: 'pages',
  },
  [NativeObjectTypes.UserContext]: {
    icon: IconBubbleText,
    label: 'Context',
    pluralLabel: 'Context Entries',
    slug: 'context',
  },
  [NativeObjectTypes.MeetingRecordingClip]: {
    icon: IconVideo,
    label: 'Meeting Recording Clip',
    pluralLabel: 'Meeting Recording Clips',
    slug: 'recording-clips',
  },
  [NativeObjectTypes.View]: {
    icon: IconColumns,
    label: 'View',
    pluralLabel: 'Views',
    slug: 'views',
  },
  [NativeObjectTypes.Event]: {
    icon: IconCalendar,
    label: 'Calendar Event',
    pluralLabel: 'Calendar Events',
    slug: 'events',
  },
  [NativeObjectTypes.GmailThread]: {
    icon: IconBubbleText,
    label: 'Gmail Thread',
    pluralLabel: 'Gmail Threads',
    slug: 'gmail',
  },
  [NativeObjectTypes.PropertyDefinition]: {
    icon: IconCode,
    label: 'Property Definition',
    pluralLabel: 'Property Definitions',
    slug: 'properties',
  },
}

export const ActionSourceLabels = {
  AI_GENERATED: 'AI',
  MEETING_RECORDING: 'Meeting',
  GMAIL_THREAD: 'Gmail',
  SLACK_CHANNEL: 'Slack',
  NOTE: 'Note',
  OPPORTUNITY: 'Opp',
  MANUAL: 'Manual',
  ZAPIER: 'Zapier',
  API: 'API',
}

export const ActionChannelLabels = {
  EMAIL: 'Email',
  GMAIL: 'Gmail',
  SLACK: 'Slack',
  MEETING: 'Meeting',
  NOTE: 'Note',
  PAGE: 'Page',
}

export type RelationshipDirection = 'TO' | 'FROM' | 'REFERRER'

export const RelationshipDirectionalities: Record<
  string,
  RelationshipDirection
> = {
  To: 'TO',
  From: 'FROM',
  Referrer: 'REFERRER',
} as const

export type RelationshipStage = 'PROSPECTIVE' | 'EXISTING' | 'ABANDONED'

export const RelationshipStages: Record<string, RelationshipStage> = {
  Prospective: 'PROSPECTIVE',
  Existing: 'EXISTING',
  Abandoned: 'ABANDONED',
} as const

export type RelationshipType =
  | 'CUSTOMER'
  | 'INVESTMENT'
  | 'PARTNER'
  | 'MENTOR'
  | 'INTERNAL'
  | 'PERSONAL'
  | 'OTHER'

export const RelationshipTypes: Record<string, RelationshipType> = {
  Customer: 'CUSTOMER',
  Investment: 'INVESTMENT',
  Partner: 'PARTNER',
  Mentor: 'MENTOR',
  Internal: 'INTERNAL',
  Personal: 'PERSONAL',
  Other: 'OTHER',
} as const

export const RelationshipTypeLabels: Record<RelationshipType, string> = {
  CUSTOMER: 'Customer',
  INVESTMENT: 'Investment',
  PARTNER: 'Partner',
  MENTOR: 'Mentor',
  INTERNAL: 'Internal',
  PERSONAL: 'Personal',
  OTHER: 'Other',
}

export const RelationshipStageDisplay: Record<
  RelationshipStage,
  { primary: string; secondary: string }
> = {
  PROSPECTIVE: {
    primary: 'Prospective',
    secondary: 'eg. Sales prospect, potential investor or referrer etc',
  },
  EXISTING: {
    primary: 'Existing',
    secondary: 'eg. Current customer, partner, referrer, investor, etc',
  },
  ABANDONED: {
    primary: 'Former',
    secondary: 'eg. Cancelled customer, former partner etc',
  },
}

/*
 workspaceId: xyz-123


pixelmachinery.com
["CUSTOMER : TO : PROSPECTIVE" = true, "CUSTOMER : FROM : EXISTING" = true]

sarah@pillar.vc
["MENTOR : MUTUAL : EXISTING" = true, "CUSTOMER : TO : PROSPECTIVE" = true, "INVESTMENT : FROM : EXISTING" = true,
 "INVESTMENT : FROM : PROSPECTIVE" = true, "INVESTMENT : REFERRER : PROSPECTIVE" = true,
 "CUSTOMER : REFERRER : EXISTING" = true]

 niriksha@hprime.co
 ["CUSTOMER : REFERRER : EXISTING" = true]

 brightmindsneuropsych.com
 ["CUSTOMER : TO : EXISTING" = true]

 */

export type RelationshipTypeMetadata = {
  label: string
  key: string
  pipelineType: keyof typeof NativeSuggestedPipelineTypes | null
}

export type RelationshipEdge = {
  key: string
  type: RelationshipType
  stage: RelationshipStage
  direction: RelationshipDirection
  label: string
  pipelineType: keyof typeof NativeSuggestedPipelineTypes | null
}

type RelationshipTypeStructure = Record<
  RelationshipType,
  Record<
    RelationshipDirection,
    Record<RelationshipStage, RelationshipTypeMetadata>
  >
>

export const NativeRelationshipTypes: RelationshipTypeStructure = {
  [RelationshipTypes.Customer as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Customer',
        key: 'PROSPECTIVE_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.NEW_CUSTOMER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Customer',
        key: 'EXISTING_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.EXISTING_CUSTOMER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Customer',
        key: 'CANCELLED_CUSTOMER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Vendor',
        key: 'PROSPECTIVE_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Vendor',
        key: 'EXISTING_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Vendor',
        key: 'CANCELLED_VENDOR',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Customer Referrer',
        key: 'PROSPECTIVE_CUSTOMER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Customer Referrer',
        key: 'EXISTING_CUSTOMER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Customer Referrer',
        key: 'FORMER_CUSTOMER_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Investment as RelationshipType]: {
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investor',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Investor',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Investor',
        key: 'FORMER_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
    },
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investment',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Portfolio Company',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Previous Portfolio Company',
        key: 'PREVIOUS_INVESTMENT',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investment Referrer',
        key: 'PROSPECTIVE_INVESTMENT_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Investment Referrer',
        key: 'EXISTING_INVESTMENT_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Investment Referrer',
        key: 'FORMER_INVESTMENT_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Partner as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner',
        key: 'FORMER_PARTNER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner',
        key: 'FORMER_PARTNER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner Referrer',
        key: 'PROSPECTIVE_PARTNER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner Referrer',
        key: 'EXISTING_PARTNER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner Referrer',
        key: 'FORMER_PARTNER_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Mentor as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentee',
        key: 'PROSPECTIVE_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentee',
        key: 'EXISTING_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentee',
        key: 'FORMER_MENTEE',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentor',
        key: 'PROSPECTIVE_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentor',
        key: 'EXISTING_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentor',
        key: 'FORMER_MENTOR',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentor Referrer',
        key: 'PROSPECTIVE_MENTOR_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentor Referrer',
        key: 'EXISTING_MENTOR_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentor Referrer',
        key: 'FORMER_MENTOR_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Internal as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Member',
        key: 'PROSPECTIVE_INTERNAL',
        pipelineType: NativeSuggestedPipelineTypes.RECRUITING.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Member',
        key: 'EXISTING_INTERNAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Member',
        key: 'FORMER_INTERNAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Member',
        key: 'PROSPECTIVE_INTERNAL',
        pipelineType: NativeSuggestedPipelineTypes.RECRUITING.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Member',
        key: 'EXISTING_INTERNAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Member',
        key: 'FORMER_INTERNAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Referrer',
        key: 'PROSPECTIVE_INTERNAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Referrer',
        key: 'EXISTING_INTERNAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Referrer',
        key: 'FORMER_INTERNAL_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Personal as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'PROSPECTIVE_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'EXISTING_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Contact',
        key: 'FORMER_PERSONAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'PROSPECTIVE_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'EXISTING_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Contact',
        key: 'FORMER_PERSONAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Personal Referrer',
        key: 'PROSPECTIVE_PERSONAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Personal Referrer',
        key: 'EXISTING_PERSONAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Referrer',
        key: 'FORMER_PERSONAL_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Other as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Other Contact',
        key: 'PROSPECTIVE_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Other Contact',
        key: 'EXISTING_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Other Contact',
        key: 'FORMER_OTHER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Other Contact',
        key: 'PROSPECTIVE_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Other Contact',
        key: 'EXISTING_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Other Contact',
        key: 'FORMER_OTHER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Contact Referrer',
        key: 'PROSPECTIVE_OTHER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Contact Referrer',
        key: 'EXISTING_OTHER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Contact Referrer',
        key: 'FORMER_OTHER_REFERRER',
        pipelineType: null,
      },
    },
  },
} as const as RelationshipTypeStructure

export const buildUserContextReferenceKey = (
  type: string,
  id: string,
  path?: string
) => `${type} : ${id} : ${path || 'root'}`

export const buildRelationship = ({
  type,
  direction,
  stage,
}: {
  type: RelationshipType
  direction: RelationshipDirection
  stage: RelationshipStage
}): RelationshipEdge => {
  const metadata = NativeRelationshipTypes?.[type]?.[direction]?.[stage]
  if (!metadata) {
    logger.warn('No metadata found')
    return null
  }
  return {
    key: buildRelationshipReference({
      type,
      direction,
      stage,
    }),
    label: metadata.label,
    pipelineType: metadata.pipelineType,
    type,
    direction,
    stage,
  }
}

export const buildRelationshipReference = ({
  type,
  direction,
  stage,
}: {
  type: RelationshipType
  direction: RelationshipDirection
  stage: RelationshipStage
}) => {
  return `relationship/${direction}/${type}/${stage}`
}

export const parseRelationshipReference = (reference: string) => {
  if (!reference || typeof reference !== 'string') {
    logger.warn('Invalid relationship reference - must be a non-empty string')
    return null
  }

  const parts = reference.split('/')
  if (parts.length !== 4) {
    logger.warn(
      'Invalid relationship reference format - must contain exactly 4 parts separated by "/" (direction/type/stage)'
    )
    return null
  }

  const [_, direction, type, stage] = parts

  if (!type || !direction || !stage) {
    logger.warn('Invalid relationship reference - all parts must be non-empty')
    return null
  }

  return { type, direction, stage }
}

const removeNulls = (obj: any): any => {
  if (!obj || typeof obj !== 'object') return obj

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      // Recursively clean nested objects
      if (value && typeof value === 'object') {
        const cleaned = removeNulls(value)
        if (Object.keys(cleaned).length > 0) {
          acc[key] = cleaned
        }
      }
      // Keep non-null values
      else if (value !== null) {
        acc[key] = value
      }
      return acc
    },
    Array.isArray(obj) ? [] : {}
  )
}

export const deepMerge = (existing: any, incoming: any): any => {
  // Remove null values from incoming object
  const sanitizedIncoming = removeNulls(incoming)

  const result = merge({}, existing, sanitizedIncoming)

  return result
}

export const getMetadataFromRelationshipReference = (key: string) => {
  const relationship = parseRelationshipReference(key)
  return NativeRelationshipTypes[relationship?.type]?.[
    relationship?.direction
  ]?.[relationship?.stage]
}

export const getRelationshipFromPipelineType = (pipelineType: string) => {
  if (!pipelineType) {
    logger.warn('No pipelineType provided')
    return null
  }

  for (const [type, directionMap] of Object.entries(NativeRelationshipTypes)) {
    for (const [direction, stageMap] of Object.entries(directionMap)) {
      for (const [stage, metadata] of Object.entries(stageMap)) {
        if (metadata.pipelineType === pipelineType) {
          return {
            type: type as RelationshipType,
            direction: direction as RelationshipDirection,
            stage: stage as RelationshipStage,
            label: metadata.label,
            pipelineType: metadata.pipelineType,
          }
        }
      }
    }
  }

  logger.warn(
    `No relationship configuration found for pipelineType: ${pipelineType}`
  )
  return null
}

export const getEndStateRelationshipByPipelineType = (pipelineType: string) => {
  const relationship = getRelationshipFromPipelineType(pipelineType)
  return {
    ...NativeRelationshipTypes[relationship?.type]?.[relationship?.direction]?.[
      RelationshipStages.Existing
    ],
    type: relationship?.type,
    direction: relationship?.direction,
    stage: RelationshipStages.Existing,
  }
}

export const ActiveActionStatuses = ['UNREAD', 'READ']
