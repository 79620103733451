export const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      title
      contentJson
      contentHtml
      ownerEmail
      createdAt
      updatedAt
      shortLinkHash
      madeExternalAt
      workspaceId
      emoji
      headerImage
      publishedForUserAt
      parentObject {
        objectId
        objectType
      }
      crmObjects {
        objectId
        objectType
        properties
        workspaceId
      }
      aiInitialPrompt
      aiPopulationCompletedAt
    }
  }
`
