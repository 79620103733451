import { useContext } from 'react'

import { Event, VideoLibrary } from '@mui/icons-material'
import { Alert, Box, Button, Typography } from '@mui/material'
import { RiCalendarEventFill } from '@remixicon/react'
import dayjs from 'dayjs'

import { navigate } from '@redwoodjs/router'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { recordingLink } from 'src/lib/meetingRecordingFormatting'

import { sidebarHeadingTextStyle } from '../styles'

const findMeetingLink = (event) => {
  // Check for Google Meet link directly
  if (event.hangoutLink) return event.hangoutLink

  // Check Conference Data
  if (event.conferenceData && event.conferenceData.entryPoints) {
    for (const entry of event.conferenceData.entryPoints) {
      if (entry.uri) return entry.uri
    }
  }

  // Regular expression to identify URLs
  const urlRegex = /(https?:\/\/[^\s"]+)/g

  // Check Location & Description
  const fieldsToCheck = [event.location, event.description]
  for (const field of fieldsToCheck) {
    if (field) {
      const matches = field.match(urlRegex)
      if (matches && matches?.[0]) {
        // Trim quotation marks from the beginning and end of the URL
        const cleanedUrl = matches[0]?.replace(/^"|"$/g, '')
        return cleanedUrl // Return the cleaned URL
      }
    }
  }

  return ''
}

const SidebarMeetingHistory = ({
  email,
  meetings,
  loading,
}: {
  email: string
  meetings: any
  loading: boolean
}) => {
  const { selectedWorkspace, workAccountWorkspaceConnections } =
    useContext(DayContext)

  return (
    <Box sx={{}}>
      {meetings?.upcomingEvents.length > 0 &&
        meetings?.upcomingEvents?.map(({ GoogleEvent: event }) => (
          <Box
            key={event.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
              textDecoration: 'none',
            }}
          >
            <Typography
              sx={{
                ...sidebarHeadingTextStyle,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <Event
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: '1.0rem',
                  mr: '4px',
                  ml: 1,
                }}
              />

              {event.title}
            </Typography>
            {findMeetingLink(event) && (
              <Button
                size="small"
                onClick={() => navigate(findMeetingLink(event))}
              >
                Join
              </Button>
            )}
          </Box>
        ))}
      {!(meetings?.recentEvents.length > 0) ? (
        <Typography
          sx={{
            ...sidebarHeadingTextStyle,
            color: (theme) => theme.palette.text.secondary,
            p: 0,
            m: 0,
          }}
        >
          {loading ? (
            <Alert severity="info">Searching recordings...</Alert>
          ) : (
            `No meeting history found for ${email}`
          )}
        </Typography>
      ) : (
        meetings?.recentEvents.map((event) => (
          <Row
            key={event.id}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'top',
              my: 2,
              textDecoration: 'none',
              cursor: event?.meetingRecordings[0]?.id ? 'pointer' : 'inherit',
            }}
            onClick={() => {
              if (event?.meetingRecordings[0]?.id) {
                navigate(
                  recordingLink({
                    workspaceId: workAccountWorkspaceConnections?.some(
                      ({ connectedWorkspaces }) =>
                        connectedWorkspaces.length > 0
                    )
                      ? selectedWorkspace
                      : null,
                    meetingRecording: event?.meetingRecordings[0],
                  })
                )
              }
            }}
          >
            <Row
              sx={{
                alignItems: 'top',
                '& .remixicon': {
                  color: (theme) => theme.palette.text.secondary,
                },
              }}
            >
              {event?.meetingRecordings[0]?.id ? (
                <>
                  {event?.meetingRecordings[0]?.video?.private?.thumbnail
                    ?.url ? (
                    <>
                      <Box
                        component="img"
                        src={
                          event?.meetingRecordings[0]?.video?.private?.thumbnail
                            ?.url
                        }
                        sx={{
                          height: '48px',
                          width: '48px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                          mr: 2,
                        }}
                      />
                    </>
                  ) : (
                    <VideoLibrary
                      sx={{
                        ...sidebarHeadingTextStyle,
                        mr: '4px',
                        color: (theme) => theme.palette.text.secondary,
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  )}
                </>
              ) : (
                <RiCalendarEventFill
                  style={{
                    marginRight: '4px',
                  }}
                  size={18}
                />
              )}
              <Box
                sx={{
                  width: '488px',
                }}
              >
                <Typography
                  sx={{
                    ...sidebarHeadingTextStyle,
                    fontWeight: event?.meetingRecordings[0]?.id ? 600 : 500,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {
                    event?.meetingRecordings[0]?.summary?.output
                      ?.Suggested_Title
                  }
                </Typography>
                <Typography
                  sx={{
                    ...sidebarHeadingTextStyle,
                    fontWeight: event?.meetingRecordings[0]?.id ? 500 : 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: (theme) => theme.palette.text.secondary,
                    letterSpacing: '-0.4px',
                  }}
                >
                  {event.GoogleEvent.title}
                </Typography>
              </Box>
            </Row>
            {event?.meetingRecordings[0]?.id &&
              event?.meetingRecordings[0]?.clips?.length > 0 && (
                <Typography
                  sx={{
                    ...sidebarHeadingTextStyle,
                    minWidth: '80px',
                    textAlign: 'center',
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {event?.meetingRecordings[0]?.clips?.length} clips
                </Typography>
              )}
            <Typography
              sx={{
                ...sidebarHeadingTextStyle,
                minWidth: '80px',
                textAlign: 'right',
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {event.GoogleEvent.start_time
                ? dayjs(event.GoogleEvent.start_time).format('MM/DD/YYYY')
                : ''}
            </Typography>
          </Row>
        ))
      )}
    </Box>
  )
}

export default SidebarMeetingHistory
