import { Chip, Box, IconButton, Menu, MenuItem } from '@mui/material'
import { IconCircleArrowRightFilled } from '@tabler/icons-react'
import type { Action } from 'types/graphql'

import { ActionChannelLabels } from 'src/lib/objects'

import { ActionChannelType } from '../Actions/ActionChannelChip/ActionChannelChip'
import ActionChannelChip from '../Actions/ActionChannelChip/ActionChannelChip'

const chipStyles = {
  padding: '1px 4px',
  cursor: 'pointer',
} as const

const CreateActionDraftButton = ({
  action,
  onClick,
  disabled,
  selectedChannelType,
  setSelectedChannelType,
}: {
  action: Action
  onClick: () => void
  disabled: boolean
  selectedChannelType: ActionChannelType
  setSelectedChannelType: (channelType: ActionChannelType) => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChannelSelect = (channelType: ActionChannelType) => {
    setSelectedChannelType(channelType)
    handleClose()
  }

  const channelType = selectedChannelType || action.channel?.type

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          variant="outlined"
          size="small"
          label={`${ActionChannelLabels[channelType]}`}
          icon={
            <ActionChannelChip
              action={{
                ...action,
                channel: {
                  ...action.channel,
                  type: channelType,
                },
              }}
              variant="icon"
            />
          }
          disabled={disabled}
          sx={chipStyles}
          onClick={handleChipClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            disabled
            sx={{ opacity: 0.7, fontSize: '0.75rem' }}
          >
            Intended destination for draft
          </MenuItem>
          {[
            ActionChannelType.GMAIL,
            ActionChannelType.SLACK,
            ActionChannelType.PAGE,
          ].map((type) => (
            <MenuItem
              key={type}
              onClick={() => handleChannelSelect(type)}
              selected={type === channelType}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ActionChannelChip
                  action={{
                    ...action,
                    channel: {
                      ...action.channel,
                      type,
                    },
                  }}
                  variant="icon"
                />
                {ActionChannelLabels[type]}
              </Box>
            </MenuItem>
          ))}
        </Menu>
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={onClick}
        >
          <IconCircleArrowRightFilled size={20} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CreateActionDraftButton
