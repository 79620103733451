export enum StageTypes {
  AWARENESS = 'AWARENESS',
  CONNECTION = 'CONNECTION',
  NEEDS_IDENTIFICATION = 'NEEDS_IDENTIFICATION',
  PROPOSAL = 'PROPOSAL',
  EVALUATION = 'EVALUATION',
  CONSIDERATION_NEGOTIATION = 'CONSIDERATION_NEGOTIATION',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_LOST = 'CLOSED_LOST',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
}

export const StageTitles = {
  [StageTypes.AWARENESS]: 'Awareness',
  [StageTypes.CONNECTION]: 'Connection',
  [StageTypes.NEEDS_IDENTIFICATION]: 'Needs Identification',
  [StageTypes.EVALUATION]: 'Evaluation',
  [StageTypes.PROPOSAL]: 'Proposal',
  [StageTypes.CONSIDERATION_NEGOTIATION]: 'Consideration & Negotiation',
  [StageTypes.CLOSED_WON]: 'Closed Won',
  [StageTypes.CLOSED_LOST]: 'Closed Lost',
  [StageTypes.CUSTOMER_SUCCESS]: 'Customer Success',
}

export const StageDescriptionsForPrompt = {
  [StageTypes.AWARENESS]:
    'Awareness: Opportunities in this stage are typically known to one party at least, but no connection has been made. Prospecting.',
  [StageTypes.CONNECTION]:
    'Connection: Both sides of these deals are known to each other and in contact, but little to no discovery has been made.',
  [StageTypes.NEEDS_IDENTIFICATION]:
    'Needs Identification: Still-early conversations are typically focused on understanding the prospect and their needs, and how the parties might work together. Discovery.',
  [StageTypes.EVALUATION]:
    'Evaluation: The prospect is evaluating the opportunity and the parties involved. Discovery.',
  [StageTypes.PROPOSAL]:
    'Proposal: Discovery is generally complete, and the parties are now focused on the proposal and identifying the details of the potential deal.',
  [StageTypes.CONSIDERATION_NEGOTIATION]:
    'Consideration & Negotiation: With the details of the deal identified, the parties are now focused on negotiating the terms of the deal.',
  [StageTypes.CLOSED_WON]:
    'Closed Won: The prospect has accepted the opportunity and the parties are now focused on beginning to work together.',
  [StageTypes.CLOSED_LOST]:
    'Closed Lost: The prospect has rejected the opportunity and the parties are no longer pursuing the opportunity.',
  [StageTypes.CUSTOMER_SUCCESS]:
    'Customer Success: The prospect has accepted the opportunity and the parties are now focused on beginning to work together.',
}

export const StagesForSetup = [
  StageTypes.AWARENESS,
  StageTypes.CONNECTION,
  StageTypes.NEEDS_IDENTIFICATION,
  StageTypes.EVALUATION,
  StageTypes.CONSIDERATION_NEGOTIATION,
  StageTypes.CLOSED_WON,
  StageTypes.CLOSED_LOST,
]

export const StageDefaults = {
  [StageTypes.AWARENESS]: {
    title: StageTitles[StageTypes.AWARENESS],
    likelihoodToClose: 0.1,
    position: 1,
  },
  [StageTypes.CONNECTION]: {
    title: StageTitles[StageTypes.CONNECTION],
    likelihoodToClose: 0.2,
    position: 2,
  },
  [StageTypes.NEEDS_IDENTIFICATION]: {
    title: StageTitles[StageTypes.NEEDS_IDENTIFICATION],
    likelihoodToClose: 0.3,
    position: 3,
  },
  [StageTypes.PROPOSAL]: {
    title: StageTitles[StageTypes.PROPOSAL],
    likelihoodToClose: 0.5,
    position: 4,
  },
  [StageTypes.EVALUATION]: {
    title: StageTitles[StageTypes.EVALUATION],
    likelihoodToClose: 0.5,
    position: 4,
  },
  [StageTypes.CONSIDERATION_NEGOTIATION]: {
    title: StageTitles[StageTypes.CONSIDERATION_NEGOTIATION],
    likelihoodToClose: 0.75,
    position: 5,
  },
  [StageTypes.CLOSED_WON]: {
    title: StageTitles[StageTypes.CLOSED_WON],
    likelihoodToClose: 1,
    position: 6,
  },
  [StageTypes.CLOSED_LOST]: {
    title: StageTitles[StageTypes.CLOSED_LOST],
    likelihoodToClose: 0,
    position: 7,
  },
  [StageTypes.CUSTOMER_SUCCESS]: {
    title: StageTitles[StageTypes.CUSTOMER_SUCCESS],
    likelihoodToClose: 1,
    position: 8,
  },
}
