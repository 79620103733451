import { Chip, Tooltip, Box } from '@mui/material'
import { IconLoader, IconStack2 } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { CRMObject } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { actionButtonStyle } from 'src/components/Sidebar/styles'

const CREATE_THREAD_FROM_BUTTON = gql`
  mutation CreateThreadFromButton(
    $workspaceId: String!
    $sourceObject: CRMObjectInput!
    $otherObjects: [CRMObjectInput!]!
    $title: String!
  ) {
    createThread(
      workspaceId: $workspaceId
      sourceObject: $sourceObject
      contextObjects: $otherObjects
      title: $title
    ) {
      id
    }
  }
`

const ThreadCreateButton = ({
  workspaceId,
  sourceObject,
  otherObjects,
  title = null,
  onThreadCreated,
}: {
  workspaceId: string
  sourceObject: CRMObject
  otherObjects: CRMObject[]
  title: string
  onThreadCreated: () => void
}) => {
  const [createThread, { loading }] = useMutation(CREATE_THREAD_FROM_BUTTON)

  const handleCreateThread = async (e) => {
    e.stopPropagation()
    const input = {
      workspaceId,
      sourceObject: {
        objectId: sourceObject.objectId,
        objectType: sourceObject.objectType,
      },
      otherObjects: otherObjects.map((object) => ({
        objectId: object.objectId,
        objectType: object.objectType,
      })),
      title,
    }

    await createThread({
      variables: input,
      onError: () => {
        toast.error('Error creating thread')
      },
      onCompleted: (data) => {
        navigate(routes.thread({ id: data.createThread.id }))
        onThreadCreated()
      },
    })
  }

  return (
    <Tooltip
      title={'Create a new thread'}
      arrow={true}
      placement="top"
    >
      <Chip
        label={loading ? 'Creating...' : 'Thread'}
        icon={
          loading ? (
            <Box
              sx={{
                '@keyframes spin': {
                  from: {
                    transform: 'rotate(0deg)',
                  },
                  to: {
                    transform: 'rotate(360deg)',
                  },
                },
                animation: 'spin 1s linear infinite',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconLoader size={12} />
            </Box>
          ) : (
            <IconStack2 size={12} />
          )
        }
        clickable={true}
        onClick={handleCreateThread}
        variant="outlined"
        size="small"
        sx={actionButtonStyle}
      />
    </Tooltip>
  )
}

export default ThreadCreateButton
