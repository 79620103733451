import { useContext, useState, useEffect, useMemo } from 'react'

import { Avatar, Box, Chip, lighten } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import { Node, mergeAttributes } from '@tiptap/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import type { SuggestionOptions } from '@tiptap/suggestion'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import ObjectPreviewMenu from 'src/components/ObjectPreviewMenu/ObjectPreviewMenu'
import { useHoverBoundary } from 'src/hooks/useHoverBoundary/useHoverBoundary'
import { DayContext } from 'src/lib/dayContext'
import { getObjectMetadata } from 'src/lib/indexedDb'
import { getBestLabel } from 'src/lib/objectLabels'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

interface ObjectChipNodeAttrs {
  objectType: string | null
  objectId: string | null
  displayName: string | null
}

type ObjectChipOptions<
  SuggestionItem = any,
  Attrs extends Record<string, any> = ObjectChipNodeAttrs,
> = {
  HTMLAttributes: Record<string, any>
  suggestion: Omit<SuggestionOptions, 'editor'>
  renderHTML: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => HTMLElement
  renderText: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => string
}

const GET_CHIP_METADATA = gql`
  query ChipMetadata(
    $objectId: String!
    $objectType: String!
    $workspaceId: String!
  ) {
    chipMetadata(
      objectId: $objectId
      objectType: $objectType
      workspaceId: $workspaceId
    ) {
      label
      icon
      domain
      avatarUrl
    }
  }
`

const GET_CONTACT_BY_EMAIL_FOR_CHIP = gql`
  query GetContactByEmailForChip($contactEmail: String!, $ownerEmail: String!) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const renderPlainParents = ['heading', 'title']

const getStyle = () => {
  return {
    height: '32px',
    fontWeight: 600,
    borderRadius: '0px',
    border: 'none',
    '& .MuiChip-label': {
      pr: '2px !important',
      pl: '4px !important',
    },
    '& .personAvatarBox, .personAvatarBox .MuiAvatar-root': {
      height: '12px !important',
      width: '12px !important',
    },
    '&:hover': {
      background: lighten('#B4D7FF', 0.8),
      borderBottom: (theme) => `1px solid ${theme.palette.text.secondary}`,
    },
    '&.selected': {
      background: lighten('#B4D7FF', 0.8),
      border: `1px solid #B4D7FF !important`,
      color: '#2867B2',
    },
  }
}

const avatarSx = {
  height: '12px !important',
  width: '12px !important',
}

const ObjectChipComponent = (props) => {
  const { node } = props
  const resolvedPos = props.editor.state.doc.resolve(props.getPos())
  const parent = resolvedPos.parent

  const { currentUser } = useAuth()
  const { setSidebarObject, selectedWorkspace } = useContext(DayContext)
  const { objectType, objectId, displayName } = node.attrs

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = () => {
    setSidebarObject({
      objectType,
      objectId,
      properties: {},
    })
  }

  const {
    isOpen: showPreview,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  } = useHoverBoundary({
    onBoundaryClick: handleClick,
  })

  useEffect(() => {
    if (showPreview) {
      document.addEventListener('mousemove', handleMouseMove)
      return () => document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [showPreview, handleMouseMove])

  const [localMetadata, setLocalMetadata] = useState<{
    label: string | null
    avatarUrl: string | null
  }>({ label: null, avatarUrl: null })

  const { data: chipData } = useQuery(GET_CHIP_METADATA, {
    variables: {
      objectId,
      objectType,
      workspaceId: selectedWorkspace,
    },
    skip:
      !objectId ||
      !objectType ||
      !selectedWorkspace ||
      [NativeObjectTypes.MeetingRecording].includes(objectType as any),
  })

  const chipMetadata = chipData?.chipMetadata

  useEffect(() => {
    if (chipMetadata) {
      setLocalMetadata({
        label: chipMetadata.label,
        avatarUrl: chipMetadata.avatarUrl,
      })
    } else {
      getObjectMetadata({
        workspaceId: selectedWorkspace,
        objectType: objectType as any,
        objectId,
      }).then((metadata) => {
        setLocalMetadata({
          label: metadata.label,
          avatarUrl: metadata.avatarUrl,
        })
      })
    }
  }, [selectedWorkspace, objectType, objectId, chipMetadata])

  const { data: personData, loading: loadingPerson } = useQuery(
    GET_CONTACT_BY_EMAIL_FOR_CHIP,
    {
      variables: {
        contactEmail: objectId,
        ownerEmail: currentUser?.email,
      },
      skip:
        !objectId ||
        !currentUser?.email ||
        objectType != NativeObjectTypes.Contact,
    }
  )

  const fallbackAvatar = useMemo(() => {
    if (objectType === NativeObjectTypes.Organization) {
      return (
        <DomainAvatar
          domain={objectId}
          size={18}
        />
      )
    } else if (objectType === NativeObjectTypes.Contact) {
      return (
        <ContactAvatar
          email={objectId}
          size={18}
        />
      )
    } else {
      const objectIcon =
        ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
      return (
        <Avatar
          src={null}
          sx={{
            background: (theme) => theme.palette.background.default,
            ...avatarSx,
          }}
        >
          {React.createElement(objectIcon, {
            size: 12,
            stroke: 2.45,
            style: {
              marginTop: '-1px',
            },
          })}
        </Avatar>
      )
    }
  }, [objectType, objectId])

  const selectedStyle = getStyle()

  const useChip = !renderPlainParents.includes(parent.type.name)
  const loading = loadingPerson // || loadingCompany etc in the future
  const crmObject = personData?.getContactByEmail || {}

  const bestLabel = useMemo(() => {
    return getBestLabel({
      passedProperties: crmObject.properties as Record<string, any>,
      localMetadata,
      serverMetadata: chipMetadata,
      objectType,
      objectId,
    })
  }, [crmObject.properties, localMetadata, chipMetadata, objectType, objectId])

  const label = loading || !crmObject?.objectId ? displayName : bestLabel

  return (
    <NodeViewWrapper
      as="span"
      style={{ display: useChip ? 'inline-block' : 'inline' }}
      draggable={props.editor.isEditable}
    >
      <>
        {useChip ? (
          <Chip
            avatar={fallbackAvatar}
            label={label}
            variant="outlined"
            color="primary"
            clickable={true}
            className={`day-ai-node day-ai-object-chip ${
              props.editor.isEditable ? 'editable' : 'public'
            } ${props.selected ? 'selected' : ''}`}
            sx={selectedStyle}
            onClick={handleClick}
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget)
              handleMouseEnter(e)
            }}
            onMouseLeave={handleMouseLeave}
          />
        ) : (
          <Box
            component="span"
            sx={{
              color: (theme) =>
                props.selected ? '#2867B2' : theme.palette.secondary.dark,
              cursor: 'pointer',
              wordBreak: 'break-word',
              '&:hover': {
                color: (theme) => theme.palette.secondary.main,
              },
              background: props.selected && lighten('#B4D7FF', 0.8),
            }}
            onClick={handleClick}
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget)
              handleMouseEnter(e)
            }}
            onMouseLeave={handleMouseLeave}
          >
            {label}
          </Box>
        )}

        <ObjectPreviewMenu
          objectType={objectType}
          objectId={objectId}
          workspaceId={selectedWorkspace}
          anchorEl={anchorEl}
          open={showPreview && !!anchorEl}
          onClose={() => setAnchorEl(null)}
        />
      </>
    </NodeViewWrapper>
  )
}

export const TiptapObjectChip = Node.create<ObjectChipOptions>({
  name: 'objectChip',
  inline: true,
  group: 'inline',
  selectable: true,

  addAttributes() {
    return {
      objectType: {
        default: null,
      },
      objectId: {
        default: null,
      },
      displayName: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      node.attrs.displayName,
    ]
  },

  renderText({ node }) {
    return node.attrs.displayName
  },

  addNodeView() {
    return ReactNodeViewRenderer(ObjectChipComponent)
  },
})
