export const CREATE_PROPERTY_DEFINITION = gql`
  mutation CreateObjectPropertyDefinition(
    $workspaceId: String!
    $input: CreateObjectPropertyDefinitionInput!
  ) {
    createObjectPropertyDefinition(workspaceId: $workspaceId, input: $input) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      propertyType {
        id
        name
        description
      }
      aiManaged
      useWeb
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_PROPERTY_DEFINITION = gql`
  mutation UpdateObjectPropertyDefinition(
    $workspaceId: String!
    $id: String!
    $input: UpdateObjectPropertyDefinitionInput!
  ) {
    updateObjectPropertyDefinition(
      workspaceId: $workspaceId
      id: $id
      input: $input
    ) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      propertyType {
        id
        name
        description
      }
      aiManaged
      useWeb
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

export const DELETE_PROPERTY_DEFINITION = gql`
  mutation DeleteObjectPropertyDefinition($workspaceId: String!, $id: String!) {
    deleteObjectPropertyDefinition(workspaceId: $workspaceId, id: $id)
  }
`

export const PROPERTY_DEFINITIONS = gql`
  query ObjectPropertyDefinitions($workspaceId: String!) {
    objectPropertyDefinitions(workspaceId: $workspaceId) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      propertyType {
        id
        name
        description
      }
      aiManaged
      useWeb
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

export const CREATE_OPPORTUNITY_FOR_DIALOG = gql`
  mutation createOpportunityForDialog($input: OpportunityCreateInput!) {
    createOpportunity(input: $input) {
      id
      workspaceId
      pipelineId
      title
      ownerEmail
      ownerId
      expectedCloseDate
      expectedRevenue
      domain
      position
      status
      daysInStage
      type
      updatedAt
      roles {
        personEmail
        roles
      }
      goals {
        content
        source {
          sourceId
          sourceType
        }
      }
      impactOfChange {
        content
        source {
          sourceId
          sourceType
        }
      }
      budgetAndTimeline {
        content
        source {
          sourceId
          sourceType
        }
      }
      challenges {
        challenge
        solution
        source {
          sourceId
          sourceType
        }
      }
      risks {
        content
        source {
          sourceId
          sourceType
        }
      }
      competition {
        content
        source {
          sourceId
          sourceType
        }
      }
      decisionProcess {
        content
        source {
          sourceId
          sourceType
        }
      }
    }
  }
`
