import { useContext } from 'react'

import ViewsContext from './ViewsContext'

const useViews = () => {
  const context = useContext(ViewsContext)
  if (context === undefined) {
    throw new Error('useViews must be used within a ViewsProvider')
  }

  return context
}

export default useViews
