import { Checkbox, Chip, Link, Typography } from '@mui/material'
import { IconTriangleFilled } from '@tabler/icons-react'

import { isValidEmail } from 'src/lib/contactFormatting'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, PropertyTypes } from 'src/lib/objects'

import MetadataChip from '../Chips/MetadataChip/MetadataChip'
import ObjectChip from '../Chips/ObjectChip/ObjectChip'
import Row from '../Row/Row'

const PicklistOptionsRow = ({ values, options }) => {
  return (
    <Row sx={{ height: '100%', flexWrap: 'wrap', gap: 0.5 }}>
      {values &&
        values.map((optionId) => {
          if (!optionId) return null
          const option = options.find((opt) => opt.id === optionId)
          return (
            optionId && (
              <Chip
                key={optionId}
                label={option?.name || optionId}
                size="small"
                sx={{
                  height: '20px',
                  fontSize: '11px',
                  '& .MuiChip-label': {
                    px: 1,
                  },
                }}
              />
            )
          )
        })}
    </Row>
  )
}

const PropertyDisplay = ({
  propertyDefinition,
  objectId: _objectId,
  objectType: _objectType,
  workspaceId,
  value,
  justify = 'flex-end',
}) => {
  let component = null
  let label = null
  let useLink = false

  const propertyType = propertyDefinition.propertyType?.id

  logger.dev({ propertyType, value, name: propertyDefinition.name })

  if (value === undefined || value === null) {
    label = null
  } else if (propertyDefinition.propertyType === PropertyTypes.Boolean) {
    component = <Checkbox checked={value} />
  } else if (propertyType === PropertyTypes.TextArea) {
    label = value
  } else if (propertyType === PropertyTypes.DateTime) {
    label = value
  } else if (propertyType === PropertyTypes.Currency) {
    label = value ? `$${Math.round(value).toLocaleString()}` : ''
  } else if (propertyType === PropertyTypes.Email) {
    if (isValidEmail(value)) {
      component = (
        <Row>
          <ObjectChip
            crmObject={{
              objectId: value,
              objectType: NativeObjectTypes.Person,
              properties: { email: value },
            }}
            workspaceId={workspaceId}
          />
        </Row>
      )
    } else if (value?.length > 0) {
      component = (
        <MetadataChip
          state={{
            label: `Invalid Email (${value})`,
            value: value,
            color: 'error',
          }}
          icon={<IconTriangleFilled />}
        />
      )
    }
  } else if (propertyType === PropertyTypes.Phone) {
    label = value
  } else if (propertyType === PropertyTypes.DeleteMarker) {
    //Obviously should never happen
    label = null
  } else if (propertyType === PropertyTypes.Calculated) {
    label = value
  } else if (propertyType === PropertyTypes.Address) {
    label = value
  } else if (propertyType === PropertyTypes.Url) {
    label = value ? (!value.startsWith('http') ? `http://${value}` : value) : ''
    useLink = true
  } else if (propertyType === PropertyTypes.Combobox) {
    label = null
  } else if (propertyType === PropertyTypes.MultiPicklist) {
    label = null
  } else if (propertyType === PropertyTypes.Integer) {
    logger.dev({ value, typeofValue: typeof value })
    if (typeof value === 'number') {
      label = Math.round(value).toLocaleString()
    }
  }

  const hasOptions = [
    PropertyTypes.Combobox,
    PropertyTypes.MultiPicklist,
    PropertyTypes.Picklist,
  ].includes(propertyType)

  const containerSx = {
    width: '100%',
    justifyContent: justify,
    '& .MuiTypography-root': {
      fontSize: '12px',
      color: 'text.secondary',
      fontWeight: 400,
      letterSpacing: '-0.22px',
    },
  }

  return (
    <Row sx={containerSx}>
      {component ? (
        component
      ) : useLink ? (
        <Link
          href={label}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </Link>
      ) : hasOptions ? (
        <PicklistOptionsRow
          values={value}
          options={propertyDefinition.options}
        />
      ) : (
        <Typography>{label}</Typography>
      )}
    </Row>
  )
}

export default PropertyDisplay
