import { darken } from '@mui/material'

export const modernToggleButtonGroupStyles = {
  borderRadius: '6px',
  '& .MuiToggleButton-root': {
    py: '4px',
    mr: '4px',
    fontSize: '11px',
    letterSpacing: '-0.22px',
    textTransform: 'none',
    color: (theme) => theme.palette.text.secondary,
    border: (theme) => `1px solid ${theme.palette.divider} !important`,
    background: (theme) => theme.palette.background.paper,
    borderRadius: '3px',
    transition: 'all 0.2s ease-in-out',
    '& .MuiSvgIcon-root': {
      mr: 1,
    },

    '&:hover': {
      opacity: 1,
      background: (theme) => theme.palette.background.paper,

      boxShadow: `
              0 1px 2px rgba(136, 161, 183, 0.07),
              0 2px 4px rgba(136, 161, 183, 0.07)
            `,
    },
    '&.Mui-selected': {
      opacity: 1,
      background: (theme) => theme.palette.action.selected,
      border: (theme) => `1px solid ${theme.palette.divider} !important`,
      boxShadow: `
              0 1px 2px rgba(136, 161, 183, 0.07),
              0 2px 4px rgba(136, 161, 183, 0.07)
            `,
    },
  },
} as const

export const modernTabStyles = {
  '& .MuiTabs-root': {
    width: '100%',
    borderRadius: '4px',
    overflow: 'visible',
    '& .MuiTabs-scroller.MuiTabs-fixed': {
      overflow: 'visible !important',
    },
    '& .MuiTab-root': {
      background: (theme) => theme.palette.background.paper,
      color: (theme) => theme.palette.text.secondary,
      border: (theme) => `1px solid ${theme.palette.divider}`,
      borderRadius: '4px',
      flexGrow: 1,
      textTransform: 'none',
      fontSize: '11px',
      fontWeight: 600,
      minHeight: '0px',
      minWidth: '0px',
      height: '30px',
      fontFeatureSettings: 'liga off, clig off',
      mr: '4px',
      transition: 'all 0.2s ease-in-out',
      '& .MuiTypography-root': {
        fontSize: '11px',
      },
      '&.Mui-selected': {
        background: (theme) => theme.palette.action.selected,
        opacity: 1,
        fontWeight: 600,
        border: (theme) => `1px solid ${darken(theme.palette.divider, 0.05)}`,
        boxShadow: `
                0 1px 2px rgba(136, 161, 183, 0.07),
                0 2px 4px rgba(136, 161, 183, 0.07)
              `,
        '& .MuiTypography-root': {
          fontWeight: 500,
        },
      },
    },
  },
} as const

export const modernButtonContainer = {
  '& .MuiButtonBase-root': {
    py: 0,
    px: '12px',
    textTransform: 'none',
    fontSize: '11px',
    minHeight: '0px',
    height: '30px',
    fontFeatureSettings: 'liga off, clig off',
    fontWeight: 500,
    mx: '4px',
    border: (theme) => `1px solid ${theme.palette.divider} !important`,
    borderRadius: '3px',
    '& .MuiButton-startIcon': {
      mr: '6px',
    },
    boxShadow: (theme) => theme.shadows[1],
  },
}

export const modernToolbarIconButton = {
  height: '30px',
  width: '30px',
  fontSize: '11px',
  background: (theme) => theme.palette.background.paper,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
}
