import { useCallback, useRef, useState, useEffect } from 'react'

interface UseHoverBoundaryOptions {
  onBoundaryClick?: () => void
  closeDelay?: number
  popoverSize?: {
    width: number
    height: number
  }
  getBoundaryRect?: () => {
    left: number
    right: number
    top: number
    bottom: number
  } | null
}

export const useHoverBoundary = (options: UseHoverBoundaryOptions = {}) => {
  const {
    onBoundaryClick,
    closeDelay = 150,
    popoverSize = { width: 320, height: 72 },
    getBoundaryRect,
  } = options
  const [isOpen, setIsOpen] = useState(false)
  const chipRef = useRef<HTMLElement | null>(null)
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const lastMousePosition = useRef<{ x: number; y: number } | null>(null)

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!chipRef.current) return

      // Update last known mouse position
      lastMousePosition.current = { x: e.clientX, y: e.clientY }

      // Use custom boundary rect if provided, otherwise calculate default
      const boundaryRect =
        getBoundaryRect?.() ||
        (() => {
          const chipRect = chipRef.current.getBoundingClientRect()
          return {
            left: chipRect.left + (chipRect.width - popoverSize.width) / 2,
            right: chipRect.left + (chipRect.width + popoverSize.width) / 2,
            top: chipRect.top - popoverSize.height - 8,
            bottom: chipRect.top - 8,
          }
        })()

      if (!boundaryRect) return

      const isInBoundary =
        e.clientX >= boundaryRect.left &&
        e.clientX <= boundaryRect.right &&
        e.clientY >= boundaryRect.top &&
        e.clientY <= boundaryRect.bottom

      if (!isInBoundary) {
        // Start close timeout if not already started
        if (!closeTimeoutRef.current) {
          closeTimeoutRef.current = setTimeout(() => {
            setIsOpen(false)
            closeTimeoutRef.current = null
          }, closeDelay)
        }
      } else {
        // Cancel close timeout if mouse re-enters boundaries
        if (closeTimeoutRef.current) {
          clearTimeout(closeTimeoutRef.current)
          closeTimeoutRef.current = null
        }
      }
    },
    [closeDelay, popoverSize, getBoundaryRect]
  )

  useEffect(() => {
    // Add mousemove listener whenever isOpen changes
    if (isOpen) {
      document.addEventListener('mousemove', handleMouseMove)
      return () => document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isOpen, handleMouseMove])

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!chipRef.current) return

      const chipRect = chipRef.current.getBoundingClientRect()
      const isInChipBoundary =
        e.clientX >= chipRect.left &&
        e.clientX <= chipRect.right &&
        e.clientY >= chipRect.top &&
        e.clientY <= chipRect.bottom

      if (isInChipBoundary && onBoundaryClick) {
        onBoundaryClick()
      }
    },
    [onBoundaryClick]
  )

  useEffect(() => {
    // Always listen for clicks when we have a chip reference
    if (chipRef.current) {
      document.addEventListener('click', handleClick)
      return () => document.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
      }
    }
  }, [])

  const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLElement>) => {
    chipRef.current = e.currentTarget
    // Cancel any pending close
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
      closeTimeoutRef.current = null
    }
    setIsOpen(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    // Don't close immediately, let the mousemove handler decide
  }, [])

  return {
    isOpen,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  }
}
