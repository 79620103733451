import { useCallback, useReducer, useEffect, useState, useRef } from 'react'

import {
  Box,
  Chip,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  IconBrowser,
  IconCheck,
  IconEdit,
  IconPlus,
  IconSparkles,
} from '@tabler/icons-react'
import { toast } from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { PropertyTypes } from 'src/lib/objects'

const PROPERTY_DEFINITIONS_FOR_SIDEBAR = gql`
  query ObjectPropertyDefinitionsForSidebar($workspaceId: String!) {
    objectPropertyDefinitions(workspaceId: $workspaceId) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      useWeb
      aiManaged
      propertyType {
        id
        name
        description
      }
      aiManaged
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

const UPDATE_OBJECT_PROPERTY_FROM_SIDEBAR = gql`
  mutation UpdateObjectPropertyFromSidebar($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      success
    }
  }
`

const POPULATE_OBJECT_PROPERTY_FROM_WEB = gql`
  mutation PopulateObjectPropertyFromWeb($input: PopulateObjectPropertyInput!) {
    populateObjectPropertyFromWeb(input: $input)
  }
`

const POPULATE_OBJECT_PROPERTY_FROM_CONTEXT = gql`
  mutation PopulateObjectPropertyFromContext(
    $input: PopulateObjectPropertyInput!
  ) {
    populateObjectPropertyFromContext(input: $input)
  }
`
// Action types
const ACTIONS = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
  UPDATE_PROPERTY_ERROR: 'UPDATE_PROPERTY_ERROR',
}

// Reducer function
const propertiesReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INITIAL_STATE:
      return {
        ...state,
        properties: action.payload,
        pendingUpdates: {},
      }
    case ACTIONS.UPDATE_PROPERTY: {
      const { propertyDefinitionId, optionId, value, propertyType } =
        action.payload

      let finalValue

      if (
        [
          PropertyTypes.Combobox,
          PropertyTypes.MultiPicklist,
          PropertyTypes.Picklist,
        ].includes(propertyType)
      ) {
        const currentValues = Array.isArray(
          state.properties[propertyDefinitionId]
        )
          ? [...state.properties[propertyDefinitionId]]
          : []

        // Add or remove the optionId based on the value
        const updatedValues = value
          ? [...currentValues, optionId]
          : currentValues.filter((id) => id !== optionId)

        finalValue = updatedValues
      } else {
        finalValue = value
      }

      return {
        ...state,
        properties: {
          ...state.properties,
          [propertyDefinitionId]: finalValue,
        },
        pendingUpdates: {
          ...state.pendingUpdates,
          [`${propertyDefinitionId}-${optionId}`]: true,
        },
      }
    }
    case ACTIONS.UPDATE_PROPERTY_SUCCESS: {
      const { [action.payload.key]: _, ...remainingPendingUpdates } =
        state.pendingUpdates
      return {
        ...state,
        pendingUpdates: remainingPendingUpdates,
      }
    }
    case ACTIONS.UPDATE_PROPERTY_ERROR: {
      const { propertyDefinitionId, optionId, value } = action.payload
      const currentValues = Array.isArray(
        state.properties[propertyDefinitionId]
      )
        ? [...state.properties[propertyDefinitionId]]
        : []

      // Revert the change by either removing or adding back the optionId
      const revertedValues = value
        ? currentValues.filter((id) => id !== optionId)
        : [...currentValues, optionId]

      return {
        ...state,
        properties: {
          ...state.properties,
          [propertyDefinitionId]: revertedValues,
        },
        pendingUpdates: {
          ...state.pendingUpdates,
          [`${propertyDefinitionId}-${optionId}`]: false,
        },
      }
    }
    default:
      return state
  }
}

const fallbackCustomProperties = {}

const menuItemPrimaryTypographyProps = {
  color: 'text.secondary',
  sx: {
    fontSize: '12px',
    fontWeight: 500,
  },
}

const menuSx = {
  width: '272px',
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },
}

const CustomPropertyEditMenu = ({
  propertyDefinition,
  objectId,
  objectType,
  currentValue,
  populateFromWeb,
  populateFromContext,
  updateObjectProperty,
}: {
  propertyDefinition: any
  objectId: string
  objectType: string
  currentValue: string
  populateFromWeb: ({ propertyDefinitionId, objectId, objectType }) => void
  populateFromContext: ({ propertyDefinitionId, objectId, objectType }) => void
  updateObjectProperty: ({ propertyDefinitionId, value, propertyType }) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [manualValue, setManualValue] = useState(currentValue)
  const hasChanged = useRef(false)

  if (manualValue !== currentValue && !hasChanged.current) {
    setManualValue(currentValue)
  }

  const resetMenu = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleSave = useCallback(() => {
    const input = {
      propertyDefinitionId: propertyDefinition.id,
      value: manualValue,
      propertyType: propertyDefinition.propertyType.id,
    }
    updateObjectProperty(input)
    resetMenu()
  }, [updateObjectProperty, propertyDefinition, manualValue, resetMenu])

  return (
    <Box>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <IconEdit size={16} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => resetMenu()}
        MenuListProps={{
          sx: menuSx,
        }}
      >
        <MenuItem>
          <TextField
            label={propertyDefinition.name}
            value={manualValue}
            onChange={(e) => {
              setManualValue(e.target.value)
              hasChanged.current = true
            }}
            size="small"
            fullWidth={true}
            sx={{ mt: '4px' }}
          />
        </MenuItem>
        {hasChanged.current && (
          <Tooltip
            title="Your edits will take precendence over AI and Web research values"
            arrow={true}
            placement="left"
          >
            <ListItemButton
              disabled={!hasChanged.current}
              onClick={handleSave}
            >
              <ListItemIcon>
                <IconCheck size={16} />
              </ListItemIcon>
              <ListItemText
                primary="Save"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
        {propertyDefinition.aiManaged && (
          <Tooltip
            title={`Day.ai will use the available communication and context to reason, decide, & populate value(s) for ${propertyDefinition.name}`}
            arrow={true}
            placement="left"
          >
            <ListItemButton
              onClick={() =>
                populateFromContext({
                  propertyDefinitionId: propertyDefinition.id,
                  objectId,
                  objectType,
                })
              }
            >
              <ListItemIcon>
                <IconSparkles size={16} />
              </ListItemIcon>
              <ListItemText
                primary="Day.ai population enabled"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
        {propertyDefinition.useWeb && (
          <Tooltip
            title="Click to trigger population from the web"
            arrow={true}
            placement="left"
          >
            <ListItemButton
              onClick={() =>
                populateFromWeb({
                  propertyDefinitionId: propertyDefinition.id,
                  objectId,
                  objectType,
                })
              }
            >
              <ListItemIcon>
                <IconBrowser size={16} />
              </ListItemIcon>
              <ListItemText
                primary="Web research enabled"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
      </Menu>
    </Box>
  )
}
const CustomPropertyOptionMenu = ({
  options,
  selectedOptionIds,
  onSelect,
  objectId,
  propertyDefinition,
  populateFromWeb,
  populateFromContext,
}: {
  options: any[]
  selectedOptionIds: string[]
  onSelect: (optionId: string) => void
  objectId: string
  propertyDefinition: any
  populateFromWeb: ({
    propertyDefinitionId,
    objectId,
    objectType,
  }: {
    propertyDefinitionId: string
    objectId: string
    objectType: string
  }) => void
  populateFromContext: ({
    propertyDefinitionId,
    objectId,
    objectType,
  }: {
    propertyDefinitionId: string
    objectId: string
    objectType: string
  }) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const safeSelectedOptionIds = Array.isArray(selectedOptionIds)
    ? selectedOptionIds
    : []

  const containerSx = {}
  return (
    <Box sx={containerSx}>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <IconPlus size={16} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        className="custom-property-option-menu"
        MenuListProps={{
          sx: {
            minWidth: '212px',
          },
        }}
      >
        {options.map(
          (option) =>
            !safeSelectedOptionIds.includes(option.id) && (
              <MenuItem key={option.id}>
                <Chip
                  size="small"
                  label={option.name}
                  onClick={() => {
                    onSelect(option.id)
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </MenuItem>
            )
        )}
        {propertyDefinition.aiManaged && (
          <Tooltip
            title={`Day.ai will use the available communication and context to reason, decide, & populate value(s) for ${propertyDefinition.name}`}
            arrow={true}
            placement="left"
          >
            <ListItemButton
              onClick={() =>
                populateFromContext({
                  propertyDefinitionId: propertyDefinition.id,
                  objectId,
                  objectType: propertyDefinition.objectType,
                })
              }
            >
              <ListItemIcon>
                <IconSparkles size={16} />
              </ListItemIcon>
              <ListItemText
                primary="AI may populate this item from context"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
        {propertyDefinition.useWeb && (
          <Tooltip
            title="Click to trigger population from the web"
            arrow={true}
            placement="left"
          >
            <ListItemButton
              onClick={() =>
                populateFromWeb({
                  propertyDefinitionId: propertyDefinition.id,
                  objectId,
                  objectType: propertyDefinition.objectType,
                })
              }
            >
              <ListItemIcon>
                <IconBrowser size={16} />
              </ListItemIcon>
              <ListItemText
                primary="AI may populate this item from the web"
                primaryTypographyProps={menuItemPrimaryTypographyProps}
              />
            </ListItemButton>
          </Tooltip>
        )}
      </Menu>
    </Box>
  )
}

const hasOptions = (propertyDefinition) => {
  return [
    PropertyTypes.Combobox,
    PropertyTypes.MultiPicklist,
    PropertyTypes.Picklist,
  ].includes(propertyDefinition.propertyType.id)
}

const SidebarCustomProperties = ({
  workspaceId,
  customProperties = fallbackCustomProperties,
  objectType,
  objectId,
}) => {
  logger.dev({ customProperties })
  const [state, dispatch] = useReducer(propertiesReducer, {
    properties: {},
    pendingUpdates: {},
  })
  const [populateFromWeb] = useMutation(POPULATE_OBJECT_PROPERTY_FROM_WEB)
  const [populateFromContext] = useMutation(
    POPULATE_OBJECT_PROPERTY_FROM_CONTEXT
  )
  const handlePopulateFromWeb = useCallback(
    ({ propertyDefinitionId, objectId, objectType }) => {
      toast.promise(
        populateFromWeb({
          variables: {
            input: {
              workspaceId,
              objectType,
              objectId,
              propertyDefinitionId,
            },
          },
        }),
        {
          loading: 'Populating from web...',
          success: 'Populating from web ... (may take a few minutes)',
          error: 'Failed to populate from web',
        }
      )
    },
    [workspaceId, populateFromWeb]
  )

  const handlePopulateFromContext = useCallback(
    ({ propertyDefinitionId, objectId, objectType }) => {
      toast.promise(
        populateFromContext({
          variables: {
            input: {
              workspaceId,
              objectType,
              objectId,
              propertyDefinitionId,
            },
          },
        }),
        {
          loading: 'Populating from context...',
          success: 'Populating from context ... (may take a few minutes)',
          error: 'Failed to populate from context',
        }
      )
    },
    [workspaceId, populateFromContext]
  )

  const { data: propertyDefinitions } = useQuery(
    PROPERTY_DEFINITIONS_FOR_SIDEBAR,
    {
      variables: {
        workspaceId,
      },
      skip: !workspaceId,
    }
  )

  const [updateObjectPropertyFromSidebar] = useMutation(
    UPDATE_OBJECT_PROPERTY_FROM_SIDEBAR
  )

  // Set initial state when customProperties changes
  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_INITIAL_STATE,
      payload: customProperties,
    })
  }, [customProperties])

  const handleUpdateObjectPropertyFromSidebar = useCallback(
    async ({ propertyDefinitionId, optionId = null, value, propertyType }) => {
      const path = optionId
        ? `custom/${propertyDefinitionId}/${optionId}`
        : `custom/${propertyDefinitionId}`

      // Optimistically update UI
      dispatch({
        type: ACTIONS.UPDATE_PROPERTY,
        payload: { propertyDefinitionId, optionId, value, propertyType },
      })

      logger.dev({ propertyDefinitionId, optionId, value, propertyType })

      try {
        await updateObjectPropertyFromSidebar({
          variables: {
            input: {
              workspaceId,
              objectType,
              objectId,
              value,
              path,
              propertyType,
            },
          },
        })

        dispatch({
          type: ACTIONS.UPDATE_PROPERTY_SUCCESS,
          payload: { key: `${propertyDefinitionId}-${optionId}` },
        })
      } catch (error) {
        logger.error('Failed to update property:', error)

        // Revert the optimistic update
        dispatch({
          type: ACTIONS.UPDATE_PROPERTY_ERROR,
          payload: { propertyDefinitionId, optionId, value },
        })
      }
    },
    [workspaceId, objectType, objectId, updateObjectPropertyFromSidebar]
  )

  const containerSx = {
    width: '100%',
    '.container-row': {
      mt: 4,
      width: '100%',
    },
    '& .options-row': {
      justifyContent: 'flex-end',
      width: '100%',
    },
    '& .no-options-row': {
      justifyContent: 'flex-end',
      width: '100%',
    },
    '& .no-options-container': {
      display: 'block',
      width: '100%',
    },
    '& .custom-property-value': {
      fontSize: '12px',
      color: 'text.secondary',
    },
  }

  return (
    <Box sx={containerSx}>
      {propertyDefinitions?.objectPropertyDefinitions.map(
        (propertyDefinition, index) =>
          propertyDefinition && (
            <>
              {hasOptions(propertyDefinition) ? (
                <Row
                  key={`${index}-${propertyDefinition.id}`}
                  className="container-row"
                >
                  <Typography
                    variant="h2"
                    sx={{ flexShrink: 0 }}
                  >
                    {propertyDefinition.name}
                  </Typography>
                  <Row className="options-row">
                    {(propertyDefinition.options || []).map((option) => {
                      const propertyValues = Array.isArray(
                        state?.properties?.[propertyDefinition?.id]
                      )
                        ? state.properties[propertyDefinition.id]
                        : []
                      return (
                        option &&
                        propertyValues &&
                        propertyValues.includes(option.id) && (
                          <Chip
                            key={option.id}
                            label={option.name}
                            size="small"
                            onDelete={() => {
                              handleUpdateObjectPropertyFromSidebar({
                                propertyDefinitionId: propertyDefinition.id,
                                optionId: option.id,
                                value: false,
                                propertyType:
                                  propertyDefinition.propertyType.id,
                              })
                            }}
                          />
                        )
                      )
                    })}
                    <CustomPropertyOptionMenu
                      options={propertyDefinition.options}
                      selectedOptionIds={
                        state?.properties?.[propertyDefinition.id] || []
                      }
                      onSelect={(optionId) => {
                        handleUpdateObjectPropertyFromSidebar({
                          propertyDefinitionId: propertyDefinition.id,
                          optionId,
                          value: true,
                          propertyType: propertyDefinition.propertyType.id,
                        })
                      }}
                      objectId={objectId}
                      propertyDefinition={propertyDefinition}
                      populateFromWeb={handlePopulateFromWeb}
                      populateFromContext={handlePopulateFromContext}
                    />
                  </Row>
                </Row>
              ) : (
                <Box
                  className="no-options-container"
                  key={`${index}-${propertyDefinition.id}`}
                >
                  <Row className="container-row">
                    <Typography
                      variant="h2"
                      sx={{ flexShrink: 0 }}
                    >
                      {propertyDefinition.name}
                    </Typography>
                    <Row className="no-options-row">
                      {((state?.properties?.[propertyDefinition.id] || '')
                        ?.length < 40 ||
                        propertyDefinition.propertyType.id !==
                          PropertyTypes.TextArea) && (
                        <PropertyDisplay
                          propertyDefinition={propertyDefinition}
                          objectId={objectId}
                          objectType={objectType}
                          value={
                            state?.properties?.[propertyDefinition.id] || ''
                          }
                          workspaceId={workspaceId}
                        />
                      )}
                      <CustomPropertyEditMenu
                        propertyDefinition={propertyDefinition}
                        objectId={objectId}
                        objectType={objectType}
                        currentValue={
                          state?.properties?.[propertyDefinition.id] || ''
                        }
                        populateFromWeb={handlePopulateFromWeb}
                        populateFromContext={handlePopulateFromContext}
                        updateObjectProperty={
                          handleUpdateObjectPropertyFromSidebar
                        }
                      />
                    </Row>
                  </Row>

                  {(state?.properties?.[propertyDefinition.id] || '')?.length >=
                    40 && (
                    <PropertyDisplay
                      propertyDefinition={propertyDefinition}
                      objectId={objectId}
                      objectType={objectType}
                      value={state?.properties?.[propertyDefinition.id] || ''}
                      workspaceId={workspaceId}
                    />
                  )}
                </Box>
              )}
            </>
          )
      )}
    </Box>
  )
}

export default SidebarCustomProperties
