import { useCallback, useState } from 'react'

import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Chip,
} from '@mui/material'
import {
  IconCircleFilled,
  IconPlus,
  IconSparkles,
  IconBrowser,
  IconCopyPlus,
  IconTrash,
} from '@tabler/icons-react'
import { useConfirm } from 'material-ui-confirm'

import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { PropertyTypes } from 'src/lib/objects'

import { objectPropertyDefinitions } from './objectPropertyDefinitions'

// Types
type PropertyDefinitionAction =
  | { type: 'SET_NAME'; payload: string }
  | { type: 'SET_DESCRIPTION'; payload: string }
  | { type: 'SET_PROPERTY_TYPE'; payload: string }
  | { type: 'SET_AI_ENABLED'; payload: boolean }
  | { type: 'SET_USE_WEB'; payload: boolean }
  | {
      type: 'SET_OPTIONS'
      payload: Array<{ id: string; name: string; description?: string }>
    }
  | { type: 'ADD_OPTION'; payload: { name: string; description: string } }
  | { type: 'REMOVE_OPTION'; payload: string }
  | {
      type: 'EDIT_OPTION'
      payload: { id: string; name: string; description: string }
    }

interface PropertyDefinitionFormProps {
  state: {
    name: string
    description: string
    propertyType: string
    aiEnabled: boolean
    useWeb: boolean
    options: Array<{
      id: string
      name: string
      description?: string
    }>
    selectedDefinition: any | null
  }
  dispatch: React.Dispatch<PropertyDefinitionAction>
  onSubmit: () => Promise<void>
  onDelete?: () => Promise<void>
}

function isPicklist(propertyType: string) {
  return (
    propertyType === PropertyTypes.MultiPicklist ||
    propertyType === PropertyTypes.Picklist ||
    propertyType === PropertyTypes.Combobox
  )
}

function anyAiEnabled(state: PropertyDefinitionFormProps['state']) {
  return state.aiEnabled || state.useWeb
}

const PropertyDefinitionForm = ({
  state,
  dispatch,
  onSubmit,
  onDelete,
}: PropertyDefinitionFormProps) => {
  const confirm = useConfirm()
  const [showNewOptionForm, setShowNewOptionForm] = useState(false)
  const [newOptionLabel, setNewOptionLabel] = useState('')
  const [newOptionDefinition, setNewOptionDefinition] = useState('')
  const [editingOptionKey, setEditingOptionKey] = useState<string | null>(null)
  const [editedLabel, setEditedLabel] = useState('')
  const [editedDefinition, setEditedDefinition] = useState('')

  const handleAddOption = useCallback(() => {
    if (!newOptionLabel) return

    dispatch({
      type: 'ADD_OPTION',
      payload: {
        name: newOptionLabel,
        description: newOptionDefinition,
      },
    })

    setNewOptionLabel('')
    setNewOptionDefinition('')
    setShowNewOptionForm(false)
  }, [dispatch, newOptionLabel, newOptionDefinition])

  const handleRemoveOption = useCallback(
    async (id: string, label: string) => {
      try {
        await confirm({
          title: 'Remove Value',
          description: `Are you sure you want to remove "${label}"?`,
          confirmationText: 'Remove',
          cancellationText: 'Keep',
        })

        dispatch({
          type: 'REMOVE_OPTION',
          payload: id,
        })
      } catch {
        // User cancelled
      }
    },
    [confirm, dispatch]
  )

  const handleStartEditOption = useCallback(
    (option: { id: string; name: string; description?: string }) => {
      setEditingOptionKey(option.id)
      setEditedLabel(option.name)
      setEditedDefinition(option.description || '')
      setShowNewOptionForm(false)
    },
    []
  )

  const handleSaveOption = useCallback(() => {
    if (!editingOptionKey) return

    dispatch({
      type: 'EDIT_OPTION',
      payload: {
        id: editingOptionKey,
        name: editedLabel,
        description: editedDefinition,
      },
    })

    setEditingOptionKey(null)
    setEditedLabel('')
    setEditedDefinition('')
  }, [dispatch, editingOptionKey, editedLabel, editedDefinition])

  return (
    <Stack spacing={2}>
      {state.propertyType ? (
        <>
          <Row>
            <Chip
              label={
                objectPropertyDefinitions?.[state.propertyType]?.name ||
                state.propertyType
              }
              color="primary"
              size="small"
              variant="outlined"
              onDelete={async () => {
                try {
                  await confirm({
                    title: 'Remove Property Type',
                    description:
                      'Are you sure you want to remove this property type?',
                    confirmationText: 'Remove',
                    cancellationText: 'Keep',
                  })
                  dispatch({ type: 'SET_PROPERTY_TYPE', payload: null })
                } catch (error) {
                  logger.warn(
                    'Error setting property type - user may have cancelled'
                  )
                }
              }}
            />
          </Row>
          <TextField
            value={state.name}
            placeholder="Name"
            onChange={(e) =>
              dispatch({ type: 'SET_NAME', payload: e.target.value })
            }
            fullWidth
            variant="outlined"
          />

          <TextField
            value={state.description}
            placeholder={
              anyAiEnabled(state)
                ? 'Description (used by AI to populate)'
                : 'Description'
            }
            onChange={(e) =>
              dispatch({ type: 'SET_DESCRIPTION', payload: e.target.value })
            }
            fullWidth
            variant="outlined"
            multiline
            rows={2}
          />

          {isPicklist(state.propertyType) && (
            <>
              <FormControlLabel
                control={
                  <Tooltip
                    title={
                      state.propertyType === PropertyTypes.MultiPicklist
                        ? 'Switch to single value - Only one value from the list may be selected'
                        : 'Enable multiple values - Multiple values from the list may be selected'
                    }
                    arrow
                  >
                    <Switch
                      checked={
                        state.propertyType === PropertyTypes.MultiPicklist
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'SET_PROPERTY_TYPE',
                          payload: e.target.checked
                            ? PropertyTypes.MultiPicklist
                            : PropertyTypes.Picklist,
                        })
                      }
                    />
                  </Tooltip>
                }
                label={
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Box className="icon-wrapper">
                      <IconCopyPlus size={16} />
                    </Box>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                    >
                      Allow multiple values to be selected
                    </Typography>
                  </Stack>
                }
              />
            </>
          )}

          <FormControlLabel
            control={
              <Tooltip
                title={
                  state.aiEnabled
                    ? 'Disable auto-population - Day.ai will stop adding values to this field'
                    : 'Enable auto-population - Day.ai will start adding values it detects through reasoning'
                }
                arrow
              >
                <Switch
                  checked={state.aiEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_AI_ENABLED',
                      payload: e.target.checked,
                    })
                  }
                />
              </Tooltip>
            }
            label={
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Box className="icon-wrapper">
                  <IconSparkles size={16} />
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                >
                  {state.aiEnabled
                    ? 'Populate with AI. Manual edits will take precedence over AI-generated values.'
                    : 'Do not populate with AI. Day.ai will not use its reasoning to fill in values for this field.'}
                </Typography>
              </Stack>
            }
          />
          <FormControlLabel
            control={
              <Tooltip
                title={
                  state.useWeb
                    ? 'Switch to disable web scraping - Day.ai will not use web data for this field'
                    : 'Enable web scraping - Day.ai will use web data to populate this field'
                }
                arrow
              >
                <Switch
                  checked={state.useWeb}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_USE_WEB',
                      payload: e.target.checked,
                    })
                  }
                />
              </Tooltip>
            }
            label={
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Box className="icon-wrapper">
                  <IconBrowser size={16} />
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                >
                  {state.useWeb
                    ? 'Use data from the web. Manual edits take precedence.'
                    : 'Day.ai will not use web data to populate this field.'}
                </Typography>
              </Stack>
            }
          />
          {isPicklist(state.propertyType) && (
            <>
              <Row
                gap={2}
                className="possible-values"
              >
                <Typography
                  variant="h4"
                  className="possible-values-header"
                >
                  Possible Values {state.name ? `for ${state.name}` : ''}
                </Typography>
              </Row>

              <Row
                gap={1}
                className="options-row"
              >
                {state.options.map((option) => (
                  <MetadataChip
                    key={option.id}
                    state={{
                      label: option.name,
                      value: option.id,
                      color: 'secondary',
                    }}
                    icon={<IconCircleFilled />}
                    onClick={() => handleStartEditOption(option)}
                  />
                ))}
                {!editingOptionKey && (
                  <MetadataChip
                    state={{
                      label: 'Add Option',
                      value: 'new',
                      color: 'default',
                    }}
                    icon={<IconPlus size={16} />}
                    onClick={() => setShowNewOptionForm(true)}
                  />
                )}
              </Row>

              <Stack spacing={2}>
                {editingOptionKey && (
                  <Box className="editing-stack">
                    <Stack spacing={1}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                      >
                        Editing &ldquo;
                        {
                          state.options.find((o) => o.id === editingOptionKey)
                            ?.name
                        }
                        &rdquo;
                      </Typography>
                      <Stack spacing={1}>
                        <TextField
                          placeholder="Option label"
                          value={editedLabel}
                          onChange={(e) => setEditedLabel(e.target.value)}
                          fullWidth
                          size="small"
                          autoFocus
                        />
                        <TextField
                          placeholder="Definition (used by AI to populate)"
                          value={editedDefinition}
                          onChange={(e) => setEditedDefinition(e.target.value)}
                          fullWidth
                          multiline
                          rows={2}
                          size="small"
                        />
                        <Row gap={1}>
                          <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            onClick={handleSaveOption}
                          >
                            Save
                          </Button>
                          <Button
                            size="small"
                            onClick={() => setEditingOptionKey(null)}
                          >
                            Cancel
                          </Button>
                          <Box sx={{ flex: 1 }} />
                          <Button
                            size="small"
                            color="inherit"
                            onClick={() => {
                              const option = state.options.find(
                                (o) => o.id === editingOptionKey
                              )
                              if (option) {
                                handleRemoveOption(option.id, option.name)
                              }
                            }}
                          >
                            Remove
                          </Button>
                        </Row>
                      </Stack>
                    </Stack>
                  </Box>
                )}

                {!editingOptionKey && showNewOptionForm && (
                  <Stack spacing={1}>
                    <TextField
                      placeholder="New option label"
                      value={newOptionLabel}
                      onChange={(e) => setNewOptionLabel(e.target.value)}
                      fullWidth
                      size="small"
                    />
                    <TextField
                      placeholder="Definition (used by AI to populate)"
                      value={newOptionDefinition}
                      onChange={(e) => setNewOptionDefinition(e.target.value)}
                      fullWidth
                      multiline
                      rows={2}
                      size="small"
                    />
                    <Row gap={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleAddOption}
                        disabled={!newOptionLabel}
                        startIcon={<IconPlus size={16} />}
                      >
                        Add Option
                      </Button>
                      <Button
                        size="small"
                        onClick={() => setShowNewOptionForm(false)}
                      >
                        Cancel
                      </Button>
                    </Row>
                  </Stack>
                )}
              </Stack>
            </>
          )}

          <Box sx={{ mt: 2 }}>
            <Stack
              direction="row"
              spacing={2}
            >
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={!state.name}
                fullWidth
              >
                Save Property
              </Button>
              {state.selectedDefinition && onDelete && (
                <IconButton
                  onClick={onDelete}
                  sx={{ flexShrink: 0 }}
                >
                  <IconTrash size={16} />
                </IconButton>
              )}
            </Stack>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4">Select a property type</Typography>
          <List>
            {Object.entries(objectPropertyDefinitions).map(
              ([key, value]) =>
                value.showUser && (
                  <ListItemButton
                    key={key}
                    onClick={() =>
                      dispatch({ type: 'SET_PROPERTY_TYPE', payload: key })
                    }
                  >
                    <ListItemIcon>
                      {React.createElement(value.icon)}
                    </ListItemIcon>
                    <ListItemText
                      primary={value.name}
                      secondary={value.description}
                      primaryTypographyProps={{
                        fontWeight: 600,
                        fontSize: '13px',
                        letterSpacing: '-0.3px',
                      }}
                      secondaryTypographyProps={{
                        fontSize: '12px',
                        color: 'text.secondary',
                      }}
                    />
                  </ListItemButton>
                )
            )}
          </List>
        </>
      )}
    </Stack>
  )
}

export default PropertyDefinitionForm
