import { useContext } from 'react'

import { MockedProvider } from '@apollo/client/testing'
import { Box, Paper, Typography } from '@mui/material'

import { GET_PERSON_TILE_FOR_AVATAR } from 'src/components/ContactTile/ContactTile'
import type { DayContextProps } from 'src/lib/dayContext'
import { DayContext } from 'src/lib/dayContext'

import MeetingRecordingActionItems, {
  GET_ACTIONS_FOR_MEETING_RECORDING,
} from './MeetingRecordingActionItems'

/**
 * Creates mock action data for testing
 * Includes all required fields that would be returned from the API
 */
const createMockAction = (overrides = {}) => ({
  id: 'action-1',
  workspaceId: 'mock-workspace-1',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  title: 'Follow up on pricing discussion',
  description: 'Send detailed pricing information for enterprise plan',
  reasoning: 'Client requested pricing details during the meeting',
  type: 'EMAIL',
  priority: 'HIGH',
  people: ['john@acme.com'],
  organizations: ['Acme Corp'],
  opportunityIds: ['opp-1'],
  pinnedFor: null,
  channel: {
    id: 'channel-1',
    label: 'Email',
    type: 'EMAIL',
    accountId: 'acc-1',
  },
  status: {
    id: 'status-1',
    label: 'Open',
    updatedAt: new Date().toISOString(),
  },
  assignedAt: new Date().toISOString(),
  owner: {
    id: 'user-1',
    email: 'internal@ourcompany.com',
  },
  draft: {
    title: 'Pricing Information',
    body: 'Hi John,\n\nFollowing up on our discussion...',
    prompts: {
      shortPrompt: 'Send pricing information',
    },
  },
  source: {
    label: 'Meeting',
    type: 'MEETING',
    id: 'source-1',
  },
  pipelineType: 'SALES',
  timeframe: {
    dueDate: new Date(Date.now() + 86400000).toISOString(),
    reminderDate: new Date(Date.now() + 43200000).toISOString(),
    updatedAt: new Date().toISOString(),
  },
  ...overrides,
})

const containerStyles = {
  maxWidth: 800,
  mx: 'auto',
  p: 3,
}

const paperStyles = {
  p: 3,
  mb: 4,
}

export const Stories = () => {
  const realContext = useContext(DayContext)

  const now = new Date()
  const timestamps = {
    threeMinutesAgo: new Date(now.getTime() - 3 * 60 * 1000),
    twoHoursAgo: new Date(now.getTime() - 2 * 60 * 60 * 1000),
  }

  // Create mocks for all scenarios
  const mocks = [
    // Scenario 1: Meeting with action
    {
      request: {
        query: GET_ACTIONS_FOR_MEETING_RECORDING,
        variables: {
          workspaceId: 'mock-workspace-1',
          meetingRecordingId: 'mock-meeting-1',
        },
      },
      result: {
        data: {
          actionsForMeetingRecording: [createMockAction()],
        },
      },
    },
    // Person data for avatar
    {
      request: {
        query: GET_PERSON_TILE_FOR_AVATAR,
        variables: {
          email: 'internal@ourcompany.com',
          workspaceId: 'mock-workspace-1',
        },
      },
      result: {
        data: {
          getPerson: {
            id: 'user-1',
            fullName: 'Internal User',
            photoUrl: null,
            currentJobTitle: 'Software Engineer',
          },
        },
      },
    },
    // Scenarios 2 & 3: No actions
    {
      request: {
        query: GET_ACTIONS_FOR_MEETING_RECORDING,
        variables: {
          workspaceId: 'mock-workspace-1',
          meetingRecordingId: 'mock-meeting-2',
        },
      },
      result: { data: { actionsForMeetingRecording: [] } },
    },
    {
      request: {
        query: GET_ACTIONS_FOR_MEETING_RECORDING,
        variables: {
          workspaceId: 'mock-workspace-1',
          meetingRecordingId: 'mock-meeting-3',
        },
      },
      result: { data: { actionsForMeetingRecording: [] } },
    },
  ]

  const mockContext: Partial<DayContextProps> = {
    selectedWorkspace: 'mock-workspace-1',
    internalDomains: ['ourcompany.com'],
    workspacePeople: [],
  }

  return (
    <Box sx={containerStyles}>
      <Typography
        variant="h4"
        gutterBottom
      >
        MeetingRecordingActionItems Stories
      </Typography>

      <MockedProvider
        mocks={mocks}
        addTypename={false}
      >
        <DayContext.Provider value={{ ...realContext, ...mockContext }}>
          <Paper
            sx={paperStyles}
            elevation={1}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              1. Actions Ready for Meeting
            </Typography>
            <MeetingRecordingActionItems
              meetingRecordingId="mock-meeting-1"
              readyAt={timestamps.twoHoursAgo}
              workspaceId="mock-workspace-1"
            />
          </Paper>

          <Paper
            sx={paperStyles}
            elevation={1}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              2. No Actions (Processing - Within One Hour)
            </Typography>
            <MeetingRecordingActionItems
              meetingRecordingId="mock-meeting-2"
              readyAt={timestamps.threeMinutesAgo}
              workspaceId="mock-workspace-1"
            />
          </Paper>

          <Paper
            sx={paperStyles}
            elevation={1}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              3. No Actions (Completed)
            </Typography>
            <MeetingRecordingActionItems
              meetingRecordingId="mock-meeting-3"
              readyAt={timestamps.twoHoursAgo}
              workspaceId="mock-workspace-1"
            />
          </Paper>
        </DayContext.Provider>
      </MockedProvider>
    </Box>
  )
}

export default Stories
