import { useMemo } from 'react'

import { Box } from '@mui/system'
import type {
  CreateWorkspaceUserContextInput,
  UpdateActionInput,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import InboxActionDetail from 'src/components/Inbox/InboxActionDetail'
import InboxSidebar from 'src/components/Inbox/InboxSidebar'
import { logger } from 'src/lib/logger'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'

const GET_ACTION_FOR_SIDEBAR = gql`
  query GetActionForSidebar($id: String!, $workspaceId: String!) {
    action(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      description
      reasoning
      type
      priority
      people
      organizations
      opportunityIds

      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
        prompts {
          shortPrompt
        }
      }
      source {
        label
        type
        id
        info {
          reasoning
          meetingRecording
          slack
          gmail
          foundSource
        }
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
      opportunity
    }
  }
`

const UPDATE_ACTION_FROM_DIALOG = gql`
  mutation UpdateActionFromDialog($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const ADD_CONTEXT_TO_ACTION_FROM_DIALOG = gql`
  mutation AddContextToActionFromDialog(
    $input: CreateWorkspaceUserContextInput!
  ) {
    createWorkspaceUserContext(input: $input) {
      id
    }
  }
`

const GET_PAGES_FOR_ACTION = gql`
  query GetPagesForAction(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
  ) {
    pagesForParentObject(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
    ) {
      id
      title
      objectId: id
      objectType: __typename
      properties: contentJson
    }
  }
`

const SidebarLayoutAction = ({ action, onClose }) => {
  const { data, refetch } = useQuery(GET_ACTION_FOR_SIDEBAR, {
    variables: { id: action.id, workspaceId: action.workspaceId },
    skip: !action.id || !action.workspaceId,
  })

  const { data: pagesData } = useQuery(GET_PAGES_FOR_ACTION, {
    variables: {
      objectType: NativeObjectTypes.Action,
      objectId: action.id,
      workspaceId: action.workspaceId,
    },
  })

  const [updateAction] = useMutation(UPDATE_ACTION_FROM_DIALOG)
  const [addContextToAction] = useMutation(ADD_CONTEXT_TO_ACTION_FROM_DIALOG)

  const handleUpdateAction = (input: UpdateActionInput) => {
    updateAction({ variables: { input } })
  }

  const handleAddContextToAction = async (
    input: CreateWorkspaceUserContextInput
  ) => {
    await addContextToAction({ variables: input })
  }

  const onUpdate = () => {
    refetch()
  }

  const mergedAction = useMemo(() => {
    if (!data?.action?.id) {
      if (action?.id) {
        return action
      }
      return null
    }
    const merged = deepMerge(action, data?.action)
    logger.dev({
      action,
      data: data?.action,
      merged,
    })
    return merged
  }, [action, data?.action])

  return (
    action && (
      <Box
        sx={{
          height: `100%`,
          width: '100%',
          overflowY: 'auto',
        }}
      >
        <Box sx={{ px: 3 }}>
          <InboxActionDetail
            selectedAction={mergedAction}
            refetch={() => {
              onUpdate()
            }}
            type={action?.type}
            updateAction={handleUpdateAction}
            onHide={onClose}
            showCrmObjects={true}
          />
        </Box>
        <InboxSidebar
          selectedAction={mergedAction}
          width={696}
          grid={true}
          addContext={handleAddContextToAction}
          refetch={() => {
            onUpdate()
          }}
          pages={pagesData?.pagesForParentObject || []}
        />
      </Box>
    )
  )
}

export default SidebarLayoutAction
