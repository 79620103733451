import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useReducer,
} from 'react'

import { Box, IconButton, lighten, Switch, Typography } from '@mui/material'
import { IconPlant, IconRefresh, IconX } from '@tabler/icons-react'
import ReactGA from 'react-ga4'

import { routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { addToRecentObjects } from 'src/lib/recentObjects'
import { isSidebarObject } from 'src/lib/types/sidebar'

import { PipelineProvider } from '../PipelineV2/PipelineDetail.tsx/PipelineContext'
import Row from '../Row/Row'

import SidebarHistory from './SidebarHistory/SidebarHistory'
import SidebarLayoutAction from './SidebarLayoutAction/SidebarLayoutAction'
import SidebarLayoutClip from './SidebarLayoutClip/SidebarLayoutClip'
import SidebarLayoutContact from './SidebarLayoutContact/SidebarLayoutContact'
import SidebarLayoutEvent from './SidebarLayoutEvent/SidebarLayoutEvent'
import SidebarLayoutGmailThread from './SidebarLayoutGmailThread/SidebarLayoutGmailThread'
import SidebarLayoutMeetingRecording from './SidebarLayoutMeetingRecording/SidebarLayoutMeetingRecording'
import SidebarLayoutOpportunity from './SidebarLayoutOpportunity/SidebarLayoutOpportunity'
import SidebarLayoutOrganization from './SidebarLayoutOrganization/SidebarLayoutOrganization'
import SidebarLayoutPage from './SidebarLayoutPage/SidebarLayoutPage'
import SidebarLayoutPipeline from './SidebarLayoutPipeline/SidebarLayoutPipeline'
import SidebarLayoutSlackChannel from './SidebarLayoutSlackChannel/SidebarLayoutSlackChannel'
import SidebarObjectConsole from './SidebarObjectConsole/SidebarObjectConsole'
import SidebarObjectCreate from './SidebarObjectCreate/SidebarObjectCreate'

/*
# Sidebar Navigation Test Requirements

## Test Case 1: Circular Navigation
User opens Object A sidebar → clicks Object B → clicks Object A
- Back arrow remains visible and functional
- Forward arrow not present
- Object chips remain clickable
- Back navigation shows correct history

## Test Case 2: Reciprocal Navigation
User opens Object B sidebar → clicks Object A → clicks Object B
- Back arrow remains visible and functional
- Forward arrow not present
- All chips remain clickable
- Navigation history preserved

## Test Case 3: Single Back Navigation
User opens Object A sidebar → clicks Object B chip → clicks back arrow
- Object B chip remains clickable on Object A sidebar
- All navigation arrows function correctly
- Navigation state preserved

## Test Case 4: Multiple Object Chain
User opens Object A sidebar → clicks Object B chip → clicks Object C chip → clicks back button
- Object C chip remains clickable on Object B sidebar
- Back/forward arrows appear appropriately
- Full navigation chain preserved

## Test Case 5: Multiple Back Navigation
User opens Object A sidebar → clicks Object B chip → clicks Object C chip → clicks back twice
- Object C chip remains clickable from Object A sidebar
- Navigation history maintained
- All object chips remain functional

## Test Case 6: Linear Chain Navigation
User opens Object A sidebar → clicks Object B chip → clicks Object C chip → clicks back → clicks back
- Each back click returns to previous state
- Forward/back arrows appear appropriately
- All chips remain clickable throughout

## Test Case 7: Bidirectional Arrow Navigation
User opens Object A sidebar → clicks Object B chip → clicks Object C chip
- Forward arrow navigates to next state
- Back arrow navigates to previous state
- All navigation states preserved
- All chips remain clickable

## Test Case 8: Full Circle Navigation
User opens Object A sidebar → clicks Object B chip → clicks Object C chip → clicks Object A chip → clicks Object B chip
- Complete navigation chain preserved
- All chips remain clickable
- Back/forward arrows appear appropriately
- Navigation history maintained in both directions

## Requirements for All Cases:
1. Navigation arrows must appear/disappear appropriately based on position in history
2. All object chips must remain clickable after navigation
3. Navigation history must be preserved in both directions
4. Back/forward navigation must restore correct sidebar state
5. Clicking any chip must preserve the navigation chain
6. Multiple navigation paths to the same object must work correctly

*/

const Ga4PageTitle = {
  [NativeObjectTypes.Contact]: 'Person',
  [NativeObjectTypes.Organization]: 'Organization',
  [NativeObjectTypes.Opportunity]: 'Opportunity',
  [NativeObjectTypes.Action]: 'Action',
  [NativeObjectTypes.Event]: 'Event',
  [NativeObjectTypes.GmailThread]: 'Gmail Thread',
  [NativeObjectTypes.MeetingRecording]: 'Meeting Recording',
  [NativeObjectTypes.Page]: 'Page',
  [NativeObjectTypes.Pipeline]: 'Pipeline',
  [NativeObjectTypes.MeetingRecordingClip]: 'Meeting Recording Clip',
}

const SUPPORTED_CONSOLE_OBJECT_TYPES = [
  //NativeObjectTypes.Person,
  NativeObjectTypes.Organization,
  NativeObjectTypes.Opportunity,
  //NativeObjectTypes.Action,

  //NativeObjectTypes.Event,
  //NativeObjectTypes.GmailThread,
  //NativeObjectTypes.MeetingRecording,
  //NativeObjectTypes.Page,
  //NativeObjectTypes.Pipeline,
]

type NavigationState = {
  history: any[]
  currentIndex: number
}

type NavigationAction =
  | { type: 'ADD_OBJECT'; payload: any }
  | { type: 'NAVIGATE_BACK' }
  | { type: 'NAVIGATE_FORWARD' }
  | { type: 'RESET' }

function navigationReducer(
  state: NavigationState,
  action: NavigationAction
): NavigationState {
  let newHistory: any[]

  switch (action.type) {
    case 'ADD_OBJECT':
      // Skip if we're already at this object
      if (
        state.history[state.currentIndex]?.objectId === action.payload?.objectId
      ) {
        return state
      }
      // Truncate history at current index and add new object
      newHistory = [
        ...state.history.slice(0, state.currentIndex + 1),
        action.payload,
      ]
      return {
        history: newHistory,
        currentIndex: state.currentIndex + 1,
      }
    case 'NAVIGATE_BACK':
      if (state.currentIndex > 0) {
        return { ...state, currentIndex: state.currentIndex - 1 }
      }
      return state
    case 'NAVIGATE_FORWARD':
      if (state.currentIndex < state.history.length - 1) {
        return { ...state, currentIndex: state.currentIndex + 1 }
      }
      return state
    case 'RESET':
      return { history: [], currentIndex: -1 }
    default:
      return state
  }
}

const USE_NEW_NAVIGATION = true // Feature flag for new navigation system

const Sidebar = ({
  isOpen = false,
  crmObject,
  setCrmObject,
  onClose = () => {},
  workspaceId,
}: {
  isOpen?: boolean
  crmObject: any
  setCrmObject: (crmObject: any) => void
  onClose?: () => void
  workspaceId?: string
}) => {
  const { currentUser } = useAuth()
  const [consoleOpen, setConsoleOpen] = useState(false)

  // Old navigation state
  const [objectHistory, setObjectHistory] = useState<any[]>([])
  const currentIndexRef = useRef(-1)
  const [_navigationCounter, setNavigationCounter] = useState(0) // Prefix with _ to indicate intentionally unused

  // New navigation state
  const [navigationState, dispatch] = useReducer(navigationReducer, {
    history: [],
    currentIndex: -1,
  })

  const { internalDomains, orgsByDomain } = useContext(DayContext)
  const [open, setOpen] = useState(isOpen)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const trackSidebarNavigation = useCallback(
    ({
      objectType,
      objectId,
    }: {
      objectType: NativeObjectType
      objectId: string
    }) => {
      const objectTypeSlug =
        ObjectTypeMetadata?.[objectType]?.slug || objectType
      if (!objectTypeSlug) {
        return
      }

      const path = routes.objectDetail({
        objectTypeSlug,
        objectId,
        workspaceId,
      })

      if (!path) {
        return
      }

      const payload = {
        hitType: 'pageview' as const,
        page: path,
        title: Ga4PageTitle[objectType],
        contentGroup1: 'Sidebar',
        userId: currentUser?.id,
      }

      logger.dev({ SidebarGA4: payload })
      if (path) {
        try {
          ReactGA.send(payload)
        } catch (e) {
          logger.warn('GA tracking error')
        }
      }
    },
    [currentUser?.id, workspaceId]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!open || !sidebarRef.current) return

      const dialogContainer = document.querySelector('.MuiDialog-container')
      if (dialogContainer) {
        return // No-op when dialog is present
      }

      const sidebarRect = sidebarRef.current.getBoundingClientRect()
      const clickX = event.clientX

      // If click is to the left of the sidebar
      if (clickX < sidebarRect.left) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  // This effect handles new object selection
  useEffect(() => {
    if (!open || !crmObject?.objectId) {
      return
    }

    trackSidebarNavigation({
      objectType: crmObject?.objectType,
      objectId: crmObject?.objectId,
    })

    const objectWithWorkspace = {
      ...crmObject,
      workspaceId: crmObject.workspaceId || workspaceId,
    }

    if (USE_NEW_NAVIGATION) {
      dispatch({ type: 'ADD_OBJECT', payload: objectWithWorkspace })
      setCrmObject(null)
      return
    }

    // Old navigation logic
    if (objectHistory.length === 0) {
      setObjectHistory([objectWithWorkspace])
      currentIndexRef.current = 0
      setNavigationCounter((prev) => prev + 1)
      setCrmObject(null)
      return
    }

    if (
      objectHistory[currentIndexRef.current]?.objectId ===
      objectWithWorkspace?.objectId
    ) {
      return
    }

    setObjectHistory((prev) => {
      const historyToKeep = prev.slice(0, currentIndexRef.current + 1)
      return [...historyToKeep, objectWithWorkspace]
    })
    currentIndexRef.current = currentIndexRef.current + 1
    setNavigationCounter((prev) => prev + 1)
    setCrmObject(null)
  }, [
    open,
    crmObject,
    workspaceId,
    trackSidebarNavigation,
    setCrmObject,
    objectHistory,
  ])

  const handleBack = useCallback(() => {
    if (USE_NEW_NAVIGATION) {
      dispatch({ type: 'NAVIGATE_BACK' })
      return
    }

    if (currentIndexRef.current > 0) {
      currentIndexRef.current--
      setNavigationCounter((prev) => prev + 1)
    }
  }, [])

  const handleForward = useCallback(() => {
    if (USE_NEW_NAVIGATION) {
      dispatch({ type: 'NAVIGATE_FORWARD' })
      return
    }

    if (currentIndexRef.current < objectHistory.length - 1) {
      currentIndexRef.current++
      setNavigationCounter((prev) => prev + 1)
    }
  }, [objectHistory.length])

  const currentObject = useMemo(() => {
    if (USE_NEW_NAVIGATION) {
      return navigationState.history[navigationState.currentIndex]
    }
    return objectHistory[currentIndexRef.current]
  }, [navigationState, objectHistory])

  const hasBack = USE_NEW_NAVIGATION
    ? navigationState.currentIndex > 0
    : currentIndexRef.current > 0

  const hasForward = USE_NEW_NAVIGATION
    ? navigationState.currentIndex < navigationState.history.length - 1
    : currentIndexRef.current < objectHistory.length - 1

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!open) {
      if (USE_NEW_NAVIGATION) {
        dispatch({ type: 'RESET' })
      } else {
        setObjectHistory([])
        currentIndexRef.current = -1
      }
      onClose()
      setConsoleOpen(false)
    }
  }, [open, onClose])

  useEffect(() => {
    if (
      crmObject?.objectId &&
      !internalDomains.includes(crmObject?.properties?.domain)
    ) {
      setOpen(true)
    }
  }, [crmObject, internalDomains])

  // Add object to recent list when sidebar opens with new object
  useEffect(() => {
    if (crmObject?.objectId && open) {
      addToRecentObjects(crmObject, workspaceId, currentUser?.id)
    }
  }, [crmObject, open, workspaceId, currentUser?.id])

  const history = {
    hasBack,
    hasForward,
    handleBack,
    handleForward,
    objectHistory: USE_NEW_NAVIGATION ? navigationState.history : objectHistory,
    currentIndex: USE_NEW_NAVIGATION
      ? navigationState.currentIndex
      : currentIndexRef.current,
  }

  const [lastConsoleFetch, setLastConsoleFetch] = useState(new Date().getTime())

  if (currentObject && !currentObject?.workspaceId) {
    currentObject.workspaceId = workspaceId
  }

  const containerSx = useMemo(
    () => ({
      position: 'fixed',
      zIndex: 1000,
      background: 'white',
      width: open ? `${8 * 90}px` : 0,
      height: '100vh',
      overflowY: 'auto',
      top: 0,
      right: 0,
      p: 0,
      m: 0,
      boxShadow: `
    0 1px 2px rgba(136, 161, 183, 0.07),
    0 2px 4px rgba(136, 161, 183, 0.07),
    0 4px 8px rgba(136, 161, 183, 0.07),
    0 8px 16px rgba(136, 161, 183, 0.07),
    0 16px 32px rgba(136, 161, 183, 0.07),
    0 32px 64px rgba(136, 161, 183, 0.07)`,
      borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
      //transition: 'all 0.4s ease',
      '& .sidebar-outer': {
        opacity: open ? 1 : 0,
        //filter: open ? 'saturate(100%)' : 'saturate(0%)',
        //transition: 'all 1.2s ease',
        height: '100%',
        overflow: 'hidden',
      },
      '& .sidebar-navigation': {
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: (theme) =>
          consoleOpen ? 'none' : `1px solid ${theme.palette.divider}`,
        height: '56px',
        px: '12px',
        backgroundColor: consoleOpen ? lighten('#1C1B22', 0.0) : 'transparent',
      },
      '& .sidebar-content': {
        height: 'calc(100% - 56px)',
        overflow: 'hidden',
      },
    }),
    [open, consoleOpen]
  )

  return (
    <Box
      ref={sidebarRef}
      sx={containerSx}
    >
      <Box className="sidebar-outer">
        <Row className="sidebar-navigation">
          {consoleOpen ? (
            <Row
              sx={{
                color: (theme) => lighten(theme.palette.secondary.main, 0.3),
              }}
            >
              <IconPlant
                size={16}
                className="mr-1"
              />
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 600,
                  color: (theme) => lighten(theme.palette.secondary.main, 0.3),
                  letterSpacing: '-0.3px',
                }}
              >
                Learning
              </Typography>
              <IconButton
                onClick={() => {
                  const now = new Date().getTime()
                  logger.dev('refresh', { now })
                  setLastConsoleFetch(now)
                }}
              >
                <IconRefresh
                  size={14}
                  color="#EDECEE"
                />
              </IconButton>
            </Row>
          ) : (
            <SidebarHistory history={history} />
          )}
          <Row gap={1}>
            {SUPPORTED_CONSOLE_OBJECT_TYPES.includes(
              currentObject?.objectType
            ) &&
              isSidebarObject(currentObject) &&
              currentObject.mode !== 'create' && (
                <Switch
                  checked={consoleOpen}
                  onChange={() => setConsoleOpen(!consoleOpen)}
                  sx={{
                    '& .MuiSwitch-thumb': {
                      boxShadow: 'none',
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14px',
                        letterSpacing: '-0.6px',
                        color: (theme) => theme.palette.text.secondary,
                        backgroundImage: (theme) =>
                          `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="${encodeURIComponent(theme.palette.text.primary)}" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M7 8l-4 4l4 4"></path><path d="M17 8l4 4l-4 4"></path><path d="M14 4l-4 16"></path></svg>')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.6,
                      },
                    },
                    '& .Mui-checked': {
                      '& .MuiSwitch-thumb': {
                        border: (theme) =>
                          `1px solid ${theme.palette.text.primary}`,
                        '&:before': {
                          opacity: 0.8,
                          backgroundImage: (theme) =>
                            `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="${encodeURIComponent(theme.palette.background.paper)}" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M7 8l-4 4l4 4"></path><path d="M17 8l4 4l-4 4"></path><path d="M14 4l-4 16"></path></svg>')`,
                        },
                      },
                    },
                  }}
                />
              )}

            <IconButton
              sx={{
                height: '32px',
                width: '32px',
                mr: '3px',
                color: consoleOpen ? 'white' : 'inherit',
                opacity: consoleOpen ? 0.7 : 1,
              }}
              onClick={() => setOpen(false)}
            >
              <IconX
                size={24}
                style={{
                  flexShrink: 0,
                }}
              />
            </IconButton>
          </Row>
        </Row>
        {consoleOpen ? (
          <SidebarObjectConsole
            key={lastConsoleFetch}
            objectType={currentObject?.objectType}
            objectId={currentObject?.objectId}
            workspaceId={currentObject?.workspaceId}
          />
        ) : (
          currentObject && (
            <Box className="sidebar-content">
              {['create', 'edit'].includes(currentObject.mode) ? (
                <>
                  <SidebarObjectCreate
                    objectType={currentObject.objectType}
                    mode={currentObject.mode}
                    workspaceId={currentObject.workspaceId}
                    initialData={{
                      ...currentObject.properties,
                      selectedOrganization: currentObject.properties?.domain
                        ? orgsByDomain[currentObject.properties.domain]
                        : null,
                      title:
                        currentObject.properties?.title ||
                        currentObject.properties?.domain
                          ? orgsByDomain?.[currentObject.properties.domain]
                              ?.name
                          : null,
                      selectedOwner: currentUser,
                    }}
                    onComplete={() => setOpen(false)}
                  />
                </>
              ) : (
                <>
                  {currentObject?.objectId &&
                    currentObject?.objectType === NativeObjectTypes.Contact && (
                      <SidebarLayoutContact
                        crmObject={currentObject}
                        setOpen={setOpen}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.Organization && (
                      <SidebarLayoutOrganization
                        domain={
                          currentObject.objectId ||
                          currentObject?.properties?.domain
                        }
                        setOpen={setOpen}
                        workspaceId={workspaceId}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.Opportunity && (
                      <SidebarLayoutOpportunity
                        crmObject={currentObject}
                        setOpen={setOpen}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType === NativeObjectTypes.Action && (
                      <SidebarLayoutAction
                        action={currentObject?.properties}
                        onClose={() => setOpen(false)}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType === NativeObjectTypes.Event && (
                      <SidebarLayoutEvent
                        workspaceId={currentObject?.workspaceId}
                        crmObject={currentObject}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.GmailThread && (
                      <SidebarLayoutGmailThread crmObject={currentObject} />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.SlackChannel && (
                      <SidebarLayoutSlackChannel crmObject={currentObject} />
                    )}
                  {/* DEPRECATED */}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.MeetingRecording && (
                      <SidebarLayoutMeetingRecording
                        crmObject={currentObject}
                        setOpen={setOpen}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType === NativeObjectTypes.Page && (
                      <SidebarLayoutPage
                        crmObject={currentObject}
                        onClose={() => setOpen(false)}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.MeetingRecordingClip && (
                      <SidebarLayoutClip
                        crmObject={currentObject}
                        setOpen={setOpen}
                      />
                    )}
                  {currentObject?.objectId &&
                    currentObject?.objectType ===
                      NativeObjectTypes.Pipeline && (
                      <PipelineProvider
                        workspaceId={currentObject?.workspaceId}
                        pipelineId={currentObject?.objectId}
                      >
                        <SidebarLayoutPipeline setOpen={setOpen} />
                      </PipelineProvider>
                    )}
                </>
              )}
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

export default Sidebar
