import React, {
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useRef,
} from 'react'

import type { GridState } from '@mui/x-data-grid-premium'
import _, { debounce } from 'lodash'
import type { UpdateViewInput, CreateViewInput, View } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import { logger } from 'src/lib/logger'

import ViewsContext from './ViewsContext'

const VIEWS_QUERY = gql`
  query ViewsQuery($objectType: String!, $workspaceId: String!) {
    views(objectType: $objectType, workspaceId: $workspaceId) {
      id
      title
      description
      gridState
      createdAt
      updatedAt
      creatorId
      position
      shareWithWorkspace
    }
  }
`

const CREATE_VIEW_MUTATION = gql`
  mutation CreateViewMutation($input: CreateViewInput!) {
    createView(input: $input) {
      id
      title
      description
      gridState
      position
      createdAt
      updatedAt
      creatorId
      shareWithWorkspace
    }
  }
`

const UPDATE_VIEW_MUTATION = gql`
  mutation UpdateViewMutation($id: String!, $input: UpdateViewInput!) {
    updateView(id: $id, input: $input) {
      id
      title
      description
      gridState
      position
    }
  }
`

const DELETE_VIEW_MUTATION = gql`
  mutation DeleteViewMutation($id: String!, $workspaceId: String!) {
    deleteView(id: $id, workspaceId: $workspaceId) {
      id
    }
  }
`

const SHARE_VIEW_MUTATION = gql`
  mutation ShareViewMutation(
    $id: String!
    $sharingTargetId: String!
    $sharingType: String!
  ) {
    shareView(
      id: $id
      sharingTargetId: $sharingTargetId
      sharingType: $sharingType
    ) {
      id
    }
  }
`

const UPDATE_VIEW_PIN_MUTATION = gql`
  mutation UpdateViewPinMutation(
    $id: String!
    $workspaceId: String!
    $position: Int
    $objectType: String!
  ) {
    updateViewPin(
      id: $id
      workspaceId: $workspaceId
      position: $position
      objectType: $objectType
    )
  }
`

interface ViewsProviderProps {
  children: React.ReactNode
  objectType: string
  workspaceId: string
  getExternalGridState: () => GridState
  setExternalGridState: (gridState: GridState) => void
  lastGridChangeAt: number
  setInitialized: () => void
  lastUserInteraction: number
  onSave: () => void
}

// Define the initial state and reducer function
const initialState = {
  views: [],
  pinnedViews: [],
  currentView: null,
}

function viewsReducer(state, action) {
  switch (action.type) {
    case 'SET_VIEWS': {
      const pinnedViews = action.payload
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: action.payload,
        pinnedViews,
        currentView:
          state.currentView || (pinnedViews.length > 0 ? pinnedViews[0] : null),
      }
    }
    case 'ADD_VIEW': {
      const newViews = [...state.views, action.payload]
      const pinnedViews = newViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: newViews,
        pinnedViews,
        currentView: action.payload,
      }
    }
    case 'SET_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.payload,
      }
    case 'REMOVE_VIEW': {
      const updatedViews = state.views.filter(
        (view) => view.id !== action.payload
      )
      return {
        ...state,
        views: updatedViews,
        currentView:
          state.currentView?.id === action.payload ? null : state.currentView,
      }
    }
    default:
      return state
  }
}

const ViewsProvider: React.FC<ViewsProviderProps> = ({
  children,
  objectType,
  workspaceId,
  getExternalGridState,
  setExternalGridState,
  lastGridChangeAt,
  setInitialized,
  lastUserInteraction,
  onSave,
}) => {
  const [state, dispatch] = useReducer(viewsReducer, initialState)
  const {
    data: viewsData,
    loading,
    error,
    refetch,
  } = useQuery(VIEWS_QUERY, {
    variables: { objectType, workspaceId },
    skip: !objectType || !workspaceId,
  })

  const initializedFromDataRef = useRef(false)

  // Pure calculation of pinned views
  const pinnedViews = useMemo(() => {
    return [...state.views]
      .filter((view) => typeof view.position === 'number')
      .sort((a, b) => a.position - b.position)
  }, [state.views]) // Much simpler dependency array now

  // Separate initialization effect
  useEffect(() => {
    if (!initializedFromDataRef.current && viewsData?.views) {
      const initialPinnedViews = viewsData.views
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      dispatch({
        type: 'SET_VIEWS',
        payload: viewsData.views,
      })

      // Initialize with first pinned view if we have one
      if (initialPinnedViews.length > 0 && !state.currentView) {
        dispatch({
          type: 'SET_CURRENT_VIEW',
          payload: initialPinnedViews[0],
        })
        setExternalGridState(
          initialPinnedViews[0].gridState as unknown as GridState
        )
      }

      initializedFromDataRef.current = true
      setInitialized()
    }
  }, [viewsData, state.currentView, setExternalGridState, setInitialized])

  const [createViewMutation, { loading: createViewLoading }] =
    useMutation(CREATE_VIEW_MUTATION)
  const [updateViewMutation, { loading: updateViewLoading }] =
    useMutation(UPDATE_VIEW_MUTATION)
  const [deleteViewMutation, { loading: deleteViewLoading }] =
    useMutation(DELETE_VIEW_MUTATION)
  const [shareViewMutation, { loading: shareViewLoading }] =
    useMutation(SHARE_VIEW_MUTATION)
  const [updateViewPinMutation, { loading: updateViewPinLoading }] =
    useMutation(UPDATE_VIEW_PIN_MUTATION)

  const saving = useMemo(() => {
    return (
      updateViewLoading ||
      createViewLoading ||
      deleteViewLoading ||
      shareViewLoading ||
      updateViewPinLoading
    )
  }, [
    updateViewLoading,
    createViewLoading,
    deleteViewLoading,
    shareViewLoading,
    updateViewPinLoading,
  ])

  const createView = useCallback(
    async (input: CreateViewInput) => {
      try {
        input.gridState = getExternalGridState() as any
        delete (input.gridState as any).preferencePanel
        const response = await createViewMutation({
          variables: {
            input: {
              ...input,
              workspaceId,
              position: input.position ?? pinnedViews.length,
            },
          },
          onCompleted: (data) => {
            logger.dev('View created successfully:', data)
            if (data?.createView) {
              // Ensure the position is set before dispatching
              const newView = {
                ...data.createView,
                position: input.position ?? pinnedViews.length,
              }
              dispatch({ type: 'ADD_VIEW', payload: newView })
            }
          },
          onError: (error) => {
            logger.error('Failed to create view:', error)
          },
        })
        return response.data?.createView
      } catch (error) {
        logger.error('Error creating view:', error)
        throw error
      }
    },
    [createViewMutation, workspaceId, getExternalGridState, pinnedViews.length]
  )

  const updateView = useCallback(
    async ({
      id,
      input,
      views,
    }: {
      id: string
      input: UpdateViewInput
      views: View[]
    }) => {
      try {
        logger.dev('🔄 updateView called with input:', input)
        input.gridState = getExternalGridState() as any
        delete (input.gridState as any).preferencePanel
        input.workspaceId = workspaceId

        logger.dev('🚀 Making updateViewMutation call')
        const response = await updateViewMutation({
          variables: { id, input },
        })
        logger.dev('✅ updateViewMutation completed')

        logger.dev('📊 Updating views state after mutation')
        dispatch({
          type: 'SET_VIEWS',
          payload: views.map((view) =>
            view.id === id ? { ...view, ...input } : view
          ),
        })

        onSave()
        return response.data?.updateView
      } catch (error) {
        logger.error('Error updating view:', error)
        throw error
      }
    },
    [updateViewMutation, workspaceId, getExternalGridState, onSave]
  )

  const debouncedHandleExternalGridChange = useMemo(
    () =>
      debounce(async () => {
        if (lastUserInteraction === 0) {
          return
        }

        // Get current state values inside the debounced function
        const currentView = state.currentView
        const currentViews = state.views

        if (!currentView?.id) return

        const currentGridState = getExternalGridState()

        if (_.isEqual(currentView?.gridState, currentGridState)) {
          return
        }
        await updateView({
          id: currentView.id,
          views: currentViews,
          input: {
            workspaceId,
            gridState: currentGridState as any,
            title: currentView.title,
            description: currentView.description,
            shareWithWorkspace: currentView.shareWithWorkspace,
          },
        })
      }, 100),
    [
      workspaceId,
      updateView,
      getExternalGridState,
      lastUserInteraction,
      state.currentView,
      state.views,
    ]
  )

  useEffect(() => {
    if (state.currentView?.id) {
      debouncedHandleExternalGridChange()
    }

    return () => {
      debouncedHandleExternalGridChange.cancel()
    }
  }, [
    lastGridChangeAt,
    debouncedHandleExternalGridChange,
    state?.currentView?.id,
  ])

  const setCurrentView = useCallback((view) => {
    dispatch({ type: 'SET_CURRENT_VIEW', payload: view })
  }, [])

  const deleteView = useCallback(
    async (viewId: string, position?: number) => {
      if (!viewId) return

      try {
        // First, find the next view to select
        const currentIndex =
          position ?? pinnedViews.findIndex((v) => v.id === viewId)
        const remainingViews = pinnedViews.filter((v) => v.id !== viewId)

        // Try to select the next view in this order: same position, previous position, next position
        const nextView =
          remainingViews[currentIndex] ||
          remainingViews[currentIndex - 1] ||
          remainingViews[0]

        // Update state in a single dispatch
        dispatch({ type: 'REMOVE_VIEW', payload: viewId })

        // Only update current view if we're deleting the current view
        if (state.currentView?.id === viewId && nextView) {
          dispatch({ type: 'SET_CURRENT_VIEW', payload: nextView })
          setExternalGridState(nextView.gridState as unknown as GridState)
        }

        // Then handle the server-side deletion
        await deleteViewMutation({
          variables: { id: viewId, workspaceId },
          refetchQueries: [
            { query: VIEWS_QUERY, variables: { objectType, workspaceId } },
          ],
        })
      } catch (error) {
        logger.error('Error deleting view:', error)
        throw error
      }
    },
    [
      deleteViewMutation,
      objectType,
      workspaceId,
      pinnedViews,
      state.currentView,
      setExternalGridState,
    ]
  )

  const shareView = useCallback(
    async (id: string, userId: string) => {
      try {
        const response = await shareViewMutation({
          variables: { id, userId },
          refetchQueries: [{ query: VIEWS_QUERY, variables: { objectType } }],
        })
        return response.data?.shareView
      } catch (error) {
        logger.error('Error sharing view:', error)
        throw error
      }
    },
    [shareViewMutation, objectType]
  )

  const removePin = useCallback(
    async (id: string) => {
      try {
        const unpinnedView = state.views.find((v) => v.id === id)
        if (!unpinnedView) return

        // Find the next best view to select
        let newCurrentView = null
        const remainingPinnedViews = pinnedViews.filter((v) => v.id !== id)
        dispatch({ type: 'SET_VIEWS', payload: remainingPinnedViews })

        if (remainingPinnedViews.length > 0) {
          // If unpinning current view, select the previous pinned view if it exists, otherwise the next one
          if (unpinnedView.position !== undefined) {
            newCurrentView =
              remainingPinnedViews.find(
                (v) => v.position === unpinnedView.position - 1
              ) ||
              remainingPinnedViews.find(
                (v) => v.position === unpinnedView.position + 1
              ) ||
              remainingPinnedViews[0]
          }
        }

        dispatch({ type: 'SET_CURRENT_VIEW', payload: newCurrentView })

        const response = await updateViewPinMutation({
          variables: { id, workspaceId, position: undefined, objectType },
          refetchQueries: [
            {
              query: VIEWS_QUERY,
              variables: { objectType, workspaceId },
              fetchPolicy: 'no-cache',
            },
          ],
        })
        //await refetch()
        return response.data?.updateViewPin
      } catch (error) {
        logger.error('Error removing pin:', error)
        throw error
      }
    },
    [
      updateViewPinMutation,
      pinnedViews,
      objectType,
      workspaceId,
      state.views,
      //refetch,
    ]
  )

  const addPin = useCallback(
    async (id: string) => {
      const position = pinnedViews.length

      dispatch({
        type: 'SET_CURRENT_VIEW',
        payload: { ...state.views.find((v) => v.id === id), position },
      })
      dispatch({
        type: 'SET_VIEWS',
        payload: [
          ...state.views,
          { ...state.views.find((v) => v.id === id), position },
        ],
      })
      const response = await updateViewPinMutation({
        variables: {
          id,
          workspaceId,
          position,
          objectType,
        },
        refetchQueries: [
          {
            query: VIEWS_QUERY,
            variables: { objectType, workspaceId },
            fetchPolicy: 'no-cache',
          },
        ],
      })
      await refetch()
      return response.data?.updateViewPin
    },
    [
      updateViewPinMutation,
      pinnedViews,
      state.views,
      objectType,
      workspaceId,
      refetch,
    ]
  )

  const value = useMemo(() => {
    return {
      views: state.views,
      pinnedViews,
      getExternalGridState,
      setExternalGridState,
      loading,
      saving,
      error,
      createView,
      updateView,
      deleteView,
      shareView,
      removePin,
      addPin,
      setCurrentView,
      currentView: state.currentView,
      objectType,
      workspaceId,
      refetchViews: refetch,
    }
  }, [
    state.views,
    pinnedViews,
    getExternalGridState,
    setExternalGridState,
    loading,
    saving,
    error,
    createView,
    updateView,
    deleteView,
    shareView,
    removePin,
    addPin,
    setCurrentView,
    state.currentView,
    objectType,
    workspaceId,
    refetch,
  ])

  return <ViewsContext.Provider value={value}>{children}</ViewsContext.Provider>
}

export default ViewsProvider
