import { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { Box } from '@mui/material'
import { EditorContent, useEditor } from '@tiptap/react'
import Ai from '@tiptap-pro/extension-ai-advanced'

import { logger } from 'src/lib/logger'

import { extensionsEmbeddedAi } from '../extensions'

const systemPrompt = `<system>
  You are an the world's best personal assistant, brilliantly proficient in effective, direct business communication, focused always on the details that matter in interpersonal relationships.
  You are an expert in using CRM data to connect the dots for your user, and diligently follow their instructions.
  Use only h3 and smaller headings. Do not write or include page titles for your responses. Return the raw inner HTML with just your response, no other text, introductory or otherwise, only string that contains one or more HTML tags.
  </system>`

const AiEditorComponent = ({
  token,
  prompt,
  onSuccess,
  contextString,
}: {
  token: string
  prompt: string
  onSuccess: (result: any) => void
  contextString: string
}) => {
  const memoizedOnSuccess = useCallback(onSuccess, [onSuccess])
  const memoizedPrompt = useMemo(() => prompt, [prompt])
  const memoizedContextString = useMemo(() => contextString, [contextString])
  const memoizedToken = useMemo(() => token, [token])

  const aiInitialized = useRef(false)
  const successCalled = useRef(false)

  const editor = useEditor({
    extensions: [
      ...extensionsEmbeddedAi,
      Ai.configure({
        appId: process.env.TIPTAP_APP_ID,
        token: memoizedToken,
        autocompletion: true,

        onSuccess: (result) => {
          if (successCalled.current) {
            logger.dev('Success already called, skipping')
            return
          }
          successCalled.current = true
          logger.dev('AiEditor onSuccess called', { result })
          memoizedOnSuccess(result)
        },
        aiStreamResolver: async ({ action, text, textOptions }) => {
          logger.dev('aiStreamResolver called', { action, text, textOptions })
          const fetchOptions = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              ...textOptions,
              text,
            }),
          }

          const response = await fetch(
            `https://f5gegaoxtd4kuyhc35q62wdyoq0ciwqh.lambda-url.us-east-1.on.aws/`,
            fetchOptions
          )

          return response?.body
        },
      }),
    ],
    autofocus: false,
    editable: false,
  })

  useEffect(() => {
    const initializeAi = async () => {
      if (!editor || !memoizedPrompt) {
        logger.dev('Editor or prompt not found, skipping AI initialization')
        return
      }

      successCalled.current = false

      try {
        logger.dev('Starting AI population', {
          token: memoizedToken,
          prompt: memoizedPrompt,
          contextString: memoizedContextString,
        })

        editor
          .chain()
          .aiTextPrompt({
            text: `${systemPrompt}\n\n<instructions>${memoizedPrompt}</instructions>`,
            stream: true,
            insert: true,
            format: 'rich-text',
            context: [
              {
                type: 'text',
                text: `<context>${memoizedContextString}</context>`,
              },
            ],
          })
          .run()
      } catch (error) {
        logger.error('AI initialization failed:', error)
      }
    }

    if (
      !aiInitialized.current &&
      editor &&
      memoizedPrompt &&
      memoizedToken &&
      memoizedContextString
    ) {
      aiInitialized.current = true
      initializeAi()
    }
  }, [editor, memoizedPrompt, memoizedContextString, memoizedToken])

  useEffect(() => {
    aiInitialized.current = false
    successCalled.current = false
  }, [memoizedPrompt, memoizedToken])

  const containerSx = {
    width: '100%',
  }

  return (
    <Box sx={containerSx}>
      <div className="editor-tiptap-inner">
        <EditorContent editor={editor} />
      </div>
    </Box>
  )
}

const AiEditor = memo(AiEditorComponent, (previous, next) => {
  return (
    previous.token === next.token &&
    previous.prompt === next.prompt &&
    previous.contextString === next.contextString
  )
})

AiEditor.displayName = 'AiEditor'

export default AiEditor
