import { createContext } from 'react'

import type { GridState } from '@mui/x-data-grid-premium'
import type { CreateViewInput, UpdateViewInput, View } from 'types/graphql'

interface ViewsContextType {
  views: View[]
  pinnedViews: View[]
  loading: boolean
  saving: boolean
  error?: Error
  createView: (input: CreateViewInput) => Promise<View>
  updateView: ({
    id,
    input,
  }: {
    id: string
    input: UpdateViewInput
  }) => Promise<View>
  deleteView: (id: string, position?: number) => Promise<void>
  shareView: (id: string, userId: string) => Promise<View>
  removePin: (id: string) => Promise<View>
  addPin: (id: string) => Promise<View>
  setCurrentView: (view: View) => void
  currentView: View | null
  objectType: string
  workspaceId: string
  getExternalGridState: () => GridState
  setExternalGridState: (gridState: GridState) => void
  refetchViews: () => void
}

const ViewsContext = createContext<ViewsContextType | undefined>(undefined)

export default ViewsContext
