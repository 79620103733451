import { IconButton, Typography } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import {
  RiAlignJustify,
  RiDownloadCloudLine,
  RiFilter3Line,
  RiSearchLine,
} from '@remixicon/react'
import { IconBrandLinkedin } from '@tabler/icons-react'
import LayoutColumnLineIcon from 'remixicon-react/LayoutColumnLineIcon'

import Row from 'src/components/Row/Row'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { NativeObjectTypes } from 'src/lib/objects'
import { getRelationshipSummaryTypeMetadataFromKey } from 'src/lib/relationshipSummary'

import ObjectChip from '../Chips/ObjectChip/ObjectChip'
import ContactAvatar from '../ContactAvatar/ContactAvatar'
import RelationshipTypeChip from '../Relationships/RelationshipTypeChip/RelationshipTypeChip'

export const getDataTableRenderers = () => {
  return {
    headers: {},
    cells: {
      photoUrl: (params) => {
        return (
          <ContactAvatar
            email={params?.row?.email}
            size={28}
          />
        )
      },
      companyName: (params) => {
        return (
          <Row sx={{ width: '100%', height: '100%' }}>
            <ObjectChip
              workspaceId={params.row.workspaceId}
              crmObject={{
                ...params.row,
                objectType: NativeObjectTypes.Organization,
                objectId: extractEmailDomain(params.row?.email),
                properties: {},
              }}
            />
          </Row>
        )
      },
      relationshipType: (params) => {
        return <RelationshipTypeChip type={params.row?.relationship?.type} />
      },
      linkedInUrl: (params) => {
        return (
          params.row?.linkedInUrl && (
            <Row
              sx={{ justifyContent: 'center', width: '100%', height: '100%' }}
            >
              <IconButton
                sx={{
                  p: '3px',
                  borderRadius: '4px',
                  color: '#2867B2',
                  '&:hover': {
                    color: '#2867B2',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(params.row?.linkedInUrl, '_blank')
                }}
              >
                <IconBrandLinkedin size={20} />
              </IconButton>
            </Row>
          )
        )
      },
    },
  }
}

const standardWidth = 16

export const getDefaultColumnsContact = ({ DataTableRenderers }) => {
  return [
    {
      field: 'person',
      headerName: 'Person',
      flex: 0,
      width: standardWidth * 14,
      editable: false,
      valueGetter: (value, row) => {
        return row.fullName || row.email || row.id
      },
      renderCell: (params) => (
        <ObjectChip
          workspaceId={params.row.workspaceId}
          crmObject={params.row}
        />
      ),
      groupable: false,
    },
    {
      field: 'companyName',
      headerName: 'Organization',
      width: standardWidth * 14,
      editable: false,
      renderCell: DataTableRenderers.cells.companyName,
      valueGetter: (value, row) => {
        return extractEmailDomain(row?.id)
      },
    },
    {
      field: 'currentJobTitle',
      headerName: 'Title',
      flex: 0,
      width: standardWidth * 10,
      editable: true,
      propertyPath: 'currentJobTitle',
      type: 'string',
    },
    {
      field: 'linkedInUrl',
      headerName: '',
      flex: 0,
      width: standardWidth * 1,
      editable: false,
      renderCell: DataTableRenderers.cells.linkedInUrl,
    },
    {
      field: 'relationshipSummary/oneSentence',
      headerName: 'Current Status',
      flex: 1,
      width: standardWidth * 14,
      editable: false,
      groupable: false,
      sortable: false,
      valueGetter: (value, row) => {
        return row.relationship?.oneSentence
      },
    },
    {
      field: 'relationshipSummary/type',
      headerName: 'Type',
      width: 200,
      editable: false,
      renderCell: DataTableRenderers.cells.relationshipType,
      valueGetter: (value, row) => {
        const typeObject = getRelationshipSummaryTypeMetadataFromKey(
          row['relationshipSummary/type']
        )
        return typeObject?.label || ''
      },
    },
    {
      field: 'email',
      headerName: '',
      flex: 0,
      width: standardWidth * 14,
      editable: false,
    },
  ]
}

export const DataGridToolbar = (props) => {
  const iconStyle = {
    width: '16px',
    height: '16px',
    fontWeight: 500,
    flexShrink: 0,
  }
  return (
    <GridToolbarContainer className="data-grid-toolbar">
      <Row
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          height: '64px !important',
          '& .MuiButton-root': {
            mr: 3,
            px: 0,
          },
        }}
      >
        <Row>
          {props.title && (
            <Typography
              variant="h2"
              sx={{ pl: props.titleMargin || 0, pr: 2 }}
            >
              {props.title}
            </Typography>
          )}
          {props.customFilters && React.cloneElement(props.customFilters)}
          {props.showColumnFilter && (
            <GridToolbarColumnsButton
              startIcon={<LayoutColumnLineIcon style={iconStyle} />}
            />
          )}
          {props.showFilter && (
            <GridToolbarFilterButton
              componentsProps={{
                button: {
                  startIcon: <RiFilter3Line style={iconStyle} />,
                },
              }}
            />
          )}
          {props.showDensitySelector && (
            <GridToolbarDensitySelector
              startIcon={<RiAlignJustify style={iconStyle} />}
            />
          )}
          {props.showExport && (
            <GridToolbarExport
              csvOptions={props.csvOptions}
              startIcon={<RiDownloadCloudLine style={iconStyle} />}
            />
          )}
        </Row>
        {props.showQuickFilter && (
          <GridToolbarQuickFilter
            InputProps={{
              startAdornment: <RiSearchLine size={16} />,
              placeholder: 'Search ...',
              disableUnderline: true,
              sx: {
                m: 0,
                p: 0,
                fontSize: '0.8rem',
                fontWeight: 500,
                '& .MuiInput-input': {
                  padding: 0,
                  fontSize: '0.8rem',
                  fontWeight: 500,
                },
                '& .MuiInputBase-adornedStart': {
                  marginRight: '4px',
                  marginLeft: 0,
                },
              },
            }}
          />
        )}
      </Row>
    </GridToolbarContainer>
  )
}

export const StandardDataTableSx = {
  '.MuiDataGrid-cell': {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '-0.4px',
    color: (theme) => theme.palette.text.secondary,
    cursor: 'pointer',
  },
  '& .MuiDataGrid-toolbarContainer': {
    border: 'none',
    p: 0,
  },
  '.MuiDataGrid-main': {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
}
