import type { ActionPromptOutput } from 'types/graphql'

import Row from '../Row/Row'

import SuggestedPromptChip from './SuggestedPromptChip'

const SuggestedPromptRow = ({
  prompts,
  onAddPrompt,
  hide,
}: {
  prompts: ActionPromptOutput[]
  onAddPrompt: (prompt: ActionPromptOutput) => void
  hide?: boolean
}) => {
  return (
    <Row
      gap={1}
      sx={{
        flexWrap: 'wrap',
        width: '100%',
        '& .MuiChip-root': {
          maxWidth: 'auto',
        },
      }}
    >
      {(prompts || []).map((prompt) => (
        <SuggestedPromptChip
          prompt={prompt}
          key={prompt.shortPrompt}
          onAddPrompt={onAddPrompt}
          hide={hide}
        />
      ))}
    </Row>
  )
}

export default SuggestedPromptRow
