import { useContext, useMemo, useState } from 'react'

import {
  Box,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  IconBubbleText,
  IconCircleFilled,
  IconColumns3,
  IconTrophy,
} from '@tabler/icons-react'

import ActionCreateDialog from 'src/components/Actions/ActionCreateDialog/ActionCreateDialog'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import ContextEditSidebar from 'src/components/ContextEditSidebar/ContextEditSidebar'
import ObjectTile from 'src/components/ObjectTile/ObjectTile'
import { getStageColor } from 'src/components/Pipeline/Pipeline'
import { usePipeline } from 'src/components/PipelineV2/PipelineDetail.tsx/PipelineContext'
import Row from 'src/components/Row/Row'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import {
  NativeSuggestedPipelineStages,
  NativeSuggestedPipelineTypes,
} from 'src/lib/relationshipSummary'

import {
  actionButtonStyle,
  actionChipStyle,
  sidebarBodyTextStyle,
} from '../styles'

const SidebarLayoutPipeline = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void
}) => {
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)
  const [noteCreate, setNoteCreate] = useState(null)
  const [tab, setTab] = useState('status')
  const { pipeline } = usePipeline()

  const pipelineTypeLabel = useMemo(() => {
    return NativeSuggestedPipelineTypes[pipeline?.type]?.label || 'Custom'
  }, [pipeline?.type])

  return pipeline ? (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, px: 3, pb: '4px' }}>
        <Row
          sx={{ width: '410px', overflow: 'hidden' }}
          gap={1}
        >
          <IconColumns3 size={24} />
          <Typography
            variant="h1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flexShrink: 1,
              fontSize: '1.5rem',
            }}
          >
            {pipeline.title}
          </Typography>
        </Row>
        <MetadataChip
          state={{
            label: pipelineTypeLabel,
            value: pipeline.type,
            color: 'secondary',
          }}
          icon={<IconCircleFilled />}
        />
      </Row>

      <Row
        sx={{ mt: 1, px: 3 }}
        gap={1}
      >
        {pipeline.id && (
          <>
            <Tooltip title={`Add context to ${pipeline.title}`}>
              <Chip
                clickable={true}
                icon={<IconBubbleText size={16} />}
                label="Add context"
                variant="outlined"
                size="small"
                sx={{ ...actionChipStyle }}
                onClick={() => {
                  setNoteCreate({
                    parentReferenceKey: `${NativeObjectTypes.Pipeline} : ${pipeline.id} : root`,
                    new: true,
                  })
                }}
              />
            </Tooltip>

            <ActionCreateDialog
              variant="button"
              orgs={[]}
              people={[]}
              oppIds={[]}
              workspaceId={selectedWorkspace}
              onUpdate={() => {
                // Refetch pipeline data if needed
              }}
            />

            <Chip
              size="small"
              icon={<IconTrophy size={16} />}
              label="Create Opportunity"
              variant="outlined"
              onClick={() => {
                setSidebarObject({
                  mode: 'create',
                  objectType: NativeObjectTypes.Opportunity,
                  objectId: 'CREATE',
                  workspaceId: selectedWorkspace,
                  properties: { pipelineId: pipeline.id },
                })
              }}
              sx={actionButtonStyle}
            />

            <ThreadCreateButton
              workspaceId={selectedWorkspace}
              sourceObject={{
                objectType: NativeObjectTypes.Pipeline,
                objectId: pipeline.id,
                properties: pipeline,
              }}
              otherObjects={[]}
              title={pipeline.title}
              onThreadCreated={() => {
                setOpen(false)
              }}
            />
          </>
        )}

        {noteCreate && (
          <ContextEditSidebar
            title={pipeline.title}
            context={noteCreate}
            onClose={() => {
              setNoteCreate(null)
            }}
            onInit={() => {}}
            onUpdate={() => {
              // Refetch pipeline data if needed
            }}
          />
        )}
      </Row>

      <Row sx={{ height: '64px', display: 'none' }}>
        <Tabs
          value={tab}
          onChange={(_, value) => {
            setTab(value)
          }}
          sx={{
            width: '100%',
            mx: 3,
            mt: 3,
            background: (theme) => theme.palette.secondary.light,
            borderRadius: '4px',
            '& .MuiTab-root': {
              border: 'none',
              borderRadius: '4px',
              flexGrow: 1,
              py: '4px',
              px: '12px',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 600,
              minHeight: '0px',
              height: '30px',
              fontFeatureSettings: 'liga off, clig off',
              m: '4px',
              '&.Mui-selected': {
                background: (theme) => theme.palette.background.paper,
              },
            },
          }}
          TabIndicatorProps={{
            sx: {
              height: '0px',
            },
          }}
        >
          <Tab
            label="Status"
            value="status"
          />
          <Tab
            label="Timeline"
            value="timeline"
          />
          <Tab
            label="About"
            value="about"
          />
        </Tabs>
      </Row>

      <Box
        sx={{
          px: 3,
          height: 'calc(100vh - 128px - 40px)',
          overflowY: 'auto',
        }}
      >
        {tab === 'status' && (
          <>
            <Box sx={{ mt: 3 }}>
              <Typography sx={sidebarBodyTextStyle}>
                {pipeline.description}
              </Typography>
            </Box>

            {pipeline?.stages?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Stages</Typography>
                {pipeline.stages.map((stage) => (
                  <Box
                    key={stage?.id || 'unknown'}
                    sx={{ mt: 2 }}
                  >
                    <Row
                      gap={1}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Typography variant="h3">
                        {stage?.title || 'Untitled Stage'}
                      </Typography>
                      {stage?.type && (
                        <MetadataChip
                          state={{
                            label:
                              NativeSuggestedPipelineStages[stage?.type]
                                ?.label || stage?.type?.toLowerCase(),
                            value: stage?.type,
                            color: getStageColor(stage.position),
                          }}
                          icon={<IconCircleFilled />}
                        />
                      )}
                    </Row>
                    {stage?.description && (
                      <Typography
                        sx={{ ...sidebarBodyTextStyle, opacity: 0.7 }}
                      >
                        {stage?.description}
                      </Typography>
                    )}
                    {stage?.opportunities?.length > 0 ? (
                      <Box sx={{ mb: 5 }}>
                        {stage?.opportunities?.map((opportunity) => (
                          <Box
                            sx={{
                              mt: 2,
                              border: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                              borderRadius: '4px',
                              p: '6px',
                            }}
                            key={opportunity?.id}
                          >
                            <ObjectTile
                              objectId={opportunity?.id}
                              objectType={NativeObjectTypes.Opportunity}
                              workspaceId={selectedWorkspace}
                              onClick={() => {
                                setSidebarObject(opportunity)
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography sx={sidebarBodyTextStyle}>
                        No opportunities in this stage
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}

        {tab === 'timeline' && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h2">Activity Timeline</Typography>
            {/* Add InteractionsTimeline component for pipeline */}
          </Box>
        )}

        {tab === 'about' && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h2">About</Typography>
            <Typography sx={sidebarBodyTextStyle}>
              {pipeline.description}
            </Typography>

            {pipeline?.opportunityTypes?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Opportunity Types</Typography>
                {pipeline.opportunityTypes.map((type) => (
                  <Typography
                    key={type || 'unknown'}
                    sx={sidebarBodyTextStyle}
                  >
                    {type || 'Unknown Type'}
                  </Typography>
                ))}
              </Box>
            )}

            {pipeline?.ownerEmails?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Pipeline Owners</Typography>
                {pipeline.ownerEmails.map((email) => (
                  <Typography
                    key={email || 'unknown'}
                    sx={sidebarBodyTextStyle}
                  >
                    {email || 'Unknown Email'}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Row
      sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <CircularProgress />
    </Row>
  )
}

export default SidebarLayoutPipeline
