import { useContext, useMemo } from 'react'

import { Box } from '@mui/material'

import { routes } from '@redwoodjs/router'

import {
  getDataTableRenderers,
  getDefaultColumnsContact,
} from 'src/components/DataGrid/util'
import DayTable from 'src/components/DataTables/DayTable/DayTable'
import { DataNavItems } from 'src/components/Organizations/organizations'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes, PropertyTypes } from 'src/lib/objects'

const PersonTable = ({ workspaceId, people = [] }) => {
  const { setSidebarObject, workspacePeople } = useContext(DayContext)

  const DataTableRenderers = getDataTableRenderers()
  const defaultColumnsContact = getDefaultColumnsContact({ DataTableRenderers })

  const allPeople = workspacePeople || people

  const data = useMemo(() => {
    if (allPeople.length === 0)
      return {
        rows: [],
        columns: defaultColumnsContact,
        waitingToQuery: true,
      }

    const uniqueEmails = new Set()
    return {
      rows: allPeople.map((person) => {
        if (!uniqueEmails.has(person.email)) {
          uniqueEmails.add(person.email)
          return {
            ...person,
            objectType: NativeObjectTypes.Person,
            workspaceId,
            objectId: person.email,
            id: person.email,
          }
        }
      }),
      columns: defaultColumnsContact,
    }
  }, [allPeople, defaultColumnsContact, workspaceId])

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DayTable
        id={`data-${workspaceId}-people`}
        title="People"
        objectType={NativeObjectTypes.Person}
        workspaceId={workspaceId}
        loading={false}
        data={data}
        rowObjectName="people"
        rowObjectNameSingular="person"
        navItems={DataNavItems(routes)}
        editableColumns={{
          currentJobTitle: {
            path: 'currentJobTitle',
            propertyType: PropertyTypes.TextArea,
          },
        }}
        onRowClick={(params) => {
          setSidebarObject({
            objectType: NativeObjectTypes.Person,
            objectId: params.row.email,
            properties: params.row,
          })
        }}
      />
    </Box>
  )
}

export default PersonTable
