import {
  isValidElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import type {
  GridFilterItem,
  GridColumnVisibilityModel,
  GridEventLookup,
} from '@mui/x-data-grid-premium'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import {
  IconAdjustmentsHorizontal,
  IconArrowBackUp,
  IconBaselineDensityMedium,
  IconChevronDown,
  IconColumns3,
  IconCsv,
  IconFilter,
  IconSearch,
} from '@tabler/icons-react'

import { navigate } from '@redwoodjs/router'

import { logger } from 'src/lib/logger'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'
import {
  modernButtonContainer,
  modernTabStyles,
  modernToolbarIconButton,
} from 'src/lib/style'

import Row from '../Row/Row'
import ViewManager from '../Views/ViewManager'
import ViewsProvider from '../Views/ViewsProvider'

const localCacheVersion = '0.0.1'

// const enableLogging = true

// function log(msg) {
//   if (enableLogging) {
//     logger.dev(msg)
//   }
// }

function parseOrNull(raw: unknown) {
  if (!raw) return null

  if (typeof raw === 'string') {
    try {
      return JSON.parse(raw)
    } catch (e) {
      console.warn(`Failed to parse: ${raw.substring(0, 50)}`)
      return null
    }
  }

  return null
}

export const DayDataGridToolbar = (props) => {
  const lastUserInteraction = useRef(0)
  const [filterPanelOpen, setFilterPanelOpen] = useState(false)

  const hasViews = useMemo(() => {
    return props.objectType &&
      Object.values(NativeObjectTypes).includes(props.objectType) &&
      props.workspaceId
      ? true
      : false
  }, [props.objectType, props.workspaceId])

  const [showAdvanced, setShowAdvanced] = useState(false)
  const apiRef = useGridApiContext()
  const [navMenuEl, setNavMenuEl] = useState(null)
  const [filterStateType, setFilterStateType] = useState<
    'tabSets' | 'custom' | null
  >(null)

  const storageKey = props.id
    ? `dayTable-${props.id}-${localCacheVersion}`
    : null

  const initialized = useRef(false)

  const [matchedFilter, setMatchedFilter] = useState(null)
  const [matchedGroupings, setMatchedGroupings] = useState(null)

  const rowCount = useMemo(() => {
    return apiRef?.current?.getRowsCount()
  }, [apiRef])

  const handleSetFilter = (filterId) => {
    if (!apiRef?.current || !props.tabSets) return
    const filter = props.tabSets?.filters.find(
      (filter) => filter.id === filterId
    )
    apiRef.current.setFilterModel({
      items: filter.filterItems,
    })
  }

  const navAnchorEl = useRef(null)

  const navWidth = useMemo(() => {
    if (navMenuEl) {
      return navMenuEl.offsetLeft
    } else {
      return 128
    }
  }, [navMenuEl])

  const checkForMatchedFilter = useCallback(() => {
    if (hasViews) return
    const items = apiRef.current.state.filter.filterModel.items
    if (items.length === 0) {
      setMatchedFilter(null)
      return
    }
    for (const filter of props.tabSets?.filters ?? []) {
      for (const item of filter.filterItems) {
        if (
          items?.some((i) => {
            const matches =
              i.field === item.field &&
              i.value === item.value &&
              i.operator === item.operator
            return matches
          })
        ) {
          setMatchedFilter(filter.id)
          return
        }
      }
    }
    logger.warn('No matched filter found')
  }, [props.tabSets, apiRef, hasViews])

  const checkForMatchedGroupings = useCallback(() => {
    if (hasViews) return
    const groupByFields = apiRef.current.state.rowGrouping.model

    const initialColumnVisibilityModel =
      props.initialState?.columns?.columnVisibilityModel

    apiRef.current.setColumnVisibilityModel({
      ...initialColumnVisibilityModel,
      ...groupByFields.reduce((acc, field) => {
        acc[field] = false
        return acc
      }, {}),
    })
    for (const grouping of props.tabSets?.groupings ?? []) {
      if (grouping.fields.every((field) => groupByFields.includes(field))) {
        setMatchedGroupings(grouping.id)
        return
      }
    }
    if (initialColumnVisibilityModel) {
      apiRef.current.setColumnVisibilityModel(initialColumnVisibilityModel)
    }
    setMatchedGroupings(null)
  }, [
    props.tabSets,
    apiRef,
    props.initialState?.columns?.columnVisibilityModel,
    hasViews,
  ])

  const handleSetGrouping = useCallback(
    (groupingIds) => {
      if (!apiRef?.current || !props.tabSets?.groupings) return
      const grouping = props.tabSets?.groupings?.find(
        (grouping) => grouping.id === groupingIds
      )

      const groupingFields = grouping?.fields
      apiRef.current.setRowGroupingModel(
        groupingFields?.[0] === 'all' ||
          !Array.isArray(groupingFields) ||
          groupingFields?.length === 0
          ? []
          : groupingFields
      )
      checkForMatchedGroupings()
    },
    [props.tabSets, checkForMatchedGroupings, apiRef]
  )

  const handleResetState = () => {
    handleSetGrouping([])
    handleSetFilter('all')
    apiRef?.current?.setSortModel([])
    apiRef?.current?.setFilterModel({ items: [] })
    apiRef?.current?.setRowGroupingModel([])
    apiRef?.current?.setAggregationModel({})
    apiRef?.current?.setQuickFilterValues([])
    apiRef?.current?.setColumnVisibilityModel(
      props.initialState?.columns?.columnVisibilityModel
    )
    apiRef?.current?.setPinnedColumns(
      props.initialState?.columns?.pinnedColumns || { left: [], right: [] }
    )
  }

  const getFilterStateType = useCallback(() => {
    if (hasViews) return null
    const hasSort = apiRef?.current?.getSortModel()?.length > 0

    const hasTabSetFilters: boolean = props.tabSets?.filters?.length > 0
    const hasTabSetGroupings: boolean = props.tabSets?.groupings?.length > 0

    if (!hasTabSetFilters && !hasTabSetGroupings) {
      setShowAdvanced(true)
      return 'custom'
    }

    const currentFilterState: GridFilterItem[] =
      apiRef?.current?.state?.filter?.filterModel?.items

    /*
    const currentPinnedColumns: GridPinnedColumns =
      apiRef?.current?.state?.pinnedColumns

    const hasPinnedColumns: boolean =
      currentPinnedColumns?.['left']?.length > 0 ||
      currentPinnedColumns?.['right']?.length > 0
    */

    const hasPinnedColumns = false

    const currentColumnVisibilityModel: GridColumnVisibilityModel =
      apiRef?.current?.state?.columns?.columnVisibilityModel

    const hasFilters: boolean =
      apiRef?.current?.state?.filter?.filterModel?.items?.length > 0

    const hasColumns: boolean =
      Object.keys(currentColumnVisibilityModel).length > 0
    const onlyTabSetFiltersApplied: boolean =
      (props.tabSets?.filters?.length === currentFilterState?.length &&
        currentFilterState?.every((filter) =>
          props.tabSets?.filters?.some((tabSetFilter) => {
            return (
              tabSetFilter.field === filter.field &&
              tabSetFilter.operator === filter.operator &&
              tabSetFilter.value === filter.value
            )
          })
        )) ||
      currentFilterState?.length === 0

    const currentGroupingState: string[] =
      apiRef?.current?.state?.rowGrouping?.model

    const onlyTabSetGroupingsApplied: boolean =
      props.tabSets?.groupings?.some((groupingSet) => {
        return (
          groupingSet.length === currentGroupingState?.length &&
          currentGroupingState?.every((field) => {
            return groupingSet.includes(field)
          })
        )
      }) || true

    if (onlyTabSetFiltersApplied && onlyTabSetGroupingsApplied && !hasSort) {
      return 'tabSets'
    } else if (hasPinnedColumns || hasSort || hasFilters || hasColumns) {
      return 'custom'
    } else {
      return null
    }
  }, [props.tabSets, apiRef, hasViews])

  const lastGridChangeAt = useRef(0)

  const saveStateToLocalStorage = useCallback(() => {
    if (!initialized.current) {
      return
    }

    if (!lastUserInteraction.current) {
      return
    }

    const state = apiRef.current.exportState()
    if (state) {
      logger.dev('Saving state to localStorage')
      localStorage.setItem(storageKey, JSON.stringify(state))

      initialized.current = true
    } else {
      logger.warn('No state to save')
    }
  }, [storageKey, apiRef])

  const handleMouseDown = useCallback(() => {
    lastUserInteraction.current = Date.now()
  }, [lastUserInteraction])

  useEffect(() => {
    const instrumentContainers = () => {
      const containers = document.querySelectorAll(
        '.MuiDataGrid-columnHeaders, .MuiDataGrid-columnsManagement, .MuiDataGrid-panelWrapper'
      )
      if (containers) {
        for (const container of containers) {
          container.addEventListener('mousedown', handleMouseDown)
        }
      }
      return containers
    }

    setTimeout(() => {
      const containers = instrumentContainers()
      return () => {
        for (const container of containers || []) {
          container.removeEventListener('mousedown', handleMouseDown)
        }
      }
    }, 500)
  }, [showAdvanced, filterPanelOpen, handleMouseDown])

  const updateFilterStateType = useCallback(() => {
    if (hasViews) return
    const filterType = getFilterStateType()
    if (filterType) {
      setFilterStateType(filterType)
    }
  }, [getFilterStateType, hasViews])

  const saveStateToViews = useCallback(() => {
    if (hasViews && lastUserInteraction.current > 0) {
      logger.dev('Saving state to views')
      lastGridChangeAt.current = Date.now()
    }
  }, [hasViews, lastUserInteraction])

  const handleViewsSaveCallback = useCallback(() => {
    lastUserInteraction.current = 0
  }, [lastUserInteraction])

  const handleGroupingModelChange = useCallback(() => {
    if (hasViews) {
      saveStateToViews()
    } else {
      updateFilterStateType()
      checkForMatchedGroupings()
      saveStateToLocalStorage()
    }
  }, [
    updateFilterStateType,
    checkForMatchedGroupings,
    saveStateToLocalStorage,
    hasViews,
    saveStateToViews,
  ])

  const handleFilterModelChange = useCallback(() => {
    if (hasViews) {
      saveStateToViews()
    } else {
      updateFilterStateType()
      checkForMatchedFilter()
      saveStateToLocalStorage()
    }
  }, [
    updateFilterStateType,
    checkForMatchedFilter,
    saveStateToLocalStorage,
    saveStateToViews,
    hasViews,
  ])

  const handleUserStateChange = useCallback(() => {
    if (hasViews) {
      saveStateToViews()
    } else {
      saveStateToLocalStorage()
    }
  }, [saveStateToLocalStorage, saveStateToViews, hasViews])

  const handleFilterPanelOpen = useCallback(() => {
    setFilterPanelOpen(true)
  }, [])

  const handleFilterPanelClose = useCallback(() => {
    setFilterPanelOpen(false)
  }, [])

  const intializeStateFromViews = useCallback(() => {
    if (hasViews) {
      // Only mark as initialized when views are handling it
      initialized.current = true
      // Clear any localStorage state to prevent it from being used
      if (storageKey) {
        localStorage.removeItem(storageKey)
      }
    }
  }, [hasViews, storageKey])

  const initializeState = useCallback(() => {
    if (initialized.current) return

    // If using views, don't do anything - let ViewsProvider handle it
    if (hasViews) {
      return
    }

    const raw = localStorage.getItem(storageKey)
    if (raw) {
      const parsed = parseOrNull(raw)
      if (parsed) {
        try {
          apiRef.current.restoreState(parsed)
          updateFilterStateType()
          initialized.current = true
        } catch (e) {
          logger.error(`Failed to restore grid state`, e)
          logger.dev({ e })
        }
      } else {
        logger.warn('No state to restore', { raw })
      }
    } else {
      const state = apiRef.current.exportState()
      if (state) {
        updateFilterStateType()
        localStorage.setItem(storageKey, JSON.stringify(state))
        initialized.current = true
      }
    }

    checkForMatchedFilter()
    checkForMatchedGroupings()
  }, [
    apiRef,
    checkForMatchedFilter,
    checkForMatchedGroupings,
    initialized,
    storageKey,
    updateFilterStateType,
    hasViews,
  ])

  const getExternalGridState = useCallback(() => {
    return apiRef.current.exportState()
  }, [apiRef])

  const setExternalGridState = useCallback(
    (state) => {
      const stateToRestore = { ...state }
      delete (stateToRestore as any).preferencePanel
      apiRef.current.restoreState(stateToRestore)
    },
    [apiRef]
  )

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', saveStateToLocalStorage)
    initializeState()

    return () => {
      window.removeEventListener('beforeunload', saveStateToLocalStorage)
      saveStateToLocalStorage()
    }
  }, [saveStateToLocalStorage, initializeState])

  useEffect(() => {
    const subs: VoidFunction[] = []

    subs.push(
      apiRef.current.subscribeEvent(
        'filterModelChange',
        handleFilterModelChange
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'rowGroupingModelChange',
        handleGroupingModelChange
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'preferencePanelOpen',
        handleFilterPanelOpen
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'preferencePanelClose',
        handleFilterPanelClose
      )
    )

    /*
      Supported Events:

      aggregationModelChange
      Fired when the aggregation model changes.

      columnOrderChange
      Fired when the user ends reordering a column.

      columnVisibilityModelChange
      Fired when the column visibility model changes.

      columnWidthChange
      Fired when the width of a column is changed.

      densityChange
      Fired when the density changes.

      filterModelChange
      Fired when the filter model changes.

      headerSelectionCheckboxChange
      Fired when the value of the selection checkbox of the header is changed.

      rowGroupingModelChange
      Fired when the row grouping model changes.

      sortModelChange
      Fired when the sort model changes.
    */

    const eventsToSubscribe: (keyof GridEventLookup)[] = [
      'columnOrderChange',
      'columnWidthChange',
      'columnVisibilityModelChange',
      'densityChange',
      'sortModelChange',
      'headerSelectionCheckboxChange',
      'aggregationModelChange',
    ]

    eventsToSubscribe.forEach((event) => {
      subs.push(apiRef.current.subscribeEvent(event, handleUserStateChange))
    })

    return () => {
      subs.forEach((unsub) => {
        unsub()
      })
    }
  }, [
    apiRef,
    handleFilterModelChange,
    handleGroupingModelChange,
    handleUserStateChange,
    handleFilterPanelOpen,
    handleFilterPanelClose,
  ])

  const navElement = props.navItems.find((item) => {
    return isValidElement(item)
  })
  const navItems = navElement ? [] : props.navItems

  return (
    <GridToolbarContainer
      className="data-grid-toolbar"
      sx={{
        height: hasViews ? '84px !important' : '48px !important',
        p: 0,
        m: 0,
        mb: '0px',
        border: 'none',
        borderWidth: '0px',
        display: 'block',
        boxSizing: 'border-box',
      }}
    >
      <Row
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          height: '48px !important',
          boxSizing: 'border-box',
          '& .MuiButton-root': {
            m: 0,
            px: 0,
          },
        }}
      >
        <Box>
          <Row
            gap={1}
            sx={{ alignItems: 'flex-start' }}
          >
            {props.objectType && (
              <>
                {React.createElement(
                  ObjectTypeMetadata[props.objectType].icon,
                  { size: 18 }
                )}
              </>
            )}
            <Box>
              <Typography
                sx={{
                  lineHeight: '115%',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: '-0.3px',
                }}
              >
                {props.title}
              </Typography>
              {props.subtitle && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    letterSpacing: '-0.7px',
                    opacity: 0.6,
                  }}
                >
                  {props.subtitle}
                </Typography>
              )}
            </Box>

            {navItems.length > 0 && (
              <>
                <IconButton
                  ref={navAnchorEl}
                  onClick={() => {
                    setNavMenuEl(navAnchorEl?.current)
                  }}
                  sx={{
                    background: (theme) =>
                      navMenuEl
                        ? theme.palette.background.paper
                        : 'transparent',
                    transition: '0.3s all ease-in-out',
                    borderRadius: '4px',
                    p: '4px',
                    mt: '-2px',
                    boxSizing: 'border-box',
                    borderBottomRightRadius: navMenuEl ? '0px' : '4px',
                    borderBottomLeftRadius: navMenuEl ? '0px' : '4px',
                    boxShadow: navMenuEl
                      ? `
            0 1px 2px rgba(136, 161, 183, 0.07),
            0 2px 4px rgba(136, 161, 183, 0.07),
            0 4px 8px rgba(136, 161, 183, 0.07),
            0 8px 16px rgba(136, 161, 183, 0.07),
            0 16px 32px rgba(136, 161, 183, 0.07),
            0 32px 64px rgba(136, 161, 183, 0.07)
          `
                      : 'none',
                  }}
                >
                  <IconChevronDown
                    stroke={2}
                    style={{
                      transform: navMenuEl ? 'rotate(180deg)' : null,
                      transition: '0.3s all ease-in-out',
                    }}
                    size={16}
                  />
                </IconButton>
                <Menu
                  open={!!navMenuEl}
                  anchorEl={navMenuEl}
                  onClose={() => setNavMenuEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 2,
                    horizontal: navWidth + 28.02,
                  }}
                  sx={{
                    border: 'none',
                    boxSizing: 'border-box',
                    '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
                      minWidth: '128px',
                      boxSizing: 'border-box',
                      borderTopRightRadius: '0px !important',
                      overflow: 'visible',
                      '::before': {
                        height: '4px',
                        width: '24px',
                        background: (theme) => theme.palette.background.paper,
                        content: '""',
                        position: 'absolute',
                        top: '-2px',
                        right: '0px',
                        zIndex: 1000000,
                      },
                    },
                  }}
                >
                  {navItems.map((item, index) => (
                    <ListItem
                      key={`dayTable-nav-item-${index}`}
                      sx={{
                        cursor: 'pointer',
                        p: 0,
                        width: `${navWidth + 28}px`,
                      }}
                      onClick={() => {
                        navigate(item.to)
                      }}
                    >
                      <ListItemButton sx={{ px: '10px' }}>
                        {item.icon && (
                          <ListItemIcon sx={{ minWidth: '14px', mr: '6px' }}>
                            {React.cloneElement(item.icon, { size: 14 })}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: '12px',
                              fontWeight: 500,
                              color: 'text.primary',
                              opacity: 0.8,
                              letterSpacing: '-0.3',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Menu>
              </>
            )}
          </Row>

          <Typography
            sx={{
              fontSize: '14px',
              color: 'text.primary',
              fontWeight: 500,
              letterSpacing: '-0.3px',
              opacity: 0.7,
              lineHeight: '215%',
              display: 'none',
            }}
          >{`${rowCount} ${props.rowObjectName}`}</Typography>
        </Box>

        <Row
          gap={1}
          sx={{ flexGrow: 1, flexShrink: 0, justifyContent: 'flex-end' }}
        >
          {props.actionButtons.length > 0 && (
            <Row
              gap={1}
              sx={{
                '& .MuiButton-root': {
                  height: '30px !important',
                  borderRadius: '3px !important',
                  flexShrink: 0,
                  fontSize: '11px !important',
                  px: '12px !important',
                  background: (theme) => theme.palette.background.paper,
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              {props.actionButtons.map((button) => button)}
            </Row>
          )}
          {!hasViews && filterStateType === 'custom' && (
            <Tooltip
              title="Reset Filters"
              arrow={true}
            >
              <IconButton
                onClick={handleResetState}
                sx={{
                  p: '8px',
                  borderRadius: '3px',
                }}
              >
                <IconArrowBackUp size={16} />
              </IconButton>
            </Tooltip>
          )}
          {!hasViews && props.showFilters && (
            <>
              {!showAdvanced && !props.tabSets?.filters ? (
                <Tooltip
                  title="Adjust Filters"
                  arrow={true}
                >
                  <IconButton
                    onClick={() => setShowAdvanced((prev) => !prev)}
                    sx={modernToolbarIconButton}
                  >
                    <IconAdjustmentsHorizontal size={14} />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  onClick={() => setShowAdvanced((prev) => !prev)}
                  sx={modernToolbarIconButton}
                >
                  {showAdvanced ? (
                    <IconArrowBackUp size={14} />
                  ) : (
                    <IconAdjustmentsHorizontal size={14} />
                  )}
                </IconButton>
              )}
            </>
          )}
          {(props.tabSets || showAdvanced) && (
            <Row ref={props.panelAnchorEl}>
              <Row
                gap={2}
                sx={{}}
              >
                {props.tabSets && !showAdvanced && (
                  <Row
                    gap={2}
                    sx={modernTabStyles}
                  >
                    {props.tabSets.filters?.length > 0 && (
                      <Tabs
                        value={matchedFilter || 'all'}
                        TabIndicatorProps={{
                          sx: {
                            height: '0px',
                          },
                        }}
                        onChange={(_, value) => {
                          handleSetFilter(value)
                        }}
                      >
                        {props.tabSets.filters.map((filter, index) => {
                          return (
                            <Tab
                              key={`filter-${index}-${filter.id}`}
                              label={filter.label}
                              value={filter.id}
                            />
                          )
                        })}
                      </Tabs>
                    )}

                    {props.tabSets?.groupings?.length > 0 && (
                      <Tabs
                        value={matchedGroupings || 'all'}
                        TabIndicatorProps={{
                          sx: {
                            height: '0px',
                          },
                        }}
                        onChange={(_, value) => {
                          handleSetGrouping(value)
                        }}
                      >
                        <Tab
                          key={`grouping-all`}
                          label="All"
                          value="all"
                        />
                        {props.tabSets.groupings.map((grouping, index) => {
                          return (
                            <Tab
                              key={`grouping-${index}`}
                              label={grouping.label}
                              value={grouping.id}
                            />
                          )
                        })}
                      </Tabs>
                    )}
                  </Row>
                )}
                {!hasViews && (showAdvanced || !props.tabSets) && (
                  <Row sx={modernButtonContainer}>
                    {props.showColumnFilter && (
                      <GridToolbarColumnsButton
                        ref={props.setColumnMenuEl}
                        slotProps={{
                          button: {
                            startIcon: (
                              <IconColumns3
                                size={12}
                                stroke={2.5}
                              />
                            ),
                          },
                        }}
                      />
                    )}
                    {props.showFilter && (
                      <GridToolbarFilterButton
                        ref={props.setFilterButtonEl}
                        slotProps={{
                          button: {
                            startIcon: (
                              <IconFilter
                                size={12}
                                stroke={2.5}
                              />
                            ),
                          },
                        }}
                      />
                    )}
                    {props.showDensitySelector && (
                      <GridToolbarDensitySelector
                        slotProps={{
                          button: {
                            size: 'small',
                            startIcon: (
                              <IconBaselineDensityMedium
                                size={12}
                                stroke={2.5}
                              />
                            ),
                          },
                        }}
                      />
                    )}
                    {props.showExport && (
                      <GridToolbarExport
                        csvOptions={props.csvOptions}
                        slotProps={{
                          button: {
                            size: 'small',
                            startIcon: (
                              <IconCsv
                                size={14}
                                stroke={2}
                              />
                            ),
                          },
                        }}
                      />
                    )}
                  </Row>
                )}
              </Row>
            </Row>
          )}

          {navElement}
          {props.showQuickFilter && (
            <GridToolbarQuickFilter
              InputProps={{
                startAdornment: (
                  <IconSearch
                    stroke={3.5}
                    size={20}
                  />
                ),
                placeholder: `Search ${props.rowObjectName}`,
                disableUnderline: true,
                sx: {
                  m: 0,
                  py: 0,
                  height: '30px',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: '4px',
                  transition: 'all 0.2s ease-in-out',
                  //width: '232px',
                  flexShrink: 1,
                  background: (theme) => theme.palette.background.paper,
                  '& .MuiInput-input': {
                    padding: 1,
                    fontSize: '12px',
                    letterSpacing: '-0.3px',
                    color: (theme) => theme.palette.text.primary,
                    fontWeight: 500,
                    opacity: 1,
                    m: 0,
                    transition: 'all 0.2s ease-in-out',

                    '&::placeholder': {
                      textOverflow: 'ellipsis !important',
                      color: (theme) => theme.palette.text.primary,
                      opacity: 0.7,
                      transition: 'all 0.2s ease-in-out',
                    },
                    '&:focus': {
                      opacity: 1,
                    },
                  },
                  '& .tabler-icon': {
                    ml: '8px',
                    color: (theme) => theme.palette.primary.main,
                    opacity: 0.6,
                    transition: 'all 0.2s ease-in-out',
                  },
                  '&.Mui-focused': {
                    transition: 'all 0.2s ease-in-out',
                    boxShadow: (theme) => theme.shadows[2],
                    '& .MuiInput-input': {
                      '&::placeholder': {
                        opacity: 0.4,
                      },
                    },
                    '& .tabler-icon': {
                      opacity: '1 !important',
                    },
                  },
                },
              }}
            />
          )}
        </Row>
      </Row>
      {hasViews && (
        <ViewsProvider
          objectType={props.objectType}
          workspaceId={props.workspaceId}
          getExternalGridState={getExternalGridState}
          setExternalGridState={setExternalGridState}
          lastGridChangeAt={lastGridChangeAt.current}
          setInitialized={intializeStateFromViews}
          lastUserInteraction={lastUserInteraction.current}
          onSave={handleViewsSaveCallback}
        >
          <ViewManager />
        </ViewsProvider>
      )}
    </GridToolbarContainer>
  )
}

// const isPresentInOperator = {
//   value: 'isPresentIn',
//   getApplyFilterFn: (filterItem) => {
//     if (!filterItem.field || !filterItem.value || !filterItem.operator) {
//       return false
//     }

//     return (params): boolean => {
//       return (
//         params.value &&
//         filterItem.value &&
//         filterItem.value.includes(params.value)
//       )
//     }
//   },
//   InputComponent: null,
// }
