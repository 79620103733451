export const internalSuperAdminEmails = [
  'markitecht@gmail.com',
  'erik@ipsumcreative.com',
  'michael.g.pici@gmail.com',
  'gwendolynr@gmail.com',
  'stephen@day.ai',
  'gwen@day.ai',
  'erin@day.ai',
  'daphne@day.ai',
  'will@day.ai',
]

export const ungatedForNewCNI = (workspaceId: string) => {
  return [
    '85334f81-c4ed-42e9-82cf-71fd4638e9ad',
    '7d014738-da3a-4c11-aced-2ce58715901d',
  ].includes(workspaceId)
}

export const ungatedForSuggestedOpportunities = () => {
  return true
}

export const ungatedForSlackNotifications = (workspaceId: string) => {
  return [
    '7d014738-da3a-4c11-aced-2ce58715901d',
    '85334f81-c4ed-42e9-82cf-71fd4638e9ad',
  ].includes(workspaceId)
}
