import { useCallback, useMemo, useRef, useState } from 'react'

import {
  Typography,
  Box,
  Menu,
  MenuItem,
  type PopoverVirtualElement,
} from '@mui/material'
import type {
  GridCellEditStartParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium'
import {
  DataGridPremium,
  gridPreferencePanelStateSelector,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license'
import { IconCircleFilled, IconRefresh } from '@tabler/icons-react'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import Row from 'src/components/Row/Row'
import { ungatedForViews } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  PropertyTypes,
} from 'src/lib/objects'

import { DayDataGridToolbar } from '../dataTables'

LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

const GET_OBJECT_PROPERTY_DEFINITIONS = gql`
  query GetObjectPropertyDefinitions(
    $workspaceId: String!
    $objectTypeId: String!
  ) {
    objectPropertyDefinitionsByObjectType(
      workspaceId: $workspaceId
      objectTypeId: $objectTypeId
    ) {
      id
      name
      description
      workspaceId
      objectTypeId
      propertyTypeId
      aiManaged
      options {
        name
        description
        id
      }
    }
  }
`

const UPDATE_OBJECT_PROPERTY = gql`
  mutation UpdateObjectProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      success
    }
  }
`

// Add type definitions for our custom column types
type PicklistOption = {
  id: string
  name: string
  description?: string
  selected?: boolean
}

const slugifyFilename = (str: string) => {
  if (!str) return ''
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

const CustomColumnDefinitions = {
  [PropertyTypes.TextArea]: {
    type: 'string',
    width: 180,
    groupable: true,
    sortable: true,
    filterable: true,
  },
  [PropertyTypes.Integer]: {
    type: 'number',
    width: 120,
    groupable: true,
    valueFormatter: (value) => value?.toLocaleString(),
    groupingValueGetter: (params) => params.value?.toLocaleString(),
  },
  [PropertyTypes.Float]: {
    type: 'number',
    width: 120,
    groupable: true,
    valueFormatter: (value) =>
      typeof value === 'number'
        ? value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : value,
    groupingValueGetter: (params) =>
      typeof params.value === 'number'
        ? params.value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : params.value,
  },
  [PropertyTypes.Boolean]: {
    type: 'boolean',
    width: 100,
    groupable: true,
    valueFormatter: (value) => (value ? 'Yes' : 'No'),
    groupingValueGetter: (params) => (params.value ? 'Yes' : 'No'),
  },
  [PropertyTypes.Combobox]: {
    type: 'singleSelect',
    width: 150,
    groupable: true,
    valueFormatter: (value, row) => {
      const option = row.options?.find((opt) => opt.id === value)
      return option?.name || value
    },
    groupingValueGetter: (params) => {
      return params.value
    },
  },
  [PropertyTypes.MultiPicklist]: {
    type: 'multipicklist',
    width: 200,
    groupable: false,
    renderEditCell: (params) => <MultiPicklistEditor {...params} />,
    valueFormatter: (value: string[], row) => {
      if (!Array.isArray(value)) return ''
      const options = row.valueOptions || []
      return value
        .map((id) => options.find((opt) => opt.id === id)?.name || id)
        .join(', ')
    },
    valueParser: (value) => value,
  },
  [PropertyTypes.Picklist]: {
    type: 'singleSelect',
    width: 150,
    groupable: true,
  },
  [PropertyTypes.Currency]: {
    type: 'number',
    width: 120,
    groupable: true,
    valueFormatter: (value) =>
      typeof value === 'number'
        ? value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        : value,
    groupingValueGetter: (params) =>
      typeof params.value === 'number'
        ? params.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        : params.value,
  },
  [PropertyTypes.Percent]: {
    type: 'number',
    width: 120,
    groupable: true,
    valueFormatter: (value) =>
      typeof value === 'number' ? `${(value * 100).toFixed(1)}%` : value,
    groupingValueGetter: (params) =>
      typeof params.value === 'number'
        ? `${(params.value * 100).toFixed(1)}%`
        : params.value,
  },
  [PropertyTypes.Phone]: {
    type: 'string',
    width: 150,
    groupable: true,
    groupingValueGetter: (params) => {
      if (!params.value) return null

      return (
        params.value?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') ||
        params.value
      )
    },
  },
  [PropertyTypes.Email]: {
    type: 'string',
    width: 200,
    groupable: true,
  },
  [PropertyTypes.Url]: {
    type: 'string',
    width: 200,
    groupable: true,
  },
  [PropertyTypes.DateTime]: {
    type: 'dateTime',
    width: 180,
    groupable: true,
    valueFormatter: (value) => (value ? new Date(value).toLocaleString() : ''),
    groupingValueGetter: (params) =>
      params.value ? new Date(params.value).toLocaleString() : '',
  },
}

const MultiPicklistEditor = (props: GridRenderEditCellParams) => {
  const value = props.value || []
  const options = (props?.colDef as any)?.valueOptions || []
  const apiRef = useGridApiContext()
  const anchorEl = useRef<PopoverVirtualElement | null>(null)

  const handleOptionClick = async (optionId: string) => {
    // toggle the selected state of the option
    let newValues = [...value]
    logger.dev(
      `Starting with ${value.length} values: ${value.map((id) => options.find((opt) => opt.id === id)?.name).join(', ')}`
    )
    const label = options.find((opt) => opt.id === optionId)?.name
    if (value.includes(optionId)) {
      logger.dev('Removing', label)
      newValues = newValues.filter((id) => id !== optionId)
    } else {
      logger.dev('Adding', label)
      newValues.push(optionId)
    }

    logger.dev('Saving', {
      id: props.id,
      field: props.field,
      value: newValues,
    })
    const result = await apiRef?.current?.setEditCellValue({
      id: props.id,
      field: props.field,
      value: newValues,
    })
    logger.dev('Set edit cell value result', { result })
  }

  const handleClose = (e: any) => {
    e.stopPropagation()
    anchorEl.current = null
    logger.dev('CLOSING')
    const result = apiRef?.current?.stopCellEditMode({
      id: props.id,
      field: props.field,
    })
    logger.dev('Stop cell edit mode result', { result })
  }

  return (
    <Box
      ref={anchorEl}
      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}
    >
      <Menu
        anchorEl={anchorEl.current}
        open={Boolean(anchorEl.current)}
        onClose={handleClose}
      >
        {options.map((option: PicklistOption) => (
          <MenuItem
            key={option.id}
            onClick={(e) => {
              e.stopPropagation()
              handleOptionClick(option.id)
            }}
            sx={{
              fontSize: '12px',
              py: 0.5,
            }}
            selected={value.includes(option.id)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const uuidv4Pattern =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const isUUIDv4 = (str) => uuidv4Pattern.test(str.toLowerCase())

const DayTable = ({
  id,
  workspaceId = null,
  title,
  subtitle = null,
  data,
  loading,
  initialState = {},
  tabSets = null,
  onRowClick,
  rowObjectName = 'rows',
  navItems = [],
  rowObjectNameSingular = 'row',
  showStats = true,
  headerLevel = 2,
  csvFilename = null,
  showFilters = true,
  actionButtons = [],
  objectType = null, // The type of object being displayed (e.g. NativeObjectTypes.Contact)
}) => {
  const { currentUser } = useAuth()
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)
  const [columnMenuEl, setColumnMenuEl] = useState<HTMLDivElement | null>(null)

  const isUngatedForViews = useMemo(() => {
    return ungatedForViews(currentUser) && objectType
  }, [currentUser, objectType])

  const {
    data: objectPropertyDefinitionData,
    loading: objectPropertyDefinitionLoading,
  } = useQuery(GET_OBJECT_PROPERTY_DEFINITIONS, {
    variables: { workspaceId, objectTypeId: objectType },
    skip: !workspaceId || !objectType || !isUngatedForViews,
    pollInterval: 3000,
  })

  const [updateObjectProperty] = useMutation(UPDATE_OBJECT_PROPERTY)

  const apiRef = useGridApiRef()

  const pathsByField = useMemo(() => {
    return Object.fromEntries(
      data.columns.map((column) => [column.field, column.propertyPath])
    )
  }, [data.columns])

  const fullyLoaded = useMemo(() => {
    return (
      !isUngatedForViews ||
      !objectPropertyDefinitionLoading ||
      data.columns.length > 0
    )
  }, [isUngatedForViews, objectPropertyDefinitionLoading, data.columns])

  //log({ passedRef: apiRef, init: initialized.current, ref: apiRef.current })

  const height = '100%'
  const width = '100%'

  const memoizedLoading = useMemo(
    () => (loading && !(data.rows?.length > 0)) || data.waitingToQuery,
    [loading, data]
  )

  const hasViews = useMemo(() => {
    return objectType &&
      Object.values(NativeObjectTypes).includes(objectType) &&
      workspaceId
      ? true
      : false
  }, [objectType, workspaceId])

  const icon = useMemo(() => {
    let iconToUse = IconCircleFilled
    if (objectType) {
      iconToUse = ObjectTypeMetadata[objectType].icon
    } else if (rowObjectNameSingular) {
      const objectTypeKey = `native_${rowObjectNameSingular}    `
      if (Object.keys(ObjectTypeMetadata).includes(objectTypeKey)) {
        iconToUse = ObjectTypeMetadata[objectTypeKey].icon
      }
    }
    return React.createElement(iconToUse)
  }, [objectType, rowObjectNameSingular])

  const dayTableStyle = useMemo(() => {
    return {
      height,
      width,
      '& .MuiDataGrid-toolbarContainer': {
        //border: 'none',
        overflow: 'visible',
      },
      '.MuiDataGrid-cell': {
        //border: 'none',
        cursor: onRowClick ? 'pointer' : 'default',
      },
      '&.MuiDataGrid-root': {
        border: 'none',
        borderWidth: '0px',
        '--DataGrid-containerBackground': (theme) =>
          theme.palette.background.paper,
        overflow: 'visible',
      },
      '.MuiDataGrid-main': {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        borderTopLeftRadius: hasViews ? '0px' : '4px',
        backgroundColor: (theme) => theme.palette.background.paper,
        '& .MuiDataGrid-virtualScroller': {
          //width: width - 2,
          '& .MuiDataGrid-virtualScrollerContent': {},
        },
        '& .MuiDataGrid-topContainer': {
          height: '42px',
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        fontSize: '12px',
        letterSpacing: '-0.4px',
      },
      '& .MuiDataGrid-row:hover': {
        background: 'transparent',
      },
      '& .MuiDataGrid-cell': {
        fontSize: '11px',
        letterSpacing: '-0.22px',
        opacity: 0.8,
        color: (theme) => theme.palette.text.primary,
        '& .MuiChip-sizeSmall': {},
        '& .MuiDataGrid-groupingCriteriaCell': {
          fontSize: '12px',
          letterSpacing: '-0.17px',
          opacity: 0.8,
          fontWeight: 600,
          color: (theme) => theme.palette.text.primary,
          '& .MuiDataGrid-groupingCriteriaCellToggle': {
            mr: 0,
            ml: '-8px',
          },
        },

        '&.MuiDataGrid-cell--editing': {
          boxShadow: 'none',
          outline: 'none',

          '&:focus-within': {
            outline: 'none',
          },
        },

        '& .MuiDataGrid-editInputCell': {
          '&.MuiInputBase-root': {
            '& .MuiInputBase-input': {
              padding: '0px 8px',
              border: 'none',
              fontSize: '11px',
              ml: '1px',
            },
          },
        },
      },
      '& .MuiDataGrid-aggregationColumnHeaderLabel': {
        fontWeight: 500,
        fontSize: '9px',
        letterSpacing: '-0.4px',
        opacity: 0.5,
      },
      '& .MuiDataGrid-menu': {},
      '& .MuiDataGrid-panelWrapper': {
        '& .MuiDataGrid-panelHeader': {},
        '& .MuiDataGrid-panelContent': {
          '& .MuiDataGrid-columnsPanel': {
            '& .MuiDataGrid-columnsPanelRow': {
              '& .MuiFormControlLabel-root': {
                '& .MuiSwitch-root': {
                  '& .MuiSwitch-switchBase': {},
                  '& .MuiSwitch-track': {},
                },
                '& .MuiTypography-root': {},
              },
            },
          },
          '& .MuiDataGrid-panelFooter': {},
        },
      },
    }
  }, [hasViews, onRowClick])

  const rowEditUnderway = useRef(0)
  const clickTimeout = useRef(null)

  const handleCellClick = useCallback(
    (params) => {
      logger.dev({ params, editUnderway: rowEditUnderway.current })
      if (rowEditUnderway.current) {
        return
      }

      if (!params.isEditable) {
        onRowClick?.(params)
        return
      }

      // If there's an existing timeout, this is a second click - don't do anything
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        return
      }

      // First click - set timeout
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null
        onRowClick?.(params) // Only fires if we got a single click
      }, 200)
    },
    [onRowClick]
  )

  const handleCellEditStart = useCallback(
    (_params: GridCellEditStartParams) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        rowEditUnderway.current = 1
      }
    },
    []
  )

  const handleCellEditStop = useCallback((cellEditStopParams) => {
    logger.dev('Stopping edit', { cellEditStopParams })
    setTimeout(() => {
      rowEditUnderway.current = 0
    }, 150)
  }, [])

  const handleProcessRowUpdate = useCallback(
    (updatedRow, originalRow) => {
      let editedField
      logger.dev('Processing row update', { updatedRow, originalRow })
      for (const key of Object.keys(updatedRow)) {
        if (updatedRow[key] !== originalRow[key]) {
          editedField = key
          break
        }
      }
      if (editedField) {
        const isCustomProperty = isUUIDv4(editedField)
        const input = {
          workspaceId,
          objectId: updatedRow.id,
          objectType,
          path: isCustomProperty
            ? `custom/${editedField}`
            : pathsByField[editedField],
          value: updatedRow[editedField],
        }

        logger.dev('Updating object property', { input })

        updateObjectProperty({ variables: { input } })
      } else {
        logger.dev('No edited field found')
      }

      return updatedRow
    },
    [pathsByField, objectType, workspaceId, updateObjectProperty]
  )

  // Replace the enhancedColumns with columns generated from property definitions and passed columns
  const columns = useMemo(() => {
    // Start with the passed-in columns
    const baseColumns = data.columns || []

    // If we don't have property definitions, just return the base columns
    if (!objectPropertyDefinitionData?.objectPropertyDefinitionsByObjectType) {
      if (isUngatedForViews) {
        logger.dev('No property definitions, returning empty columns')
        return []
      } else {
        return baseColumns
      }
    }

    // Create a map of existing column fields to avoid duplicates
    const existingFields = new Set(baseColumns.map((col) => col.field))

    // Generate columns from property definitions that don't already exist
    const propertyColumns =
      objectPropertyDefinitionData.objectPropertyDefinitionsByObjectType
        .filter((propDef) => !existingFields.has(propDef.id))
        .map((propDef) => {
          const baseColumn = {
            field: propDef.id,
            headerName: propDef.name,
            description: propDef.description,
            editable: true,
            //flex: 1,
          }

          // Get the column definition based on property type
          const customDef = CustomColumnDefinitions[propDef.propertyTypeId]
          if (!customDef) {
            return baseColumn
          }

          // For picklist/multipicklist, add options
          if (
            [PropertyTypes.Picklist, PropertyTypes.MultiPicklist].includes(
              propDef.propertyTypeId
            )
          ) {
            const finalColumn = {
              ...baseColumn,
              ...customDef,
              valueOptions: propDef.options || [], // Add options directly to column definition
              getOptionValue: (option) => option.id, // Tell MUI how to get the value from our option objects
              getOptionLabel: (option) => option.name, // Tell MUI how to get the label from our option objects
              renderCell: (params) => (
                <Row sx={{ height: '100%' }}>
                  <PropertyDisplay
                    propertyDefinition={propDef}
                    value={params.value}
                    workspaceId={workspaceId}
                    objectId={params.row.id}
                    objectType={objectType}
                  />
                </Row>
              ),
            }
            return finalColumn
          }

          // For all other types, merge base with custom definition
          return {
            ...baseColumn,
            ...customDef,
          }
        })

    // Combine base columns with property-defined columns
    return [...baseColumns, ...propertyColumns]
  }, [
    objectPropertyDefinitionData,
    data.columns,
    isUngatedForViews,
    objectType,
    workspaceId,
  ])

  return (
    fullyLoaded && (
      <DataGridPremium
        apiRef={apiRef}
        rows={data.rows}
        columns={columns}
        hideFooterSelectedRowCount={true}
        loading={memoizedLoading}
        initialState={initialState}
        disableRowSelectionOnClick={true}
        editMode="cell"
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={(error) => {
          logger.warn(
            `Error updating object property: ${error.message}`,
            error.stack
          )
        }}
        onCellEditStart={handleCellEditStart}
        onCellEditStop={handleCellEditStop}
        slots={{
          toolbar: DayDataGridToolbar,
          noResultsOverlay: () => (
            <Row
              sx={{ justifyContent: 'center', height: '100%', width: '100%' }}
            >
              <Typography variant="h4">{`No ${rowObjectName} match these filters`}</Typography>
            </Row>
          ),
          noRowsOverlay: () => (
            <Row
              sx={{ justifyContent: 'center', height: '100%', width: '100%' }}
            >
              <Typography variant="h4">{`No ${rowObjectName} to display`}</Typography>
            </Row>
          ),
          footerRowCount: (params) => {
            return (
              <>
                {memoizedLoading ? (
                  <MetadataChip
                    state={{
                      label: 'Loading',
                      color: 'primary',
                      value: 'Loading',
                    }}
                    icon={<IconRefresh />}
                  />
                ) : (
                  <Row sx={{ justifyContent: 'flex-end', width: '100%' }}>
                    <MetadataChip
                      state={{
                        label: `${params.visibleRowCount}
                    ${
                      params.visibleRowCount === 1
                        ? rowObjectNameSingular || rowObjectName
                        : rowObjectName
                    }`,
                        color: 'primary',
                        value: `${params.visibleRowCount}`,
                      }}
                      icon={icon}
                    />
                    {params.visibleRowCount !== params.rowCount && (
                      <Typography sx={{ fontSize: '11px' }}>
                        {`${Math.round(
                          (params.visibleRowCount / params.rowCount) * 100
                        )}
                        %`}
                      </Typography>
                    )}
                  </Row>
                )}
              </>
            )
          },
        }}
        //onRowClick={memoizedOnRowClick}
        onCellClick={handleCellClick}
        sortingOrder={['desc', 'asc']}
        slotProps={{
          toolbar: {
            id,
            showColumnFilter: true,
            showFilter: showFilters,
            showQuickFilter: showFilters,
            showExport: showFilters,
            showDensitySelector: false,
            title,
            subtitle,
            navItems,
            headerLevel,
            objectType,
            workspaceId,
            tabSets,
            initialState,
            rowObjectName,
            showStats,
            setFilterButtonEl,
            setColumnMenuEl,
            showFilters,
            actionButtons,
            csvOptions: {
              fileName: slugifyFilename(
                csvFilename || `Day.ai ${rowObjectName || 'Export'}`
              ),
            },
          },
          panel: {
            anchorEl: () => {
              const preferencePanelState = gridPreferencePanelStateSelector(
                apiRef.current.state
              )
              if (preferencePanelState.open) {
                if (preferencePanelState.openedPanelValue === 'filters') {
                  apiRef.current.hideColumnMenu()
                  return filterButtonEl
                } else if (
                  preferencePanelState.openedPanelValue === 'columns'
                ) {
                  return columnMenuEl
                }
              }
              return null
            },
          },
        }}
        columnHeaderHeight={40}
        rowHeight={40}
        sx={dayTableStyle}
      />
    )
  )
}

export default DayTable
