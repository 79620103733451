export const viewManagerContainerSx = {
  height: '36px',
  alignItems: 'flex-start',
  '& .view-manager-tabs-container': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 10,
    overflow: 'visible',
    position: 'relative',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}

export const tabsContainerSx = {}

export const refreshButtonSx = {
  p: '4px',
  borderRadius: '3px',
}
