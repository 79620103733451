import { useContext, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  ListItemButton,
  List,
  TextField,
  Typography,
  ListItemIcon,
} from '@mui/material'
import { IconBubbleText, IconBulb, IconX } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import type { SearchableObject } from 'src/lib/searchService'

import { BoxResize } from '../BoxResize/BoxResize'
import Row from '../Row/Row'
import ObjectContextManager from '../Threads/ObjectContextManager/ObjectContextManager'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

const GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR = gql`
  query GetOrganizationForContextSidebar(
    $workspaceId: String!
    $orgId: String!
  ) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      employeeCount
      about {
        description
        aiDescription
        phoneNumbers
        isHiring
        industryType
        founded
        employeesFrom
        employeesTo
        selling
      }
      headquarters {
        address
        city
        state
        country
        postalCode
        latitude
        longitude
      }
      purpose {
        differentiators
        marketingPromises
        missionAndVision
      }
      people {
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        square
      }
      relationship {
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      links {
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        pipelineType
        stageType
      }
    }
  }
`

const UPDATE_CONTEXT = gql`
  mutation UpdateContext(
    $id: String!
    $input: UpdateWorkspaceUserContextInput!
  ) {
    updateWorkspaceUserContext(id: $id, input: $input) {
      id
    }
  }
`

const CREATE_CONTEXT = gql`
  mutation CreateContext($input: CreateWorkspaceUserContextInput!) {
    createWorkspaceUserContext(input: $input) {
      id
    }
  }
`

const ContextEditSidebar = ({
  onClose,
  onInit,
  title = null,
  context = null,
  hideSearch = false,
  onUpdate = () => {},
}: {
  onClose: () => void
  onInit: () => void
  onUpdate: () => void
  title?: string | null
  context: any | null
  hideSearch?: boolean
}) => {
  const { currentUser } = useAuth()
  const { workspacePeople, selectedWorkspace } = useContext(DayContext)
  const [showSearch, setShowSearch] = useState(!context?.parentReferenceKey)
  const [objectType, setObjectType] = useState<NativeObjectType | null>(null)
  const [objectId, setObjectId] = useState<string | null>(null)
  const [objectTitle, setObjectTitle] = useState<string | null>(null)
  const [contextEdit, setContextEdit] = useState<any>(null)
  const [hasEdits, setHasEdits] = useState(false)

  const { data } = useQuery(GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: objectId,
    },
    skip: !objectId || objectType !== NativeObjectTypes.Organization,
  })

  const [updateContext] = useMutation(UPDATE_CONTEXT)

  const isCreator = useMemo(() => {
    return currentUser?.id === contextEdit?.userId || !contextEdit?.userId
  }, [currentUser, contextEdit])

  const handleSave = () => {
    const input = {
      plainTextValue: contextEdit.plainTextValue,
      workspaceId: selectedWorkspace,
      parentReferenceKey: contextEdit.parentReferenceKey,
      referencedObjectIds: contextEdit.referencedObjectIds || [],
    }
    toast.promise(updateContext({ variables: { id: contextEdit.id, input } }), {
      loading: 'Saving context...',
      success: () => {
        onUpdate()
        onClose()
        return 'Context saved!'
      },
      error: 'Error saving context',
    })
  }

  const [createContext] = useMutation(CREATE_CONTEXT)

  const handleCreate = () => {
    const referencedObjectIds = contextEdit.referencedObjectIds || []
    if (referencedObjectIds.length === 0) {
      if (objectType === NativeObjectTypes.Person) {
        const domain = extractEmailDomain(objectId)
        if (domain) {
          referencedObjectIds.push(
            `${NativeObjectTypes.Organization} : ${domain} : root`
          )
        }
      }
    }

    const input: any = {
      parentReferenceKey: contextEdit.parentReferenceKey,
      plainTextValue: contextEdit.plainTextValue.trim(),
      referencedObjectIds,
      workspaceId: selectedWorkspace,
    }

    delete input.new
    toast.promise(createContext({ variables: { input } }), {
      loading: 'Creating context...',
      success: () => {
        onUpdate()
        onClose()
        return 'Context created!'
      },
      error: (error: Error) => {
        logger.error('Failed to create context', error)
        return 'Error creating context'
      },
    })
  }

  const organization = useMemo(() => data?.workspaceOrganization, [data])

  const handleSelectObject = (result: SearchableObject) => {
    setObjectType(result.objectType as NativeObjectType)
    setObjectId(result.objectId)

    // For workspace actions (like Action Instructions), the objectType is Workspace but objectId is the actual type
    const parentReferenceKey =
      result.objectType === NativeObjectTypes.Workspace
        ? `${result.objectType} : ${result.objectId} : root`
        : `${result.objectType} : ${result.objectId} : root`

    setContextEdit({
      id: null,
      plainTextValue: '',
      parentReferenceKey,
      new: true,
    })
    setObjectTitle(
      result.properties?.name || result.properties?.fullName || result.objectId
    )
    setShowSearch(false)
    setHasEdits(false)
  }

  const handleWorkspaceAction = (objectType: NativeObjectType) => {
    const metadata = ObjectTypeMetadata[objectType]
    const label =
      metadata.paths?.ROOT?.label || `${metadata.label} Instructions`

    // Set up the context directly instead of going through handleSelectObject
    const parentReferenceKey = `${NativeObjectTypes.Workspace} : ${objectType} : root`

    // Set all state at once to avoid race conditions
    const newContextEdit = {
      id: null,
      plainTextValue: '',
      parentReferenceKey,
      new: true,
    }

    setContextEdit(newContextEdit)
    setObjectType(NativeObjectTypes.Workspace)
    setObjectId(objectType)
    setObjectTitle(`${ObjectTypeMetadata[objectType].label} - ${label}`)
    setShowSearch(false)
    setHasEdits(false)
  }

  useEffect(() => {
    const [passedObjectType, passedObjectId, passedPath] =
      context?.parentReferenceKey
        ? context.parentReferenceKey.split(' : ')
        : [null, null, null]

    const requiresInitialization =
      !contextEdit ||
      (contextEdit?.id && context?.id && context.id !== contextEdit.id) ||
      context?.new

    if (requiresInitialization) {
      if (context?.new) {
        const newContextEdit = {
          ...context,
          parentReferenceKey:
            contextEdit?.parentReferenceKey || context.parentReferenceKey,
          plainTextValue: '',
          new: true,
        }
        setContextEdit(newContextEdit)
        setHasEdits(false)
      } else if (!contextEdit || contextEdit.id !== context.id) {
        setContextEdit(context)
      }

      if (context && context.parentReferenceKey) {
        if (context.id !== contextEdit?.id || context.new) {
          setObjectType(passedObjectType as NativeObjectType)
          setObjectId(passedObjectId)
          if (title) {
            setObjectTitle(title)
          } else if (
            passedPath &&
            passedObjectType === NativeObjectTypes.Workspace
          ) {
            const metadata = ObjectTypeMetadata[passedObjectId]
            const pathKey = Object.keys(metadata.paths || {}).find(
              (key) => metadata.paths[key].key === passedPath
            )
            if (pathKey && metadata.paths) {
              setObjectTitle(
                `${metadata.pluralLabel} - ${metadata.paths[pathKey].label}`
              )
            }
          } else {
            if (passedObjectType === NativeObjectTypes.Person) {
              const person = workspacePeople.find(
                (p) => p.email === passedObjectId
              )
              setObjectTitle(person?.fullName || person?.email)
            } else if (passedObjectType === NativeObjectTypes.Organization) {
              setObjectTitle(organization?.name || passedObjectId)
            } else if (passedObjectType === NativeObjectTypes.Workspace) {
              const metadata =
                ObjectTypeMetadata[passedObjectId as NativeObjectType]
              setObjectTitle(
                metadata.paths?.ROOT?.label || `${metadata.label} Instructions`
              )
            } else {
              setObjectTitle(ObjectTypeMetadata[passedObjectType].label)
            }
          }
        }
      }
    }
  }, [context, contextEdit?.id, organization?.name, title, workspacePeople])

  return (
    contextEdit && (
      <Box
        sx={{
          position: 'fixed',
          zIndex: 1000,
          background: 'white',
          width: 'calc(680px - 72px)',
          height: '100vh',
          overflowY: 'hidden',
          top: 0,
          right: 0,
          p: 0,
          m: 0,
          boxShadow: `
            0 1px 2px rgba(136, 161, 183, 0.07),
            0 2px 4px rgba(136, 161, 183, 0.07),
            0 4px 8px rgba(136, 161, 183, 0.07),
            0 8px 16px rgba(136, 161, 183, 0.07),
            0 16px 32px rgba(136, 161, 183, 0.07),
            0 32px 64px rgba(136, 161, 183, 0.07)`,
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: '12px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Row
          sx={{
            justifyContent: 'space-between',
            background: (theme) => theme.palette.background.default,
            p: 3,
            boxShadow: `
                0 1px 2px rgba(136, 161, 183, 0.04),
                0 2px 4px rgba(136, 161, 183, 0.04),
                0 4px 8px rgba(136, 161, 183, 0.04),
                0 8px 16px rgba(136, 161, 183, 0.04),
                0 16px 32px rgba(136, 161, 183, 0.04),
                0 32px 64px rgba(136, 161, 183, 0.04)`,
            height: '72px',
            width: '100%',
          }}
        >
          <Row sx={{ width: '100%' }}>
            {objectTitle ? (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    opacity: 0.8,
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '-0.7px',
                    lineHeight: '140%',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    width: '100%',
                    maxHeight: '40px',
                    whiteSpace: 'normal',
                  }}
                >
                  {`Add context to ${
                    objectType === NativeObjectTypes.Workspace
                      ? 'your workspace'
                      : ObjectTypeMetadata[objectType].label
                  }`}
                </Typography>

                <Row gap={'4px'}>
                  {objectType && objectId && (
                    <Box
                      sx={{
                        height: '28px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pb: '2px',
                        ml: '-4px',
                        flexShrink: 0,
                      }}
                    >
                      {React.createElement(
                        ObjectTypeMetadata[
                          objectType === NativeObjectTypes.Workspace
                            ? objectId
                            : objectType
                        ].icon,
                        { size: 20, stroke: 2.5 }
                      )}
                    </Box>
                  )}
                  <Typography
                    variant="h3"
                    sx={{
                      WebkitLineClamp: 1,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      lineHeight: '110%',
                    }}
                  >
                    {objectTitle}
                  </Typography>
                </Row>
              </Box>
            ) : (
              <Box>
                <Typography variant="h2">
                  {hideSearch ? 'Add instructions' : 'Add context'}
                </Typography>
              </Box>
            )}
          </Row>
          <Row gap={2}>
            {contextEdit.id && !isCreator && (
              <Button
                startIcon={<IconBubbleText size={16} />}
                size="small"
                variant="contained"
                onClick={onInit}
                color="secondary"
              >
                Add more context
              </Button>
            )}
            <IconButton onClick={onClose}>
              <IconX />
            </IconButton>
          </Row>
        </Row>
        {objectType && objectId && (
          <Row
            gap={2}
            sx={{ mx: '12px', height: '64px' }}
          >
            <Box sx={{ opacity: 0.8 }}>
              <IconBulb size={24} />
            </Box>
            <Box sx={{ opacity: 0.9 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Day.ai learns & adapts from this context
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  fontWeight: 400,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Add insights, instructions, details and corrections{' '}
                {objectTitle ? `to ${objectTitle}` : ''}
              </Typography>
            </Box>
          </Row>
        )}

        {!objectType || !objectId ? (
          <Box sx={{ mt: 4 }}>
            {showSearch && !hideSearch && (
              <Box sx={{ px: 3, mb: 2 }}>
                <ObjectContextManager
                  variant="new"
                  contextObjects={[]}
                  onUpdateContextObjects={(objects) => {
                    if (objects.length > 0) {
                      handleSelectObject({
                        objectType: objects[0].objectType,
                        objectId: objects[0].objectId,
                        properties: {},
                        lastUpdated: Date.now(),
                        label: '',
                      })
                    }
                  }}
                  showStatus={false}
                  buttonLabel="Search for a person, organization, or opportunity..."
                  buttonFullWidth={true}
                  workspaceId={selectedWorkspace}
                  contextForObjectType={objectType as NativeObjectType}
                />
              </Box>
            )}
            {!hideSearch && (
              <Typography
                variant="h3"
                sx={{ px: 3, mt: 2 }}
              >
                ... or, add context to your workspace:
              </Typography>
            )}
            <List
              sx={{
                mx: 3,
                '& .MuiListItemButton-root': {
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  mt: 2,
                },
              }}
            >
              {Object.entries(ObjectTypeMetadata).map(
                ([objectType, metadata]) => {
                  // Skip if no paths or no ROOT path
                  if (!metadata.paths) return null

                  return Object.values(metadata.paths).map((pathMetadata) => (
                    <ListItemButton
                      key={objectType}
                      onClick={() =>
                        handleWorkspaceAction(objectType as NativeObjectType)
                      }
                    >
                      <ListItemIcon sx={{ minWidth: '28px' }}>
                        {React.createElement(metadata.icon, {
                          size: 20,
                          stroke: 2.5,
                        })}
                      </ListItemIcon>
                      <ListItemText
                        primary={`${metadata.label} ${pathMetadata.label}`}
                      />
                    </ListItemButton>
                  ))
                }
              )}
            </List>
          </Box>
        ) : (
          <Box
            sx={{
              height: `calc(100vh - ${72 + 72 + 64 + 36}px)`,
              width: '100%',
              px: '12px',
            }}
          >
            <BoxResize>
              {(dimens) => (
                <TextField
                  {...dimens}
                  placeholder="Add context"
                  multiline={true}
                  fullWidth={true}
                  rows={Math.floor((dimens.height + 4) / 21)}
                  disabled={!isCreator}
                  value={contextEdit.plainTextValue}
                  onChange={(e) => {
                    const clipboardData = (e.nativeEvent as ClipboardEvent)
                      .clipboardData
                    if (clipboardData) {
                      logger.dev('paste detected')
                    }
                    setHasEdits(true)
                    setContextEdit({
                      ...contextEdit,
                      plainTextValue: e.target.value,
                    })
                  }}
                />
              )}
            </BoxResize>
            {objectType !== NativeObjectTypes.Workspace && (
              <ObjectContextManager
                variant="existing"
                contextObjects={
                  contextEdit.referencedObjectIds?.map((ref: string) => {
                    const [objectType, objectId] = ref.split(' : ')
                    return { objectType, objectId }
                  }) || []
                }
                onUpdateContextObjects={(objects) => {
                  setHasEdits(true)
                  setContextEdit({
                    ...contextEdit,
                    referencedObjectIds: objects.map(
                      (obj) => `${obj.objectType} : ${obj.objectId} : root`
                    ),
                  })
                }}
                showStatus={false}
                buttonLabel="Attach CRM records"
                contextForObjectType={NativeObjectTypes.UserContext}
                workspaceId={selectedWorkspace}
              />
            )}

            <Row
              gap={1}
              sx={{ height: '72px' }}
            >
              {isCreator ? (
                <Button
                  fullWidth={true}
                  size="large"
                  onClick={() => {
                    if (contextEdit.id) {
                      handleSave()
                    } else {
                      handleCreate()
                    }
                  }}
                  variant="contained"
                  disableElevation={true}
                  disabled={!isCreator || !hasEdits}
                >
                  {contextEdit.id ? 'Save' : 'Add'}
                </Button>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ flexShrink: 0 }}
                  >
                    Added {dayjs(contextEdit.createdAt).fromNow()} by{' '}
                  </Typography>
                  <WorkspaceMemberChip userId={contextEdit.userId} />
                </>
              )}
            </Row>
          </Box>
        )}
      </Box>
    )
  )
}

export default ContextEditSidebar
