import { useMemo, useCallback, useState } from 'react'

import { Box, hexToRgb } from '@mui/material'
import { EditorContent, useEditor } from '@tiptap/react'
import { debounce } from 'lodash'
import type { UpdatePageInput } from 'types/graphql'

import PromptInput from 'src/components/Prompts/PromptInput'
import Row from 'src/components/Row/Row'
import SidebarLoader from 'src/components/Sidebar/SidebarLoader/SidebarLoader'
import AiEditor from 'src/components/Tiptap/AiEditor/AiEditor'
import { extensionsEmbeddedAiWithMark } from 'src/components/Tiptap/extensions'
import { editorStyle } from 'src/components/Tiptap/styles'
import TiptapBubbleMenu from 'src/components/Tiptap/TiptapBubbleMenu/TiptapBubbleMenu'
import TiptapFloatingMenu from 'src/components/Tiptap/TiptapFloatingMenu/TiptapFloatingMenu'
import { usePage } from 'src/hooks/usePage/usePage'
import { logger } from 'src/lib/logger'

import { extractTitleAndContent } from '../pages'

const EmbeddedAiPageEditor = () => {
  const [prompt, setPrompt] = useState('')

  const {
    page,
    showPrompt,
    contextObjects,
    setContextObjects,
    contextStatus,
    contextString,
    token,
    handleUpdatePageContent,
  } = usePage()

  const handleUpdatePageContentEditor = useCallback(
    async (updatePayload) => {
      const editor = updatePayload.editor
      logger.dev('handleUpdatePageContentEditor called', { editor })
      const currentContent = editor.getJSON()
      const contentHtml = editor.getHTML()

      const input: {
        contentJson: { type: string; content: any }
        contentHtml: any
      } = {
        contentJson: currentContent,
        contentHtml,
      }

      try {
        logger.dev('Updating page content', { input })
        await handleUpdatePageContent(input)
      } catch (error) {
        logger.error('Failed to update page content:', error)
      }
    },
    [handleUpdatePageContent]
  )

  const handleSendPrompt = useCallback(() => {
    setPrompt('')
  }, [setPrompt])

  const handleAiResponse = useCallback(
    (response) => {
      const responseEditor = response.editor
      responseEditor.commands.selectAll()
      responseEditor.commands.unsetMark('aiMark')
      const contentJson = responseEditor.getJSON()
      const contentHtml = responseEditor.getHTML()
      logger.dev('handleAiResponse', {
        responseEditor,
        contentJson,
        contentHtml,
      })
      const input: UpdatePageInput = {
        contentJson,
        contentHtml,
        aiPopulationCompletedAt: new Date().toISOString(),
      }
      handleUpdatePageContent(input)
    },
    [handleUpdatePageContent]
  )

  const debouncedUpdateContent = useMemo(() => {
    const debouncedFn = debounce((editor) => {
      logger.dev('debouncedUpdateContent called')
      handleUpdatePageContentEditor(editor)
    }, 750)
    return debouncedFn
  }, [handleUpdatePageContentEditor])

  const pageSidebarContainerSx = useMemo(
    () => ({
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& .editor-container': {
        height: '100%',
        width: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        '& .editor-tiptap': {
          ...editorStyle,
          pb: '24px',
          px: 4,
          width: '100%',
          '& > div, .editor-tiptap-inner': {
            height: '100%',
            width: '100%',
          },
          '& tiptap ProseMirror': {
            overflowY: 'auto',
            height: '100%',
          },
        },
        '& .editor-prompt': {
          display: showPrompt ? 'flex' : 'none',
          flexShrink: 0,
          width: '100%',
          background: (theme) => theme.palette.background.default,
          position: 'relative',
          pt: 2,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-8px',
            left: 0,
            right: 0,
            height: '8px',
            background: (theme) =>
              `linear-gradient(to top, ${hexToRgb(
                theme.palette.background.default
              )}, rgba(0, 0, 0, 0))`,
            zIndex: 9,
          },
        },
      },
    }),
    [showPrompt]
  )

  const aiUnderway = page?.aiInitialPrompt && !page?.aiPopulationCompletedAt

  return (
    <Box sx={pageSidebarContainerSx}>
      {!page ? (
        <SidebarLoader />
      ) : (
        <>
          <Box className="editor-container">
            <>
              <Box className="editor-tiptap">
                {aiUnderway ? (
                  <AiEditor
                    token={token}
                    prompt={page?.aiInitialPrompt}
                    onSuccess={handleAiResponse}
                    contextString={contextString}
                  />
                ) : (
                  <PageEditor onUpdate={debouncedUpdateContent} />
                )}
              </Box>
            </>
          </Box>
          {showPrompt && (
            <Box className="editor-prompt">
              <Row sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', mx: 2 }}>
                  <PromptInput
                    prompt={prompt}
                    setPrompt={setPrompt}
                    placeholder="Ask Day.ai to revise the page"
                    contextObjects={contextObjects}
                    onUpdateContextObjects={setContextObjects}
                    onSubmit={handleSendPrompt}
                    workspaceId={page?.workspaceId}
                    contextStatus={contextStatus}
                    showStatus={true}
                    showDebug={true}
                    debugComponent="page"
                  />
                </Box>
              </Row>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

const PageEditor = ({ onUpdate }) => {
  const { page } = usePage()

  const editor = useEditor({
    extensions: [...extensionsEmbeddedAiWithMark],
    autofocus: true,
    editable: true,
    content:
      ((page?.contentJson as any)?.content || [])?.length > 0
        ? extractTitleAndContent(page?.contentJson)?.content
        : '',
    onUpdate,
  })

  return (
    <div className="editor-tiptap-inner">
      <TiptapBubbleMenu editor={editor} />
      <TiptapFloatingMenu editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default EmbeddedAiPageEditor
