import { useContext, useMemo, useState } from 'react'

import { IconMinus, IconPlus } from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import type { NativeObjectType } from 'src/lib/objects'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../../Row/Row'
import MetadataChip from '../MetadataChip/MetadataChip'
import ObjectChip from '../ObjectChip/ObjectChip'

interface ObjectChipRowProps {
  workspaceId: string
  objects: Partial<CRMObject>[]
  suggestedObjects?: Partial<CRMObject>[]
  onRemove?: (object: Partial<CRMObject>) => void
  onAdd?: (object: Partial<CRMObject>) => void
  action?: React.ReactElement
  showSidebar?: boolean
  fullWidth?: boolean
  maxToShow?: number
}

const ObjectChipRow = ({
  workspaceId,
  objects,
  suggestedObjects = [],
  onRemove = null,
  onAdd = null,
  action = null,
  showSidebar = false,
  fullWidth = true,
  maxToShow = 3,
}: ObjectChipRowProps) => {
  const { internalDomains } = useContext(DayContext)
  const [showAll, setShowAll] = useState(false)

  const validUniqueObjects = useMemo(() => {
    const objs = []
    const objIds = new Set<string>()
    if (!objects || !objects.length) return objs
    for (const obj of objects) {
      if (
        obj.objectId &&
        !objIds.has(obj.objectId) &&
        Object.values(NativeObjectTypes).includes(
          obj.objectType as NativeObjectType
        )
      ) {
        if (
          obj.objectType !== NativeObjectTypes.Organization ||
          !internalDomains.includes(obj.objectId)
        ) {
          objs.push(obj)
          objIds.add(obj.objectId)
        } else {
          logger.warn('Invalid object', { object: obj })
        }
      }
    }

    return objs
  }, [objects, internalDomains])

  const moreToShowCount = useMemo(() => {
    return showAll ? 0 : validUniqueObjects.length - maxToShow
  }, [validUniqueObjects, showAll, maxToShow])

  const moreToShow = useMemo(() => {
    return validUniqueObjects.length > maxToShow
  }, [validUniqueObjects, maxToShow])

  const visibleObjects = useMemo(() => {
    return showAll ? validUniqueObjects : validUniqueObjects.slice(0, maxToShow)
  }, [validUniqueObjects, showAll, maxToShow])

  return (
    <Row
      gap={1}
      sx={{
        flexWrap: 'wrap',
        width: fullWidth ? '100%' : 'auto',
        '& .MuiChip-root': {
          maxWidth: fullWidth
            ? 'auto'
            : showAll
              ? '188px'
              : moreToShow
                ? '108px'
                : '128px',
        },
      }}
    >
      {(visibleObjects || []).map((object) => (
        <ObjectChip
          workspaceId={workspaceId}
          key={object.objectId}
          crmObject={object}
          onRemove={onRemove}
          fullWidth={false}
          showSidebar={showSidebar}
        />
      ))}

      {moreToShow && (
        <MetadataChip
          onClick={() => setShowAll((prev) => !prev)}
          icon={showAll ? <IconMinus /> : <IconPlus />}
          state={{
            label: showAll ? 'Show fewer' : `${moreToShowCount} more`,
            value: showAll ? 'collapse' : 'expand',
          }}
        />
      )}
      {suggestedObjects.length > 0 && (
        <Row gap={1}>
          {suggestedObjects.map((suggestion) => (
            <ObjectChip
              key={suggestion.objectId}
              workspaceId={workspaceId}
              crmObject={suggestion}
              showSidebar={showSidebar}
              fullWidth={fullWidth}
              isSuggested={true}
              onAdd={onAdd}
            />
          ))}
        </Row>
      )}

      {action && action}
    </Row>
  )
}

export default ObjectChipRow
